<mat-form-field *ngIf="surveyLanguages && surveyLanguages.length > 1">
    <mat-select
        [ngModel]="selectedLanguage"
        (ngModelChange)="onSelectedLanguageChange($event)"
    >
        <mat-option *ngFor="let item of surveyLanguages" [value]="item">
            {{
                checkTranslate(item, 'LANGUAGES.' + item.value | translate)
            }}
        </mat-option>
    </mat-select>
</mat-form-field>
