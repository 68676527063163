import { Component, Input, OnInit } from '@angular/core';
import { ProfilePropertyItem } from '../../../models/survey-items/action-items/profilePropertyItem';

@Component({
    selector: 'cb-profile-property-item-preview',
    templateUrl: './profile-property-item-preview.component.html',
    styleUrls: ['./profile-property-item-preview.component.scss']
})
export class ProfilePropertyPreviewItemComponent implements OnInit {
    @Input() actionItem: ProfilePropertyItem;
    constructor() {}

    ngOnInit() {}
}
