import { MessageItem } from '../../../models/survey-items/display-items/messageItem';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-message-item-preview',
    templateUrl: './message-item-preview.component.html',
    styleUrls: ['./message-item-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagePreviewItemComponent implements AfterViewInit, OnInit{
    @Input() displayItem: MessageItem;
    constructor(
        private printService: PrintService,
        private elem: ElementRef
        ) { }
    
    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.displayItem.id, this.elem);
    }
}


