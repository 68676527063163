<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)"
         [class]="isPrint ? 'cb-item-layout--vertical': ''">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>

        <div
            class="cb-image-choice-container cb-question-item-content cb-question-item-content--max-width"
        >

            <form [formGroup]="form" >
                <div
                    class="image-choices-group"
                    role='group' [attr.aria-labelledby]="questionId + questionItem.id"
                >
                    <ng-container formArrayName="choices">
                        <ng-container *ngFor="let c of choices.controls; index as i"
                             [formGroupName]="i"
                        >
                            <mat-checkbox
                                [checked]="c.controls.is_default.value"
                                formControlName="is_default"
                            >
                                <div tabindex="0"
                                     (keydown.space)=" toggleCheckedState($event, c.controls)"
                                     role="checkbox"
                                     [attr.aria-checked]="c.controls.is_default.value"
                                     class="choice-item-container"
                                >
                                    <span *ngIf="questionItem.show_number_labels">{{ i + 1 }}.</span>
                                    <div class="image-wrapper"
                                         [ngStyle]="isMobile || isVertical ? verticalStyleObj : styleObj">
                                        <img
                                            [ngStyle]="imageMaxWidth"
                                            [src]='c.controls.url.value'
                                            [alt]="'image ' + c.controls.alias.value">
                                    </div>
                                    <span *ngIf="questionItem.show_text_labels" class="img-alias">
                                        {{c.controls.alias.value}}
                                    </span>
                                </div>

                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                </div>
                <cb-server-validation-errors
                    [item]="questionItem"
                ></cb-server-validation-errors>
            </form>

        </div>
    </div>
</div>
