import { PagedList, PageRequestOptions } from '../models';
import { SurveyQuestionType } from '../consts/surveys.consts';
import { SurveyPage } from '../../app-admin/models';
import { SurveysPageTypes } from '../consts/surveys.consts';
import * as _ from 'lodash';

export class PageProcessor {
    static proceedPageList<SubjListItem>(
        currentList: SubjListItem[],
        newBlock: PagedList<SubjListItem>,
        pageOptions: PageRequestOptions,
        newBlockMapper?: (
            value: SubjListItem,
            index?: number,
            array?: SubjListItem[]
        ) => SubjListItem
    ): SubjListItem[] {
        const pageSize = Number(pageOptions.pageSize) || 0;
        const pageNumber = Number(pageOptions.pagesNumber) || 0;
        let newList = currentList;
        const defaultBlockMapper = i => i;
        const blockMapper = newBlockMapper || defaultBlockMapper;

        if (newBlock) {
            const sizeOfContact = currentList ? currentList.length || 0 : 0;
            const expectedSize = pageSize * pageNumber;
            const sizeOfPage = newBlock.items.length;
            const isExpectedSizeValid =
                sizeOfContact < expectedSize &&
                sizeOfContact >= expectedSize - pageSize;
            const mappedPage: SubjListItem[] = newBlock.items.map(blockMapper);
            if (!sizeOfContact || pageOptions.queryString || pageNumber === 1) {
                newList = newBlock.items;
            } else if (isExpectedSizeValid) {
                newList = _.concat(currentList, mappedPage);
            } else {
                newList = _.concat(
                    _.take(currentList, sizeOfContact - sizeOfPage),
                    mappedPage
                );
            }
            return newList;
        }
    }
}

export function preparePages(
    pages: any[],
    questions: any[],
    appearanceSettings
) {
    return pages.map((page, index) => {
        return {
            ...page,
            items: questions[index],
            points: 0,
            title: page.title || `Page ${index + 1}`,
            settings: appearanceSettings && appearanceSettings.showItemsNumber
                ? {
                      enable_dynamic_item_numbers:
                          appearanceSettings.enableDynamicItemNumber,
                      first_question_number: countFirstQuestionNumber(
                          questions,
                          index
                      )
                  }
                : null
        };
    });
}

export function preparePreviewPages(
    pages: any[]
) {
    return pages.map((page, index) => {
        return {
            ...page,
            items: undefined,
            points: 0,
            title: page.title || `Page ${index + 1}`
        };
    });
}

export function preparePage(
    page: SurveyPage,
    itemQuestions: any[],
    questions,
    index,
    showItemsNumber = false
    ) {
        return {
            ...page,
            items: itemQuestions,
            points: 0,
            title: page.title || `Page ${index + 1}`,
            settings: showItemsNumber ? {
                first_question_number: countFirstQuestionNumber(
                    questions,
                    index
                )
            } : null
        };
}

export function filterHiddenItemPage(pagesData) {
    return pagesData.filter(
        page =>
            page.page_type === SurveysPageTypes.ContentPage ||
            page.page_type === SurveysPageTypes.Completion
    );
}

function countFirstQuestionNumber(questions: any[][], index: number): number {
    let count = 0;
    for (let i = 0; i <= index; i++) {
        if (questions[i].length) {
            const questionCount = questions[i].filter(q => {
                if (!q.excluded && q.enabled) {
                    for (const key in SurveyQuestionType) {
                        if (SurveyQuestionType[key] === q.item_type) {
                            return true;
                        }
                    }
                }
            }).length;
            if (index === i && questionCount) {
                count += 1;
            } else {
                count += questionCount;
            }
        }
    }

    return count;
}
