<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <form [formGroup]="form" class="cb-question-item-content" tabindex="0"
              role="form" [attr.aria-labelledby]="questionId + questionItem.id">
            <div class="cb-promoter-score-rating">
                <div class="cb-score-inline-content">
                    <mat-radio-group
                        class="score-radio-group"
                        formControlName="value"

                    >
                        <mat-radio-button
                            class="square-radio-button hidden"
                            value=""
                            [checked]="isNoneSelected()"
                        >
                        </mat-radio-button>
                        <!--special hidden radio to help make proper markup-->

                        <div
                            class="cb-score-likely-text cb-score-least-likely-text"
                        >
                            {{surveyText.NpsNotAtAllLikely}}
                        </div>
                        <mat-radio-button
                            class="square-radio-button"
                            *ngFor="
                                let c of questionItem.choices;
                                let i = index
                            "
                            [checked]="i === lastIndex"
                            [value]="c.id"
                            >{{ c.points }}
                        </mat-radio-button>
                        <div
                            class="cb-score-likely-text cb-score-most-likely-text"
                        >
                            {{surveyText.NpsExtremelyLikely}}
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </form>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
