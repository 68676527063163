import { QuestionItem } from './questionItem';
import { ItemOption } from './itemOption';

export enum maxDiffItemOrientationEnum {
    WORST_ITEM_BEST = 'WORST_ITEM_BEST',
    BEST_ITEM_WORST = 'BEST_ITEM_WORST',
    ITEM_WORST_BEST = 'ITEM_WORST_BEST',
    ITEM_BEST_WORST = 'ITEM_BEST_WORST',
    WORST_BEST_ITEM = 'WORST_BEST_ITEM',
    BEST_WORST_ITEM = 'BEST_WORST_ITEM'
}

export class MaxDiffItem extends QuestionItem {
    best_label: string;
    worst_label: string;
    item_label: string;
    number_sets: number;
    orientation: maxDiffItemOrientationEnum;
    number_items_per_set: number;
    question_text_position: string;
    item_position: string;
    choices: ItemOption[];
    alias: string;
    sets: any[];
    dataSets: any[];
}
