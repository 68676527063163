<div class="{{ questionItem.html_class }}">
    <div
        class="cb-question-item-wrapper-outer {{ questionItem.html_class }} {{
            getQuestionItemInnerCssClass(questionItem)
        }}"
    >
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <cb-spinner [show]="isLoading" [blockLayout]="true"></cb-spinner>
        <div class="cb-fileupload-item-preview cb-question-item-content"
             role="form"
             [attr.aria-labelledby]="questionId + questionItem.id">
            <label>
                <span hidden>{{ 'SURVEY-VIEW.FILE_UPLOAD' | translate }}</span>
                <input
                    #fileInput
                    type="file"
                    *ngIf="showFileInput"
                    (change)="onUploadFile($event)"
                    [accept]="allowedFileTypes"
                    [disabled]="isLoading"
                />
            </label>

            <p
                *ngIf="!showFileInput"
                [innerHTML]="
                    questionItem.file_name ? (questionItem.file_name | keepHtml) : ''
                "
            ></p>
            <input
                *ngIf="!showFileInput"
                type="button"
                value="Clear"
                (click)="resetFileInput()"
            />
            <h4>{{surveyText.AllowedFileTypes}}</h4>
            <div class="file-types-preview-list">
                <div
                    class="file-types-preview-item"
                    *ngFor="let type of questionItem.allowed_file_types"
                >
                    {{ type }}
                </div>
            </div>
            <cb-server-validation-errors
                [item]="questionItem"
            ></cb-server-validation-errors>
        </div>
    </div>
</div>
