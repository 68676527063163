import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { CheckboxesItem } from '../../../models/survey-items/question-items/checkboxesItem';
import * as _ from 'lodash';
import {
    SurveyPositionType,
    SurveyLayoutType
} from '../../../../infrastructure/consts/surveys.consts';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { getEnabledRegularChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { prepareStyle } from '../../../../infrastructure/helpers/column-style.helper';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { WINDOW_WIDTH } from '../../../../infrastructure/consts/window-width';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-checkboxes-item-preview',
    templateUrl: './checkboxes-item-preview.component.html',
    styleUrls: ['./checkboxes-item-preview.component.scss']
})
@AutoUnsubscribe()
export class CheckboxesPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: CheckboxesItem;
    @Input() isMatrix: boolean;
    @Output() updated = new EventEmitter<any>();
    bottomPositionType = SurveyPositionType.BOTTOM;
    horizontalLayoutType = SurveyLayoutType.HORIZONTAL;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    prepareStyle = prepareStyle;
    styleObj: Object;
    cellStyles = [];
    form: FormGroup;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    private componentDestroyed = new Subject();
    isMobile: boolean;
    isTablet: boolean;
    defaultLayout: string;
    layoutClass: string;
    questionId = TakeSurveyData.QUESTION_ID;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkLayout();
    }

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.defaultLayout = this.questionItem.layout;
        // TODO: randomize only on preview
        if (this.questionItem.randomize) {
            this.questionItem.choices = _.shuffle(this.questionItem.choices);
        }
        this.createFormGroup();
        [this.styleObj, this.cellStyles] = this.prepareStyle(this.questionItem, this.controls);
        this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
        this.checkLayout();
        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });    
        
        
    }

    ngAfterViewInit(): void {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    private createFormGroup(): void {
        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            choices: new FormArray([])
        });
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));

        this.initChoices();
    }

    get choices(): FormArray {
        return this.form.get('choices') as FormArray;
    }
    get controls() {
        const allControls = [...this.choices.controls];
        if (this.noneOption) {
            allControls.push(this.noneOption);
        }
        if (this.otherOption) {
            allControls.push(this.otherOption);
        }
        return allControls;
    }
    get noneOption(): any {
        return this.form.get('noneOption');
    }
    get otherOption(): any {
        return this.form.get('otherOption');
    }

    initChoices() {
        const all_choices = this.questionItem.choices;
        const enabled_choices = getEnabledRegularChoices(this.questionItem.choices);

        enabled_choices.map(item => {
            // add ordinary options
            const control = new FormGroup({
                id: new FormControl(item.id),
                is_default: new FormControl(item.is_default),
                is_other: new FormControl(item.is_other),
                is_none_of_above: new FormControl(item.is_none_of_above),
                text: new FormControl(item.text),
                points: new FormControl(item.points),
                enabled: new FormControl(item.enabled)
            });
            (<FormArray>this.form.get('choices')).push(control);
        });

        if (this.questionItem.allow_other) {
            const otherOption = this.questionItem.otherOption ||
                _.find(all_choices, { is_other: true }) ||
                { is_other: true };

            const control = new FormGroup({
                id: new FormControl(otherOption.id),
                is_default: new FormControl(otherOption.is_default),
                is_other: new FormControl(otherOption.is_other),
                is_none_of_above: new FormControl(otherOption.is_none_of_above),
                text: new FormControl(otherOption.text),
                other_value: new FormControl(otherOption.other_value),
                points: new FormControl(otherOption.points),
                enabled: new FormControl(otherOption.enabled)
            });
            this.form.addControl('otherOption', control);
        }

        if (this.questionItem.allow_none_of_above) {
            const noneOption = this.questionItem.noneOption ||
                _.find(all_choices, { is_none_of_above: true }) ||
                { is_none_of_above: true };

            const control = new FormGroup({
                id: new FormControl(noneOption.id),
                is_default: new FormControl(noneOption.is_default),
                is_other: new FormControl(noneOption.is_other),
                is_none_of_above: new FormControl(noneOption.is_none_of_above),
                text: new FormControl(noneOption.text),
                points: new FormControl(noneOption.points),
                enabled: new FormControl(noneOption.enabled)
            });
            this.form.addControl('noneOption', control);
        }
    }

    resetForm() {
        this.choices.controls.forEach(control => {
            control.get('is_default').reset();
        });
        if (this.noneOption) {
            this.noneOption.get('is_default').reset();
        }
        if (this.otherOption) {
            this.otherOption.get('is_default').reset();
        }
    }

    optionsChanged() {
        const noneOption = this.form.get('noneOption');
        if (noneOption) {
            this.noneOption.controls.is_default.setValue(false);
        }
    }

    noneChange() {
        const newNoneValue = this.form.get('noneOption.is_default').value;

        if (newNoneValue) {
            const choices = this.form.get('choices') as FormArray;
            choices.controls.map(option => {
                (<FormGroup>option).controls.is_default.setValue(false);
            });
            const otherOption = this.form.get('otherOption');
            if (otherOption) {
                this.otherOption.controls.is_default.setValue(false);
            }
        }
    }

    checkLayout() {
        this.isMobile = window.innerWidth < WINDOW_WIDTH.mobileWidth;
        this.isTablet = window.innerWidth < WINDOW_WIDTH.tabletWidth;
        if (this.isMobile || (this.isTablet && this.isMatrix)) {
            this.questionItem.layout = 'Vertical';
            this.layoutClass = this.getQuestionItemInnerCssClass({
                'question_text_position': 'top',
                'item_position': 'left',
                'layout': 'Vertical'
            });
            [this.styleObj, this.cellStyles] = [{}, []];
        } else {
            this.questionItem.layout = this.defaultLayout;
            this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
            [this.styleObj, this.cellStyles] = this.prepareStyle(this.questionItem, this.controls);
        }
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
