import { Component, Input } from '@angular/core';
import { EmailResponseItem } from '../../../models/survey-items/action-items/emailResponseItem';

@Component({
    selector: 'cb-email-response-item-preview',
    templateUrl: './email-response-item-preview.component.html',
    styleUrls: ['./email-response-item-preview.component.scss']
})
export class EmailResponsePreviewItemComponent {
    @Input() actionItem: EmailResponseItem;
    constructor() {}
}
