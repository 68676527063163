<div class="filter-content">
  <div>
    <mat-checkbox color="primary" [(ngModel)]="active" (click)="$event.stopPropagation()">
      <cb-chip [text]="'SURVEYS-LIST.ACTIVE' | translate" color="success" icon="fi_check"></cb-chip>
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox color="primary" [(ngModel)]="draft" (click)="$event.stopPropagation()">
      <cb-chip [text]="'SURVEYS-LIST.DRAFT' | translate" color="accent" icon="fi_edit"></cb-chip>
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox color="primary" [(ngModel)]="archived" (click)="$event.stopPropagation()">
      <cb-chip [text]="'SURVEYS-LIST.ARCHIVED' | translate" icon="fi_archive"></cb-chip>
    </mat-checkbox>
  </div>
  <div>
    <div (click)="$event.stopPropagation()">
      <cb-date-time-picker placeholder="{{
                          'SURVEYS-LIST.FILTER-DATE-FROM' | translate
                      }}" [(ngModel)]="startDate"></cb-date-time-picker>
    </div>
    <div (click)="$event.stopPropagation()">
      <cb-date-time-picker placeholder="{{
                          'SURVEYS-LIST.FILTER-DATE-TO' | translate
                      }}" [(ngModel)]="endDate" [minDate]="startDate"></cb-date-time-picker>
    </div>
  </div>
  <div class="filter-actions">
    <cb-button [text]="'SHARED.RESET' | translate" type="stroked" (onClick)="onResetClick()"></cb-button>
    <cb-button [text]="'SHARED.APPLY' | translate" type="flat" color="primary" (onClick)="onFilterClick()"></cb-button>
  </div>
</div>
