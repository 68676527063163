import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MultilinesItem } from '../../../models/survey-items/question-items/multilinesItem';
import { FormControl, FormGroup } from '@angular/forms';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { StyleTemplatesProvider } from '../../../../infrastructure/providers/style-templates.provider';
import { Guid } from '../../../../infrastructure/helpers/guid.helper';
import { stripHtml } from '../../../../infrastructure/helpers/strip-html.helper';
import { SharedService } from '../../../../infrastructure/services';
import { String } from 'typescript-string-operations-ng4';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { WINDOW_WIDTH } from '../../../../infrastructure/consts/window-width';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import {TakeSurveySanitizerService} from '../../../services/take-survey-sanitizer.service';

@Component({
    selector: 'cb-multilines-item-preview',
    templateUrl: './multilines-item-preview.component.html',
    styleUrls: ['./multilines-item-preview.component.scss']
})
@AutoUnsubscribe()
export class MultilinesPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: MultilinesItem;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();
    defaultCols = 80;
    defaultRows = 10;
    filledTextLength;
    form: FormGroup;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    clearText: string;
    isMobile: boolean;
    surveyText = SurveyDefaultText;
    guid: string = Guid.newGuid();
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < WINDOW_WIDTH.mobileWidth;
    }

    constructor(
        private styleTemplatesProvider: StyleTemplatesProvider,
        private sharedService: SharedService,
        private printService: PrintService,
        private elem: ElementRef,
        private sanitizerService: TakeSurveySanitizerService
        ) {}

    ngOnInit() {
        this.isMobile = window.innerWidth < WINDOW_WIDTH.mobileWidth;
        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            answer_text: new FormControl(this.questionItem.default_text)
        });
        const questionItemText = (this.questionItem.answer_text) || this.questionItem.default_text || '';
        // TODO: fix calculation of this field, in value changes we don't strip html
        this.filledTextLength = this.sanitizerService.getTextContent(questionItemText).length;

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                tap((s) => {
                    if (!this.questionItem.allow_html) {
                        this.filledTextLength = s.answer_text ? s.answer_text.length : 0;
                    }
                }),
                debounceTime(500)
            )
            .subscribe(s => {
                this.updated.emit(s);
            });

        this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
        });
        this.calculateEditorSize();
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    resetForm() {
        this.form.get('answer_text').reset();
    }

    getTextareaClassName() {
        const textareaClass = 'question-textarea';
        if (this.questionItem.cols) {
            textareaClass.concat('height-unset');
        }
        if (this.questionItem.rows) {
            textareaClass.concat('width-unset');
        }
        return textareaClass;
    }

    calculateEditorSize() {
        const cols = this.questionItem.cols
            ? this.questionItem.cols
            : this.defaultCols;
        const rows = this.questionItem.rows
            ? this.questionItem.rows
            : this.defaultRows;
        const css = `.cb-rich-editor-${this.guid} .tox-edit-area{width: ${cols * 8}px; height: ${rows * 22 + 104}px;}`;
        this.styleTemplatesProvider.appendCssToPage(css, `tiny-editor-style-${this.guid}`);
    }

    getTextLengthWithoutHtml(ev) {
        this.filledTextLength = ev ? stripHtml(ev).length : 0;
    }

    getToLimitText(number) {
        if (this.surveyText) {
            return String.Format(this.surveyText.NumberOfCharactersRemainingToMinLength, `${number}`);
        }
    }

    getOverLimitText(number) {
        if (this.surveyText) {
            return String.Format(this.surveyText.NumberOfCharactersRemaining, `${number}`);
        }
    }

    ngOnDestroy(): void {
        this.styleTemplatesProvider.removeCssFromPage(`tiny-editor-style-${this.guid}`);
    }
}
