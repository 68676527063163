<cb-spinner [show]="isLoading"></cb-spinner>
<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}" *ngIf="!isLoading ">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
            [hideSubtext]="isMatrix"
        ></cb-questions-header>
        <form
            [formGroup]="form"
            class="cb-question-item-content"
            *ngIf="!isPrint"
        >
            <ng-container *ngIf="!isMatrix">
                <mat-form-field>
                    <mat-select
                        [placeholder]="surveyText.SelectAnOption"
                        [formControlName]="'value'"
                        [aria-labelledby]="questionId + questionItem.id"
                    >
                        <mat-option
                            *ngFor="let c of choices; index as i"
                            [value]="c"
                        >
                            <span *ngIf="questionItem.show_number_labels">{{ i + 1 }}.&nbsp;</span>
                            <span [innerHtml]="c | keepHtml"></span>
                        </mat-option>
                        <mat-option [value]="questionItem.other_text" *ngIf="questionItem.allow_other">
                            <span *ngIf="questionItem.show_number_labels">{{ choices.length + 1 }}.&nbsp;</span>
                            <span [innerHtml]="questionItem.other_text | keepHtml"></span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span
                    *ngIf="
                        questionItem.allow_other &&
                        form.controls.value.value == questionItem.other_text
                    "
                    class="other-option-input"
                >
                    <mat-form-field>
                        <input
                            matInput
                            [placeholder]="surveyText.YourOptionPrompt"
                            [formControlName]="'other_value'"
                        />
                    </mat-form-field>
                </span>
            </ng-container>

            <div class="table-matrix-group" *ngIf="isMatrix">
                <div class="text-left table-data-cell">
                    <mat-form-field
                        class="dropdown-list-control-preview"
                    >
                        <mat-select
                            [placeholder]="surveyText.SelectAnOption"
                            [formControlName]="'value'"
                        >
                            <mat-option
                                *ngFor="let c of choices"
                                [value]="c"
                            >
                                <span [innerHtml]="c | keepHtml"></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </form>
        <ng-container *ngIf="isPrint">
            <mat-radio-button
                *ngFor="let c of choices; index as i"
            >
                <span *ngIf="questionItem.show_number_labels">{{ i + 1 }}.&nbsp;</span>
                <span [innerHtml]="c | keepHtml"></span>
            </mat-radio-button>
            <mat-radio-button *ngIf="questionItem.allow_other">
                <span *ngIf="questionItem.show_number_labels">{{ choices.length + 1 }}.&nbsp;</span>
                <span [innerHtml]="questionItem.other_text | keepHtml"></span>
            </mat-radio-button>
        </ng-container>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
