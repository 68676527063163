<ng-container *ngIf="page">
    <div class="cb-survey-content">
        <ng-container *ngFor="let item of page.items; trackBy: trackByItemId">
            <div *ngIf="!item.excluded && item.enabled" class="cb-take-survey-sub-section">
                <cb-singlelines-item-preview
                    *ngIf="
                        item.item_type === questionType.SINGLE_LINE_TEXT
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-singlelines-item-preview>
                <cb-checkboxes-item-preview
                    *ngIf="
                        item.item_type === questionType.CHECKBOXES
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-checkboxes-item-preview>
                <cb-radiobuttons-item-preview
                    *ngIf="
                        item.item_type === questionType.RADIOBUTTONS
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-radiobuttons-item-preview>
                <cb-captcha-item-preview
                    *ngIf="
                        item.item_type === questionType.CAPTCHA &&
                        !isPrint
                    "
                    [questionItem]="item"
                ></cb-captcha-item-preview>
                <cb-dropdownlist-item-preview
                    *ngIf="
                        item.item_type === questionType.DROPDOWNLIST
                    "
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    (updated)="onUpdate($event)"
                ></cb-dropdownlist-item-preview>
                <cb-drill-down-item-preview
                    *ngIf="
                        item.item_type === questionType.DRILL_DOWN
                    "
                    removeIfFeatureOff="drilldown_question"
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    (updated)="onUpdate($event)"
                ></cb-drill-down-item-preview>
                <cb-multilines-item-preview
                    *ngIf="
                        item.item_type === questionType.MULTI_LINE_TEXT
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                    [isPrint]="isPrint"
                ></cb-multilines-item-preview>
                <cb-rating-scale-item-preview
                    *ngIf="
                        item.item_type === questionType.RATINGSCALE
                    "
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    (updated)="onUpdate($event)"
                ></cb-rating-scale-item-preview>
                <cb-net-promoter-score-item-preview
                    *ngIf="
                        item.item_type === questionType.NET_PROMOTER_SCORE
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-net-promoter-score-item-preview>
                <cb-rating-item-preview
                    *ngIf="item.item_type === questionType.RATING"
                    [questionItem]="item"
                ></cb-rating-item-preview>
                <cb-slider-item-preview
                    *ngIf="item.item_type === questionType.SLIDER"
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-slider-item-preview>
                <cb-message-item-preview
                    *ngIf="item.item_type === displayType.MESSAGE"
                    [displayItem]="item"
                ></cb-message-item-preview>
                <cb-matrix-item-preview
                    *ngIf="item.item_type === questionType.MATRIX"
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    [validationMessages]="validationMessages"
                    [validationText]="validationText"
                    (updated)="onMatrixUpdate($event)"
                ></cb-matrix-item-preview>
                <cb-contact-form-item-preview
                    *ngIf="item.item_type === questionType.CONTACT_FORM"
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    [validationMessages]="validationMessages"
                    [validationText]="validationText"
                    (updated)="onContactFormUpdate($event)"
                ></cb-contact-form-item-preview>
                <cb-rankorder-item-preview
                    *ngIf="
                        item.item_type === questionType.RANKORDER
                    "
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    (updated)="onUpdate($event)"
                ></cb-rankorder-item-preview>
                <cb-max-diff-item-preview
                    *ngIf="
                        item.item_type === questionType.MAXDIFF
                    "
                    [questionItem]="item"
                    (updated)="onUpdate($event)"
                ></cb-max-diff-item-preview>
                <cb-signature-item-preview
                    *ngIf="
                        item.item_type === questionType.SIGNATURE
                    "
                    [questionItem]="item"
                    [isTakeSurvey]="isTakeSurvey"
                ></cb-signature-item-preview>
                <cb-file-upload-item-preview
                    *ngIf="
                        item.item_type === questionType.FILE_UPLOAD &&
                        !isPrint
                    "
                    [questionItem]="item"
                    [isTakeSurvey]="isTakeSurvey"
                ></cb-file-upload-item-preview>
                <cb-custom-source-dropdownlist-item-preview
                    *ngIf="item.item_type === questionType.CUSTOM_SOURCE_DROPDOWNLIST"
                    [questionItem]="item"
                    [isPrint]="isPrint"
                    (updated)="onUpdate($event)"
                ></cb-custom-source-dropdownlist-item-preview>
                <cb-image-choice-item-preview
                    *ngIf="item.item_type === questionType.IMAGE_CHOICE"
                    (updated)="onUpdate($event)"
                    [isPrint]="isPrint"
                    [questionItem]="item">
                </cb-image-choice-item-preview>
                <div hidden>
                    <cb-javascript-item-preview
                        *ngIf="
                            item.item_type === actionType.JAVASCRIPT
                        "
                        [actionItem]="item"
                        [isTakeSurvey]="isTakeSurvey"
                    ></cb-javascript-item-preview>
                </div>
                <div hidden>
                    <cb-email-alert-item-preview
                        *ngIf="
                            item.item_type === actionType.EMAIL_ALERT
                        "
                        [actionItem]="item"
                    ></cb-email-alert-item-preview>
                </div>
                <div hidden>
                    <cb-email-response-item-preview
                        *ngIf="
                            item.item_type === actionType.EMAIL_RESPONSE
                        "
                        [actionItem]="item"
                    ></cb-email-response-item-preview>
                </div>
                <div hidden>
                    <cb-webhook-item-preview
                        *ngIf="
                            item.item_type === actionType.WEBHOOK
                        "
                        [actionItem]="item"
                    ></cb-webhook-item-preview>
                </div>
                <div>
                    <cb-hyperlink-item-preview
                        *ngIf="
                            item.item_type === actionType.REDIRECT
                        "
                        [actionItem]="item"
                        [isTakeSurvey]="isTakeSurvey"
                        [isPrint]="isPrint"
                        (restart)="onRestart($event)"
                    ></cb-hyperlink-item-preview>
                </div>
                <div hidden>
                    <cb-profile-property-item-preview
                        *ngIf="
                            item.item_type === actionType.PROFILE_PROPERTY
                        "
                        [actionItem]="item"
                    ></cb-profile-property-item-preview>
                </div>
                <cb-image-item-preview
                    *ngIf="item.item_type === displayType.IMAGE"
                    [displayItem]="item"
                ></cb-image-item-preview>
                <cb-scoring-message-item-preview
                    *ngIf="
                        item.item_type === displayType.SCORING_MESSAGE &&
                        !isPrint
                    "
                    [displayItem]="item"
                    [isTakeSurvey]="isTakeSurvey"
                    [pages]="pages"
                ></cb-scoring-message-item-preview>
                <cb-response-summary-item-preview
                    *ngIf="
                        item.item_type === reportType.SUMMARY &&
                        !isPrint
                    "
                    [reportItem]="item"
                    [isTakeSurvey]="isTakeSurvey"
                    [pages]="pages"
                ></cb-response-summary-item-preview>
                <div class="cb-validation-error">
                    <span class="invalid">{{getItemValidationError(item)}}</span>
                </div>
                <div
                    class="cb-take-survey-divider"
                    *ngIf="
                        page.page_type !== surveyPageType.Completion &&
                        item.item_type !== actionType.REDIRECT &&
                        item.item_type !== actionType.JAVASCRIPT &&
                        item.item_type !== actionType.WEBHOOK &&
                        item.item_type !== actionType.EMAIL_ALERT &&
                        item.item_type !== actionType.EMAIL_RESPONSE
                    "
                ></div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!isLast && !isPrint" class="cb-take-survey-buttons-container">
        <button
            *ngIf="showSaveButton"
            mat-button
            class="btn-prev save-and-exit-btn"
            (click)="saveAndExit()"
            [disabled]="disableButtons"
            [innerHTML]="surveyText.saveAndExitButton | keepHtml"
        >
        </button>
        <button
            *ngIf="isNotFirst && showBackButton"
            mat-button
            class="btn-prev"
            (click)="onTransition(transitionType.MoveBackward)"
            [disabled]="disableButtons"
            [innerHTML]="surveyText.backButton | keepHtml"
        >
        </button>
        <button
            *ngIf="showResponseResetButton"
            mat-button
            class="btn-next"
            (click)="onResponseReset()"
            [disabled]="disableButtons"
            [innerHTML]="surveyText.formResetButton | keepHtml"
        >
        </button>
        <button
            *ngIf="isNotBeforeLast"
            mat-button
            class="btn-next"
            (click)="onTransition(transitionType.MoveForward)"
            [disabled]="disableButtons"
            [innerHTML]="surveyText.nextButton | keepHtml"
        >
        </button>
        <button
            *ngIf="isBeforeLast"
            mat-button
            class="btn-next"
            (click)="onTransition(transitionType.MoveForward)"
            [disabled]="disableButtons"
            [innerHTML]="surveyText.finishButton | keepHtml"
        >
        </button>
    </div>
</ng-container>
