<div class="dialog-content">
    <h2 mat-dialog-title>
        {{ headerText }}
    </h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
        <p [innerHTML]="descriptionText" class="sub-section-main-text">
        </p>
        <mat-form-field class="full-width-input">
            <input
                matInput
                placeholder="{{
                    'EMAIL-VERIFICATION.DIALOG.VERIFICATION-CODE' | translate
                }}"
                formControlName="code"
            />
            <mat-error>
                {{ 'EMAIL-VERIFICATION.DIALOG.PLEASE-ENTER-CODE' | translate }}
            </mat-error>
        </mat-form-field>
        <div class="buttons-container">
            <button
                mat-button
                type="button"
                class="btn-cancel"
                mat-dialog-close
            >
                {{ 'SHARED.CANCEL' | translate }}
            </button>
            <button
                mat-button
                type="button"
                class="btn-cancel"
                (click)="resendCode()"
            >
                {{ 'EMAIL-VERIFICATION.DIALOG.RESEND' | translate }}
            </button>
            <button
                mat-raised-button
                type="submit"
                [disabled]="disableSubmitButton()"
                class="btn-submit"
            >
                {{ 'EMAIL-VERIFICATION.DIALOG.SUBMIT' | translate }}
            </button>
        </div>
    </form>
</div>
