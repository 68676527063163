import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'cb-google-signin',
    templateUrl: './google-signin.html',
    styleUrls: ['./google-signin.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSigninComponent implements OnInit {
    private normalButtonImg: string = 'assets/gogle-signin/btn_google_signin_light_normal_web.png';
    private focusButtonImg: string = 'assets/gogle-signin/btn_google_signin_light_focus_web.png';
    private pressedButtonImg: string = 'assets/gogle-signin/btn_google_signin_light_pressed_web.png';

    @Output() googleLogin: EventEmitter<any> = new EventEmitter();
    buttonImgSource: string;

    constructor(
    ) {}

    ngOnInit() {
        this.buttonImgSource = this.normalButtonImg;
    }

    onMouseDown() {
        this.buttonImgSource = this.pressedButtonImg;
    }
    onMouseUp() {
        this.buttonImgSource = this.focusButtonImg;
    }
    onMouseEnter() {
        this.buttonImgSource = this.focusButtonImg;
    }
    onMouseLeave() {
        this.buttonImgSource = this.normalButtonImg;
    }
}
