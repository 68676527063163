import { QuestionItem } from './questionItem';
import { ItemOption } from './itemOption';

export class CheckboxesItem extends QuestionItem {
    width: number;
    answer_format: string;
    select_min_limit: number;
    select_max_limit: number;
    randomize: boolean;
    allow_other: boolean;
    other_value: string;
    allow_none_of_above: boolean;
    none_value: string;
    choices: ItemOption[];
    otherOption: any;
    noneOption: any;
    alias: string;
    layout: string;
    item_position: string;
    question_text_position: string;
    columns: number;
    show_number_labels: boolean;
}
