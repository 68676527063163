<cb-svg-definitions class="cb-svg-definitions"></cb-svg-definitions>
<cb-spinner [show]="isLoading || isChangingRoute || jwtInProgress"></cb-spinner>
<cb-event-log></cb-event-log>

<div [hidden]="jwtInProgress" class="main-page" *ngIf="!isLoading && !isPrintResponsePage && !isPreviewPage"
    [ngClass]="{ 'print-report': isPrintReport$|async }">
    <div class="main-container">
        <mat-toolbar color="basic" *ngIf="(notPrinting$|async) && currentUserRoles && currentUserRoles.length">
            <div>
                <a class="toolbar-logo" [routerLink]="defaultUrl">
                    <img [src]="'assets/images/Checkbox.svg' | assetspipe" alt="{{ 'ADMIN-MENU.LOGO' | translate }}" />
                </a>
                <div>
                    <span *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.REPORT_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    ">
                        <a routerLink="/surveys" routerLinkActive="active">
                            {{ 'ADMIN-MENU.SURVEYS' | translate }}
                        </a>
                    </span>
                    <span *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.REPORT_ADMINISTRATOR,
                            userRoles.REPORT_VIEWER
                        )
                    ">
                        <a routerLink="/dashboards" routerLinkActive="active">
                            {{ 'ADMIN-MENU.DASHBOARDS' | translate }}
                        </a>
                    </span>
                    <span *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.CONTACT_ADMINISTRATOR
                        )
                    ">
                        <button mat-button [ngClass]="[isMainContactMenu() ? 'active' : '']"
                            [matMenuTriggerFor]="contactsMenu" routerLinkActive="active">
                            {{ 'ADMIN-MENU.CONTACTS.CONTACTS' | translate }}
                        </button>
                    </span>
                    <span *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    ">
                        <a routerLink="/styles" routerLinkActive="active">
                            {{ 'ADMIN-MENU.STYLES' | translate }}
                        </a>
                    </span>
                    <span *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    ">
                        <a routerLink="/survey-templates" routerLinkActive="active">
                            {{ 'ADMIN-MENU.SURVEY-TEMPLATES' | translate }}
                        </a>
                    </span>
                </div>
                <div>
                    <button mat-button [matMenuTriggerFor]="appMenu" (menuClosed)="appMenuClose()"
                        (menuOpened)="appMenuOpen()" [ngClass]="['menu-profile-control', isMenuOpened]">
                        <div>
                            <mat-icon class="user" svgIcon="fi_user"></mat-icon>
                            <span>{{ userName }}</span>
                            <mat-icon class="icon-down" svgIcon="fi_chevron_down"></mat-icon>
                            <mat-icon class="icon-up" svgIcon="fi_chevron_up"></mat-icon>
                        </div>
                    </button>
                </div>
            </div>

        </mat-toolbar>
        <mat-menu #appMenu="matMenu" overlapTrigger="false"  xPosition="after">
            <button mat-menu-item (click)="clickOnMenuItem(EDIT_MY_INFO)">
                <mat-icon svgIcon="fi_user_edit"></mat-icon>
                <span>{{ 'EDIT-MY-INFO.MENU-ITEM' | translate }}</span>
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(UPGRADE_ACTION)"
                *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR) && isUpgradeable">
                <mat-icon svgIcon="fi_upgrade"></mat-icon>
                <span>{{ 'ADMIN-MENU.UPGRADE-ACCOUNT' | translate }}</span>
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(SETTINGS_ACTION)"
                *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR)">
                <mat-icon svgIcon="fi_account_setting"></mat-icon>
                <span>{{ 'ADMIN-MENU.ACCOUNT-SETTINGS' | translate }}</span>
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(SYSTEM_ACTION)"
                *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR)">
                <mat-icon svgIcon="fi_system"></mat-icon>
                <span>System</span>
                
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(DOWNLOADS)">
                <mat-icon svgIcon="fi_download"></mat-icon>
                <span>{{ 'ADMIN-MENU.DOWNLOADS' | translate }}</span>
            </button>
            <button mat-menu-item *ngIf="isMultiTenant" (click)="clickOnMenuItem(SUPPORT_ACTION)">
                <mat-icon svgIcon="fi_support"></mat-icon>
                <span>{{ 'ADMIN-MENU.CUSTOMER-SUPPORT' | translate }}</span>
            </button>
            <button mat-menu-item (click)="helpGuides()">
                <mat-icon svgIcon="fi_book"></mat-icon>
                <span>{{ 'ADMIN-MENU.CUSTOMER-HELP' | translate }}</span>
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(SIGNOUT_ACTION)">
                <mat-icon svgIcon="fi_logout"></mat-icon>
                <span>{{ 'ADMIN-MENU.LOG-OUT' | translate }}</span>
            </button>
        </mat-menu>
        <mat-menu #contactsMenu="matMenu" overlapTrigger="false">
            <a mat-menu-item routerLink="/contacts" routerLinkActive="active">
                <mat-icon svgIcon="fi_user_2"></mat-icon>
                <span>{{ 'ADMIN-MENU.CONTACTS.CONTACTS' | translate }}</span>
            </a>
            <a mat-menu-item routerLink="/groups" routerLinkActive="active">
                <mat-icon svgIcon="fi_users"></mat-icon>
                <span>{{ 'ADMIN-MENU.CONTACTS.GROUPS' | translate }}</span>
            </a>
        </mat-menu>
        <cb-header-level2 [currentPathConfig]="pathUrlConfig" *ngIf="(notPrinting$|async) && currentUserRoles && currentUserRoles.length"></cb-header-level2>
        <div class="cb-app-content" [hidden]="isChangingRoute">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div class="print-section" *ngIf="isPrintResponsePage">
    <router-outlet [hidden]="isChangingRoute"></router-outlet>
</div>
<div class="print-section" *ngIf="isPreviewPage">
    <router-outlet></router-outlet>
</div>