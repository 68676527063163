import { Component } from '@angular/core';

import { Signup } from '../../../infrastructure/models';
import { IdentityStore } from '../../../infrastructure/store';

@Component({
    selector: 'cb-singup-page',
    templateUrl: './singup-page.component.html',
    styleUrls: ['./singup-page.component.scss']
})
export class SignupPageComponent {
    constructor(private store: IdentityStore) {}

    public onSignup(user: Signup) {
        // console.log('data->');
        //  console.log(user);
        // this.authService.signUp(user);
    }
}
