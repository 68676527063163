import { JavascriptItem } from '../../../models/survey-items/action-items/javascriptItem';
import { Component, Input, OnInit } from '@angular/core';
import { appendScriptToBody } from '../../../../infrastructure/helpers/javascript.helper';
import { ComponentPrintStatus, PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-javascript-item-preview',
    templateUrl: './javascript-item-preview.component.html',
    styleUrls: ['./javascript-item-preview.component.scss']
})
export class JavascriptPreviewItemComponent implements OnInit {
    @Input() actionItem: JavascriptItem;
    @Input() isTakeSurvey: boolean;
    constructor(private printService: PrintService) { }

    ngOnInit() {
        if (this.isTakeSurvey && this.actionItem.script) {
            appendScriptToBody(this.actionItem.script);
        }

        this.printService.setStatus(this.actionItem.id, ComponentPrintStatus.RENDERED);
    }
}
