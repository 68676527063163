import { QuestionItem } from './questionItem';
import { CustomSourceDropdownlistAnswer } from '../../../../app-take-survey/models';

export class CustomSourceDropdownListItem extends QuestionItem {
    randomize: boolean;
    allow_other: boolean;
    other_text: string;
    other_value: string;
    show_number_labels: boolean;
    question_text_position: string;
    item_position: string;
    choice_list_url: string;
    value_field: string;
    alias: string;
    value: string;
    answer?: CustomSourceDropdownlistAnswer;
}
