import { ItemBase } from '../itemBase';

export class ResponseSummaryItem extends ItemBase {
    display_inline: boolean;
    link_text: string;
    include_response_details: boolean;
    show_page_numbers: boolean;
    show_question_numbers: boolean;
    include_message_items: boolean;
    show_hidden_items: boolean;
    show_print_button: boolean;
    width: number;
    height: number;
    item_position: string;
    surveyData: any;
    show_points: boolean;
}
