import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FileUploadItem } from '../../../models/survey-items/question-items/fileUploadItem';
import * as _ from 'lodash';
import { RespondentFileUploadProvider } from '../../../../app-take-survey/providers/respondent-file-upload.provider';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { ToasterService } from '../../../../infrastructure/services';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription } from 'rxjs';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';

@Component({
    selector: 'cb-file-upload-item-preview',
    templateUrl: './file-upload-item-preview.component.html',
    styleUrls: ['./file-upload-item-preview.component.scss']
})
export class FileUploadPreviewItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: FileUploadItem;
    @Input() isTakeSurvey: boolean;
    @ViewChild('fileInput') fileInputVariable: ElementRef;
    fileData: FormData;
    showFileInput: boolean;
    isLoading: boolean;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    allowedFileTypes;
    questionId = TakeSurveyData.QUESTION_ID;
    surveyId: number;
    responseId: string;

    constructor(
        private toasterService: ToasterService,
        private sharedService: SharedService,
        private fileUploadProvider: RespondentFileUploadProvider,
        private changeDetectorRef: ChangeDetectorRef
        ) { }

    ngOnInit() {
        this.showFileInput = !this.questionItem.file_name;
        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
        this.allowedFileTypes = this.questionItem.allowed_file_types.map(type => type.toLowerCase());
        if (this.isTakeSurvey) {
            this.surveyId = this.questionItem.surveyId;
            this.responseId = this.questionItem.responseId;
        }
    }

    onUploadFile($event) {
        if (!this.surveyId || !this.responseId) {
            this.resetFileInput();
            return;
        }
        
        if (<File>$event.target.files[0]) {
            const file = <File>$event.target.files[0];
            const fileType = file.name.split('.').pop().toLowerCase();
            const isAllowedType = this.allowedFileTypes.includes(`.${fileType}`);
            if (!isAllowedType) {
                this.resetFileInput();
                return;
            }
            this.fileData = new FormData();
            this.fileData.append('content', file, file.name);
        }

        this.isLoading = true;
        this.fileUploadProvider.uploadRespondentFile(
            this.surveyId, this.responseId, this.questionItem.id, this.fileData)
            .subscribe(img => {
                this.showFileInput = false;
                this.questionItem.file_name = img.file_name;
                this.questionItem.file_id = img.id;
                this.questionItem.file_size = img.content_size;
                this.isLoading = false;
            }, err => this.handleError(err));
    }

    handleError(error) {
        this.isLoading = false;
    }

    resetFileInput() {
        this.showFileInput = true;
        if (this.fileInputVariable) {
            this.fileInputVariable.nativeElement.value = '';
        }
        this.questionItem.file_id = this.questionItem.file_name = null;
        if (this.questionItem.answer && this.questionItem.answer.file) {
            this.questionItem.answer.file.file_id = this.questionItem.answer.file.file_name = null;
        }
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
