import { defer, Observable, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

export function debounceWithFirst(
    firstValueDueTime: number, dueTime: number): <T>(source: Observable<T>) => Observable<T> {

    return <T>(source: Observable<T>) =>
        defer(() => {
            let lastTime: number = null;

            return source.pipe(
                debounce(() => {
                    const nowTime = new Date().getTime();
                    const isFirstValue = !lastTime || nowTime - lastTime > dueTime; 
                    lastTime = nowTime;
                    return isFirstValue ? timer(firstValueDueTime) : timer(dueTime);
                })
            );
        });
}