<div class="matrix" *ngIf="!isPrint">
    <div
        class="cb-question-item-wrapper-outer {{
            this.questionItem.html_class
        }}"
    >
        <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
            <cb-questions-header
                [questionItem]="questionItem"
            ></cb-questions-header>
            <div
                class="cb-question-item-content matrix-content"
                [ngStyle]="{ 'width.px': questionItem.width }"
            >
                <table
                    class="cb-matrix-table"
                    tabindex="0"
                    role="table" [attr.aria-labelledby]="questionId + questionItem.id"
                    [ngClass]="{
                        'table-single-column': headers.length === 1,
                        'table-multiple-columns': headers.length > 1,
                        'matrix-borders-horizontal': questionItem.grid_lines === 'Horizontal',
                        'matrix-borders-vertical': questionItem.grid_lines === 'Vertical',
                        'matrix-borders-both': questionItem.grid_lines === 'Both'
                    }"
                >
                    <thead *ngIf="showQuestionTextRow || showChoiceTextRow">

                    <tr *ngIf="showQuestionTextRow"
                        class="header-column-group"
                    >
                        <ng-container *ngFor="let header of headers; let headerIndex = index">
                            <th *ngIf="header.text && header.text !== emptyRootElement;  else emptyHeaderCell"
                                [id]="tableHeadIds.upperTableHeader + headerIndex"
                                class="matrix-group-cell group-cell-title"
                                [innerHTML]="header.text | keepHtml"
                            ></th>
                            <ng-template #emptyHeaderCell>
                                <td class="matrix-group-cell group-cell-title"></td>
                            </ng-template>

                        </ng-container>
                    </tr>


                    <tr *ngIf="showChoiceTextRow">
                        <ng-container *ngFor="let header of headers; let headerIndex = index">
                        <th *ngIf="header.colNames.length || header.ratingScaleLabels.length; else emptyChoiceTextCell"
                            [id]="tableHeadIds.choiceHeader + headerIndex"
                            class="matrix-group-cell header-with-options">

                            <div
                                class="matrix-rating-scale-labels"
                                *ngIf="header.ratingScaleLabels.length"
                                [ngClass]="{
                                        'with-NA-option':
                                            header.enable_not_applicable
                                    }"
                                [ngStyle]="{'width': getRatingScaleLabelsWidth(header)}">
                                <label
                                    *ngFor="let ratingScaleLabel of header.ratingScaleLabels; let i = index"
                                    [ngStyle]="{'width': getRatingScaleLabelWidth(header, i)}"
                                >{{ ratingScaleLabel || '' }}</label
                                >
                            </div>

                            <div class="header-with-options-table table-matrix-group table-header"
                                 [ngClass]="{
                                        'with-NA-option':
                                            header.enable_not_applicable,
                                        'table-matrix-group--rating-scale':
                                            header.itemType === ratingScale,
                                        'table-matrix-group--slider':
                                            header.itemType === slider
                                    }">
                                <div class="header-with-options-table-row table-align-header-col"
                                [ngClass]="{'table-align-header-col--slider': header.itemType === slider}">
                                    <ng-container *ngFor="let colName of header.colNames">
                                        <span role="columnheader"
                                              class="header header-with-options-table-row-header"
                                              [ngClass]="{'header-rating-scale': header.itemType === ratingScale,
                                              'header-slider': header.itemType === slider}"
                                              [innerHTML]="(colName) ? (colName | keepHtml) : ''"
                                              [style.width]="calcWidth(header)"
                                        ></span>
                                    </ng-container>

                                </div>
                                <div class="header-with-options-table-row matrix-rating-scale-point-labels table-align-header-col" *ngIf="header.ratingScaleShowPointLabels">
                                    <span
                                        role="columnheader"
                                        class="header points-header header-with-options-table-row-header"
                                        *ngFor="let pointsLabel of header.ratingScalePointLabels"
                                        [innerHTML]="pointsLabel || ''"
                                        [style.width]="calcWidth(header)"
                                    ></span>
                                </div>
                            </div>

                        </th>

                            <ng-template #emptyChoiceTextCell>
                                <td class="matrix-group-cell header"></td>
                            </ng-template>
                        </ng-container>
                    </tr>


                    </thead>

                    <tbody>
                    <ng-container *ngFor="let row of matrix.rows; let rowIndex = index">
                        <tr
                            *ngIf="checkOnExcluded(row.id, rowIndex)"
                            [ngClass]="{
                                    'header-column-group':
                                        row.row_type === subHeadingRowType
                                }"
                        >
                            <ng-container *ngFor="let column of row.columns; let columnIndex = index">
                                <th
                                    *ngIf="column.column_type === 'RowTexts'; else notRowTextCell"
                                    scope="row"
                                    class="td-padding"
                                    [ngClass]="{
                                    'matrix-group-cell': row.row_type !== subHeadingRowType,
                                    'table-header-horizontal': row.row_type !== subHeadingRowType
                                }"
                                    [id]="tableHeadIds.rowHeader + rowIndex">
                                    <div [ngClass]="getMatrixRowClass()">
                                        <span
                                            *ngIf="row.row_type === 'Normal' || row.row_type === subHeadingRowType"
                                            [innerHtml]="row.text | keepHtml"
                                        ></span>
                                        <span *ngIf="row.row_type === otherRowType" aria-label="No value">
                                                <input
                                                    type="text"
                                                    [value]="row.text"
                                                    [title]="row.text"
                                                    class="otherRow"
                                                    (keyup)="rowTextChanged($event, row)"
                                                />
                                            </span>
                                    </div>
                                </th>


                                <ng-template #notRowTextCell>
                                    <td
                                        [ngStyle]="{ 'min-width.px': column.width }"
                                        [headers]="tableHeadIds.upperTableHeader + columnIndex + ' ' + tableHeadIds.rowHeader + rowIndex + ' ' + tableHeadIds.choiceHeader + columnIndex"
                                        [ngClass]="{
                                            'matrix-group-cell':
                                                row.row_type !== subHeadingRowType
                                        }"
                                    >
                                        <div
                                            *ngIf="column.prototype_item && row.row_type !== subHeadingRowType"
                                        >
                                            <cb-dropdownlist-item-preview
                                                *ngIf="
                                                    column.prototype_item
                                                        .item_type === dropdownList
                                                "
                                                [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                [isMatrix]="true"
                                                (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-dropdownlist-item-preview>

                                            <cb-custom-source-dropdownlist-item-preview
                                                *ngIf="column.prototype_item.item_type === customSourceDropdownList"
                                                [questionItem]="
                                                getColumnItem(
                                                    column.prototype_item,
                                                    row.position,
                                                    column.position
                                                )
                                            "
                                                [isMatrix]="true"
                                                (updated)="
                                                updateMatrixValueAnswers(
                                                    $event,
                                                    row.position,
                                                    column.position
                                                )
                                            "
                                            ></cb-custom-source-dropdownlist-item-preview>

                                            <cb-checkboxes-item-preview
                                                *ngIf="
                                                    column.prototype_item
                                                        .item_type === checkBoxes
                                                "
                                                class="d-block"
                                                [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                [isMatrix]="true"
                                                (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-checkboxes-item-preview>

                                            <cb-radiobuttons-item-preview *ngIf="
                                                    column.prototype_item
                                                        .item_type === radiobuttons
                                                "
                                                                          [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                                          [isMatrix]="true"
                                                                          (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-radiobuttons-item-preview>

                                            <cb-singlelines-item-preview
                                                *ngIf="
                                                    column.prototype_item
                                                        .item_type ===
                                                        singleLineText ||
                                                    column.prototype_item
                                                        .item_type ===
                                                        matrixSumTotal
                                                "
                                                [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                [isSubQuestion]="true"
                                                (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-singlelines-item-preview>

                                            <cb-rating-scale-item-preview
                                                *ngIf="
                                                    column.prototype_item
                                                        .item_type === ratingScale
                                                "
                                                [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                [isMatrix]="true"
                                                (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-rating-scale-item-preview>

                                            <cb-slider-item-preview
                                                *ngIf="
                                                    column.prototype_item
                                                        .item_type === slider
                                                "
                                                [questionItem]="
                                                    getColumnItem(
                                                        column.prototype_item,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                                [isMatrix]="true"
                                                (updated)="
                                                    updateMatrixValueAnswers(
                                                        $event,
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            >
                                            </cb-slider-item-preview>

                                            <span
                                                class="invalid"
                                                style="display: block"
                                                [ngClass]="{
                                                    'has-width-limitation':
                                                        column.prototype_item
                                                            .item_type ===
                                                            singleLineText ||
                                                        column.prototype_item
                                                            .item_type ===
                                                            matrixSumTotal
                                                }"
                                                *ngFor="
                                                    let text of getMatrixSubItemErrorMessages(
                                                        row.position,
                                                        column.position
                                                    )
                                                "
                                            > {{questionItem && questionItem.server_error_messages ? (text | async) :  text}}
                                        </span>

                                        </div>


                                    </td>
                                </ng-template>

                            </ng-container>
                        </tr>
                    </ng-container>

                    <tr class="header-column-group"
                        *ngIf="
                                hasSumTotalColumn ||
                                matrixColumnHasValidationErrors
                            "
                    >
                        <td
                            class="question-cell"
                            *ngIf="
                                    !isSumTotalColumn(matrix.rows[0].columns[0])
                                "
                        >
                            <span *ngIf="hasSumTotalColumn">Total</span>
                        </td>
                        <td class="header-column-group"

                            *ngFor="
                                    let column of checkSumTotalPos(
                                        matrix.rows[0].columns
                                    )
                                "
                        >
                            <div *ngIf="isSumTotalColumn(column)">
                                {{ getTotalForSumTotalColumn(column) }}
                            </div>
                            <span
                                class="invalid"
                                *ngFor="
                                        let text of getMatrixColumnValidationMessages(column.id)
                                    "
                            >
                                    {{ text }}<br/>
                                </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <span
                    class="invalid"
                    *ngIf="matrixColumnHasValidationErrors || (validationMessages && checkOnRequired())">
                    <br/>
                    {{validationText.AnswerRequired}}
                </span>
            </div>
        </div>
    </div>
</div>

<div class="print-matrix" *ngIf="isPrint">
    <cb-questions-header [questionItem]="questionItem"></cb-questions-header>
    <ng-container *ngFor="let row of matrix.rows; let rowIndex = index">
        <div
            class="print-row"
            *ngIf="checkOnExcluded(row.id, rowIndex)"
            [ngClass]="{
                'header-column-group': row.row_type === subHeadingRowType
            }"
        >
            <div
                class="question-cell"
                [ngClass]="{
                    'group-cell-title': row.row_type === subHeadingRowType
                }"
            >
                <span *ngIf="row.row_type === 'Normal'" [innerHtml]="row.text | keepHtml">
                </span>
                <span *ngIf="row.row_type === 'Other'">
                    <input
                        type="text"
                        [value]="row.text"
                        class="otherRow"
                        (keyup)="rowTextChanged($event, row)"
                    />
                </span>
                <span
                    *ngIf="row.row_type === subHeadingRowType"
                    [innerHtml]="row.text | keepHtml"
                ></span>
            </div>

            <div
                *ngFor="let column of row.columns"
                class="matrix-group-cell"
                [ngStyle]="{ 'min-width.px': column.width }"
                [ngClass]="{
                    'empty-cell': row.row_type === subHeadingRowType
                }"
            >
                <div *ngIf="column.prototype_item && row.row_type !== subHeadingRowType">
                    <cb-dropdownlist-item-preview
                        *ngIf="column.prototype_item.item_type === dropdownList"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isPrint]="true"
                        [isMatrix]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-dropdownlist-item-preview>

                    <cb-custom-source-dropdownlist-item-preview
                        *ngIf="column.prototype_item.item_type === customSourceDropdownList"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isMatrix]="true"
                        [isPrint]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    ></cb-custom-source-dropdownlist-item-preview>

                    <cb-checkboxes-item-preview
                        *ngIf="column.prototype_item.item_type === checkBoxes"
                        class="d-block"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isMatrix]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-checkboxes-item-preview>
                    <cb-radiobuttons-item-preview
                        *ngIf="column.prototype_item.item_type === radiobuttons"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isMatrix]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-radiobuttons-item-preview>
                    <cb-singlelines-item-preview
                        *ngIf="
                            column.prototype_item.item_type ===
                                singleLineText ||
                            column.prototype_item.item_type === matrixSumTotal
                        "
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isSubQuestion]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-singlelines-item-preview>
                    <cb-rating-scale-item-preview
                        *ngIf="column.prototype_item.item_type === ratingScale"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isMatrix]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-rating-scale-item-preview>
                    <cb-slider-item-preview
                        *ngIf="column.prototype_item.item_type === slider"
                        [questionItem]="
                            getColumnItem(
                                column.prototype_item,
                                row.position,
                                column.position
                            )
                        "
                        [isMatrix]="true"
                        (updated)="
                            updateMatrixValueAnswers(
                                $event,
                                row.position,
                                column.position
                            )
                        "
                    >
                    </cb-slider-item-preview>
                </div>
            </div>
        </div>
    </ng-container>
</div>
