<div
    *ngIf="!isPrint"
    class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}"
>
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <div
            class="cb-question-item-content contact-form-content"
            [ngStyle]="{ 'width.px': questionItem.width }"
        >
            <table
                class="cb-contact-form-table"
                tabindex="0"
                role="table"
                [attr.aria-labelledby]="questionId + questionItem.id"
            >
                <tbody>
                    <ng-container *ngFor="let field of enabledFormFields">
                        <tr *ngIf="!isSubquestionExcluded(field)">
                            <th
                                scope="row"
                                class="contact-form-table-cell"
                                [id]="tableRowHeaderPrefix + field.question.id"
                            >
                                <div
                                    [innerHtml]="field.question.question_text | keepHtml"
                                    [class.required]="
                                        field.question.is_required &&
                                        (appearanceSettings && appearanceSettings.showAsterisks)
                                    "
                                ></div>
                            </th>
                            <td
                                class="contact-form-table-cell"
                                [headers]="tableRowHeaderPrefix + field.question.id"
                            >
                                <div>
                                    <cb-singlelines-item-preview
                                        [questionItem]="field.question"
                                        [isSubQuestion]="true"
                                        (updated)="updateSubquestionAnswer(field, $event)"
                                    >
                                    </cb-singlelines-item-preview>
                                    <span
                                        class="invalid has-width-limitation"
                                        *ngFor="let text of getSubquestionErrorMessages(field)"
                                    >
                                        {{questionItem && questionItem.server_error_messages ? (text | async) :  text}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div>
            <span
                class="invalid"
                *ngIf="hasServerOrRequiredValidationError()"
            >
                <br/>
                {{validationText.AnswerRequired}}
            </span>
        </div>
    </div>
</div>

<div class="print-contact-form" *ngIf="isPrint">
    <cb-questions-header [questionItem]="questionItem"></cb-questions-header>
    <div class="contact-form-table">
        <ng-container *ngFor="let field of enabledFormFields">
            <div
                class="contact-form-table-row"
                *ngIf="!isSubquestionExcluded(field)"
            >
                <div class="contact-form-table-cell">
                    <div
                        [innerHtml]="field.question.question_text | keepHtml"
                        [class.required]="
                            field.question.is_required &&
                            (appearanceSettings && appearanceSettings.showAsterisks)
                        "
                    ></div>
                </div>

                <div class="contact-form-table-cell">
                    <cb-singlelines-item-preview
                        [questionItem]="field.question"
                        [isSubQuestion]="true"
                        (updated)="updateSubquestionAnswer(field, $event)"
                    >
                    </cb-singlelines-item-preview>
                </div>
            </div>
        </ng-container>
    </div>
</div>
