import { Action } from '@ngrx/store';

import { Contact, Contacts } from '../../models';
import { PageRequestOptions } from '../../../infrastructure/models';

export const GET_USER = '[Contact] Get Contact';
export const GET_USER_SUCCESS = '[Contact] Get Contact Success';
export const GET_USERS = '[Contact] Get Users';
export const GET_USERS_PAGE = '[Contact] Get Users Page';
export const GET_USERS_SUCCESS = '[Contact] Get Users Success';
export const ADD_USER = '[Contact] Add Contact';
export const ADD_USER_SUCCESS = '[Contact] Add Contact Success';
export const USER_FAILED = '[Contact] Contact Failed';
export const REMOVE_USER = '[Contact] Remove Contact';
export const REMOVE_USER_SUCCESS = '[Contact] Remove Contact Success';

export class GetUserAction implements Action {
    readonly type = GET_USER;

    constructor(public payload: string) {}
}

export class GetUserSuccessAction implements Action {
    readonly type = GET_USER_SUCCESS;

    constructor(public payload: Contact = null) {}
}

export class GetUsersAction implements Action {
    readonly type = GET_USERS;
}

export class GetUsersPageAction implements Action {
    readonly type = GET_USERS_PAGE;

    constructor(public payload: PageRequestOptions) {}
}

export class GetUsersSuccessAction implements Action {
    readonly type = GET_USERS_SUCCESS;

    constructor(public payload: Contacts) {}
}

export class AddUserAction implements Action {
    readonly type = ADD_USER;

    constructor(public payload: Contact) {}
}

export class AddUserSuccessAction implements Action {
    readonly type = ADD_USER_SUCCESS;

    constructor(public payload: Contact) {}
}

export class RemoveUserAction implements Action {
    readonly type = REMOVE_USER;

    constructor(public payload: string) {}
}

export class RemoveUserSuccessAction implements Action {
    readonly type = REMOVE_USER_SUCCESS;

    constructor(public payload: Contact) {}
}

export class UserFailedAction implements Action {
    readonly type = USER_FAILED;

    constructor() {}
}

export type Actions =
    | GetUserAction
    | GetUsersPageAction
    | GetUserSuccessAction
    | GetUsersAction
    | GetUsersSuccessAction
    | AddUserAction
    | AddUserSuccessAction
    | RemoveUserAction
    | RemoveUserSuccessAction
    | UserFailedAction;
