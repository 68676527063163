import { QuestionItem } from './questionItem';

export class RankOrderItem extends QuestionItem {
    alias: string;
    randomize: boolean;
    rank_order_type: string;
    option_type: string;
    min_to_rank: number;
    max_to_rank: number;
    choices: any;
    question_text_position: string;
    item_position: string;
    choices_to_rank?: any;
    selected_choices?: any;
    answer?: any;
}
