import * as survey from '../actions/current-survey.actions';
import { State, initialState } from './current-survey.state';

export function currentSurveyReducer(
    state = initialState,
    action: survey.Actions
): State {
    switch (action.type) {
        case survey.LOAD_SURVEY: {
            return {
                ...state,
                isLoading: true,
                currentSurveyParams: action.payload
            };
        }
        case survey.LOAD_SURVEY_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                isLoading: false,
                survey: data
            };
        }
        case survey.DISPOSE_CURRENT_SURVEY, survey.LOAD_SURVEY_FAILED: {
            return {
                ...state,
                survey: null,
                isLoading: false,
                currentSurveyParams: null
            };
        }
        case survey.PRINT_SURVEY: {
            return {
                ...state,
                isPrinting: action.payload
            };
        }
        case survey.CHANGE_CURRENT_SURVEY_URL: {
            return {
                ...state,
                survey: {
                    ...state.survey,
                    custom_url: action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
}
