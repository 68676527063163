import { Contact, Contacts } from '../../models';

export interface State {
    isLoading: boolean;
    selectedUser: Contact;
    addedUser: Contact;
    users: Contacts;
}

export const initialState: State = {
    isLoading: false,
    selectedUser: null,
    addedUser: null,
    users: <Contacts>{ items: <Contact[]>[] }
};
