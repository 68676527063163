export class LayoutActions {
    public static OPEN_CLOSE_SIDENAV = 'Open and Close Sidenav';
    public static SIGNOUT = 'Signout';
    public static SUPPORT = 'Support';
    public static SETTINGS = 'Settings';
    public static SYSTEM = 'System';
    public static DOWNLOADS = 'Downloads';
    public static EDIT_MY_INFO = 'Edit my info';
    public static UPGRADE = 'Upgrade';
}
