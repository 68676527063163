import { SurveyLayoutType } from '../../infrastructure/consts/surveys.consts';
import { RatingScaleItem } from "../../shared/models/survey-items/question-items/ratingScaleItem";

export function prepareStyle(questionItem, controls) {
    const horizontalLayoutType = SurveyLayoutType.HORIZONTAL;
    const columns = questionItem.columns ? questionItem.columns : 1;
    const columnCount =
        columns === 1 && questionItem.layout === horizontalLayoutType
            ? controls.length
            : columns;
    const rowCount = Math.ceil(controls.length / columns);
    const cellStyles = [];
    let styleObj;
    if (questionItem.layout === horizontalLayoutType) {
        controls.forEach((item, i) => {
            const index = i + 1;
            if (index <= columnCount) {
                cellStyles.push({
                    'grid-column': index,
                    'grid-row': 1,
                    '-ms-grid-row': 1,
                    '-ms-grid-column': index
                });
            } else {
                const column =
                    index % columnCount ? index % columnCount : columnCount;
                cellStyles.push({
                    'grid-column': column,
                    'grid-row': Math.ceil(index / columnCount),
                    '-ms-grid-row': Math.ceil(index / columnCount),
                    '-ms-grid-column': column
                });
            }
        });
    } else {
        controls.forEach((item, i) => {
            const index = i + 1;
            if (index <= rowCount) {
                cellStyles.push({
                    'grid-column': 1,
                    'grid-row': index,
                    '-ms-grid-row': index,
                    '-ms-grid-column': 1
                });
            } else {
                const row = index % rowCount ? index % rowCount : rowCount;
                cellStyles.push({
                    'grid-column': Math.ceil(index / rowCount),
                    'grid-row': row,
                    '-ms-grid-row': row,
                    '-ms-grid-column': Math.ceil(index / rowCount)
                });
            }
        });
    }
    if (questionItem.layout === horizontalLayoutType) {
        styleObj = {
            'grid-template-columns': `repeat(${columnCount}, minmax(0px,1fr))`,
            '-ms-grid-columns': 'minmax(0px,1fr) '.repeat(columnCount)
        };
    } else {
        styleObj = {
            'grid-template-columns': `repeat(${
                questionItem.columns
            }, minmax(0px,1fr))`,
            '-ms-grid-columns': 'minmax(0px,1fr) '.repeat(questionItem.columns),
            'grid-template-rows': `repeat(${rowCount}, max-content)`,
            '-ms-grid-rows': 'max-content '.repeat(rowCount),
            'grid-auto-flow': 'column'
        };
    }
    return [styleObj, cellStyles];
}

export function prepareHorizontalRatingScaleStyle(questionItem: RatingScaleItem) {
    const radioButtonGroupStyle = {
        'grid-template-columns': `repeat(${questionItem.choices.length}, 1fr)`,
        '-ms-grid-columns': '1fr '.repeat(questionItem.choices.length)
    };
    const radioButtonStyles = [];
    questionItem.choices.forEach((item, i) => {
        radioButtonStyles.push({
            'grid-column': i + 1,
            'grid-row': 1,
            '-ms-grid-column': i + 1,
            '-ms-grid-row': 1
        });
    });
    return [radioButtonGroupStyle, radioButtonStyles];
}