<div class="cb-login-content">
    <img
        [src]="'assets/images/Checkbox.svg' | assetspipe"
        alt="Checkbox"
        class="login-log-image"
    />
    <mat-card>
        <mat-card-title>
            Sign in
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <ng-container *ngIf="!isServerVersion && !this.accountName">
                    <mat-form-field class="cb-50-width-input cb-left">
                        <input
                            matInput
                            placeholder="Your Checkbox Account Name"
                            formControlName="account"
                        />
                        <mat-error>
                            Don't know your account name? Contact us at
                            support@checkbox.com.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="cb-50-width-input cb-right">
                        <label>.{{ getEnvironmentDomain() }}</label>
                        <input matInput [hidden]="true" />
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="cb-full-width-input">
                    <input
                        matInput
                        placeholder="Username"
                        formControlName="id"
                    />
                    <mat-error>
                        Please type your username
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="cb-full-width-input">
                    <input
                        matInput
                        type="password"
                        placeholder="Password"
                        formControlName="password"
                    />
                    <mat-error>
                        Please type your password
                    </mat-error>
                </mat-form-field>
                <div class="cb-full-width-input" *ngIf="captchaKeys">
                    <re-captcha  class="center"
                        (resolved)="resolveCaptcha($event)"
                        #captchaRef="reCaptcha"
                        theme="ligth"
                        [size]="settings.is_invisible_captcha ? 'invisible' : 'normal'"
                        [siteKey]="settings.is_invisible_captcha ? captchaKeys.invisible_recaptcha_site_key : captchaKeys.v2_recaptcha_site_key"
                    ></re-captcha>
                    <mat-error *ngIf="!settings.is_invisible_captcha && form.controls?.captcha?.errors">
                        {{ 'VALIDATION.CaptchaRequired' | translate }}
                    </mat-error>
                </div>
                <mat-card-actions >
                    <div class="form-actions-block">
                        <button
                            *ngIf="settings == null || settings.allow_password_reset"
                            mat-button
                            type="button"
                            class="btn-cancel"
                            [routerLink]="['/reset-password']"
                        >
                            Forgot password
                        </button>
                        <button
                            mat-raised-button
                            class="btn-submit"
                            type="submit"
                        >
                            Sign in
                        </button>
                        <div class="strike">
                            <span>or</span>
                        </div>
                    </div>
                    <div *ngIf="googleAuthClientId">
                        <cb-google-signin
                            (click)="onGoogleLogin()"
                        >
                        </cb-google-signin>
                    </div>
                    <div>
                        <button
                            mat-raised-button
                            class="saml-button"
                            type="button"
                            (click)="onSamlLogin()"
                        >
                            <span class="saml-text">SSO Login</span>
                        </button>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
</div>
