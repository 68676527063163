<mat-form-field>
        <input
            matInput
            [attr.aria-labelledby]="questionId + questionItem.id"
            [matDatepicker]="picker"
            [formControl]="datePicker"
            (dateChange)="dateChangedHandler($event.value)"
        />

    <mat-datepicker-toggle matSuffix [for]="picker">
        <cb-svg-icon class="icon" [name]="'calendar'"></cb-svg-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="questionItem.is_required"
        >Answer is<strong> required</strong></mat-error
    >
</mat-form-field>
