<div [ngStyle]="{ 'text-align': actionItem.item_position }">
    <h3 *ngIf="!this.isTakeSurvey">
        {{
            this.actionItem.auto_redirect
                ? autoRedirectString
                : 'Display a hyperlink to the respondent:'
        }}
    </h3>

    <ng-container *ngIf="!actionItem.restart_survey">
        <a
            [href]="actionItem.url"
            #link
            [target]="actionItem.open_in_new_window ? '_blank' : ''"
            >{{ !actionItem.auto_redirect ? actionItem.text : '' }}</a
        >
    </ng-container>
    <ng-container *ngIf="actionItem.restart_survey">
        <a href="#" (click)="onClick($event)">{{ actionItem.text }}</a>
    </ng-container>
    <br />
    <p *ngIf="!this.isTakeSurvey">[ {{ actionItem.url }} ]</p>
</div>
