import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SingleLineItem } from '../../../models/survey-items/question-items/singleLineItem';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable, Subscription } from 'rxjs';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const ROTW_DATE_FORMATS = {
    parse: {
      dateInput: 'D/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
  };

@Component({
    selector: 'cb-datepicker-en-gb',
    templateUrl: './datepicker-en-gb.component.html',
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: ROTW_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
      ]
})
export class DatePickerEnGBComponent implements OnInit, OnDestroy {
    @Input() questionItem: SingleLineItem;
    @Output() updated = new EventEmitter<any>();
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
    @Input() resetForm: Observable<void>;
    datePicker: FormControl;
    resetSubscription: Subscription;
    surveyText = SurveyDefaultText;
    questionId = TakeSurveyData.QUESTION_ID;

    ngOnInit(): void {
        let dateMomemnt: Moment;
        if (this.questionItem.default_text && !this.questionItem.default_text.startsWith('@@')) {
            if (moment(this.questionItem.default_text, 'DD/MM/YYYY', true).isValid()) {
                dateMomemnt = moment(this.questionItem.default_text, 'DD/MM/YYYY', true);
            } else if (this.questionItem.default_text.trim().split(' ').length > 1) {
                dateMomemnt = moment(this.questionItem.default_text.split(' ')[0], 'DD/MM/YYYY', true);
            } else {
                dateMomemnt = moment(this.questionItem.default_text.split('T')[0], 'YYYY-MM-DD', true);
            }
        }
        this.datePicker = new FormControl(
            dateMomemnt,
            this.questionItem.is_required ? [Validators.required] : null
        );
        if (this.questionItem.default_text) {
            this.dateChangedHandler(dateMomemnt);
        }
        this.resetSubscription = this.resetForm.subscribe(() => this.datePicker.reset());
    }

    dateChangedHandler(dateMomemnt: Moment) {
        const ev = dateMomemnt ? dateMomemnt.format('DD/MM/YYYY') : '';
        this.updated.emit(ev);
    }

    ngOnDestroy(): void {
        this.resetSubscription.unsubscribe();
    }
}
