import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { ImageChoiceItem } from '../../../models/survey-items/question-items/imageChoiceItem';
import * as _ from 'lodash';
import {
    SurveyPositionType,
    SurveyLayoutType
} from '../../../../infrastructure/consts/surveys.consts';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-image-choice-item-preview',
    templateUrl: './image-choice-item-preview.component.html',
    styleUrls: ['./image-choice-item-preview.component.scss']
})
@AutoUnsubscribe()
export class ImageChoicePreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: ImageChoiceItem;
    @Input() isMatrix: boolean;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();
    bottomPositionType = SurveyPositionType.BOTTOM;
    horizontalLayoutType = SurveyLayoutType.HORIZONTAL;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    styleObj: Object;
    verticalStyleObj: Object;
    questionId = TakeSurveyData.QUESTION_ID;
    form: FormGroup;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    imageMaxWidth: Object;
    isMobile: boolean;
    mobileWidth = 768;
    isVertical: boolean;
    private componentDestroyed = new Subject();
    defaultLayout: string;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkLayout();
    }
    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.defaultLayout = this.questionItem.layout;
        this.checkLayout();

        // TODO: randomize only on preview
        if (this.questionItem.randomize) {
            this.questionItem.choices = _.shuffle(this.questionItem.choices);
        }

        this.createFormGroup();
        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
        this.styleObj = {
            'width': this.questionItem.image_width ? `${this.questionItem.image_width}px`
                : this.questionItem.image_height ? 'inherit' : '200px',
            height: this.questionItem.image_height ? `${this.questionItem.image_height}px` : 'inherit'
        };
        this.verticalStyleObj = {
            'max-width': this.questionItem.image_width ? `${this.questionItem.image_width}px`
                : this.questionItem.image_height ? 'inherit' : '200px',
            height: this.questionItem.image_height ?
                this.isMobile ? 'inherit' : `${this.questionItem.image_height}px` : 'inherit'
        };
        this.isVertical = this.questionItem.layout === 'Vertical';
        this.imageMaxWidth = { 'max-width': '100%' };
    }

    ngAfterViewInit(): void {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    private createFormGroup(): void {
        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            choices: new FormArray([])
        });
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        this.initChoices();
    }

    toggleCheckedState(e: Event, controls) {
        e.preventDefault();
        this.choices.controls.forEach(choice => {
            if (choice.get('id').value === controls.id.value) {
                choice.get('is_default').patchValue(!controls.is_default.value);
            }
        });
    }

    get choices(): FormArray {
        return this.form.get('choices') as FormArray;
    }

    get controls() {
        const allControls = [...this.choices.controls];
        return allControls;
    }

    initChoices() {
        const choices = getEnabledChoices(this.questionItem.choices);
        choices.map(item => {
            const control = new FormGroup({
                id: new FormControl(item.id),
                is_default: new FormControl(item.is_default),
                alias: new FormControl(item.alias),
                points: new FormControl(item.points),
                enabled: new FormControl(item.enabled),
                url: new FormControl(item.url ? item.url : item.image.file_url)
            });
            (<FormArray>this.form.get('choices')).push(control);
        });

    }

    resetForm() {
        this.choices.controls.forEach(control => {
            control.get('is_default').reset();
        });
    }

    checkLayout() {
        this.isMobile = window.innerWidth < this.mobileWidth;
        if (this.isMobile && this.questionItem.layout === 'Horizontal') {
            this.questionItem.layout = 'Vertical';
            this.isVertical = true;
        } else if (!this.isMobile && this.questionItem.layout !== this.defaultLayout) {
            this.questionItem.layout = this.defaultLayout;
            this.isVertical = this.defaultLayout === 'Vertical';
        }
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }

}
