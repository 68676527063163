import { CaptchaItem } from '../../../models/survey-items/question-items/captchaItem';
import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SurveyCaptchaType } from '../../../../infrastructure/consts/surveys.consts';
import { TakeSurveyProvider } from '../../../../app-take-survey/providers';
import { CaptchaKeys } from '../../../../app-take-survey/models';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { SharedService } from '../../../../infrastructure/services';
import { isEmpty } from 'lodash';

@Component({
    selector: 'cb-captcha-item-preview',
    templateUrl: './captcha-item-preview.component.html',
    styleUrls: ['./captcha-item-preview.component.scss']
})
export class CaptchaPreviewItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: CaptchaItem;
    @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
    public captchaType = SurveyCaptchaType;
    public captchaInvisibleResponse = '';
    public apiKeysFetched: boolean;
    private resetSubscription: Subscription;
    private executeSubscription: Subscription;
    color: string;
    isResolved: boolean;

    constructor(private takeSurveyProvider: TakeSurveyProvider,
                private sharedService: SharedService,
                private cdr: ChangeDetectorRef
        ) { }

    ngOnInit() {
        this.color = this.questionItem.theme;
        if (environment.isAdminSite) {
            this.getKeysFromService();
        } else {
            this.getKeysFromServer();
        }

        this.resetSubscription = this.takeSurveyProvider.resetCaptchaSubject.subscribe(
            () => {
                this.isResolved = false;
                this.captchaRef.reset();
            }
        );
        this.executeSubscription = this.takeSurveyProvider.executeCaptchaSubject.subscribe(
            () => {
                if (this.isResolved) {
                    this.takeSurveyProvider.invisibleCaptchaResolveSubject.next(
                        this.questionItem.id
                    );
                } else if (
                    this.captchaRef &&
                    this.questionItem.captcha_type ===
                        SurveyCaptchaType.INVISIBLE
                ) {
                    this.captchaRef.execute();
                }
            }
        );
    }

    getKeysFromService() {
        this.sharedService.captchaKeys
        .pipe(
            first()
        )
        .subscribe((keys: CaptchaKeys) => {
            if (!isEmpty(keys)) {
                this.questionItem.visibleRecaptchaSiteKey =
                keys.v2_recaptcha_site_key;
                this.questionItem.inVisibleRecaptchaSiteKey =
                    keys.invisible_recaptcha_site_key;
                this.apiKeysFetched = true;
            } else {
                this.getKeysFromServer();
            }
        });
    }

    getKeysFromServer() {
        this.takeSurveyProvider
        .getCaptchaSiteKeys()
        .subscribe((keys: CaptchaKeys) => {
            if (keys) {
                this.sharedService.captchaKeys.next(keys);
                this.questionItem.visibleRecaptchaSiteKey =
                keys.v2_recaptcha_site_key;
                this.questionItem.inVisibleRecaptchaSiteKey =
                    keys.invisible_recaptcha_site_key;
                this.apiKeysFetched = true;
                this.cdr.detectChanges();
            }
        });
    }

    resolvedVisible(captchaResponse: string) {
        this.questionItem.answer_text = `${captchaResponse}`;
    }

    resolvedInvisible(captchaResponse: string) {
        this.questionItem.answer_text = `${captchaResponse}`;
        const newResponse = captchaResponse
            ? `${captchaResponse.substr(0, 7)}...${captchaResponse.substr(-7)}`
            : captchaResponse;
        this.captchaInvisibleResponse += `${JSON.stringify(newResponse)}\n`;
        this.isResolved = !!captchaResponse;
        this.takeSurveyProvider.invisibleCaptchaResolveSubject.next(
            this.questionItem.id
        );
    }

    ngOnDestroy(): void {
        this.resetSubscription.unsubscribe();
        this.executeSubscription.unsubscribe();
    }
}
