import { of as observableOf, forkJoin, Subject } from 'rxjs';
import { mergeMap, takeUntil, first } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewChecked,
} from '@angular/core';
import {
    SurveyEditorProvider,
    SurveyPagesProvider,
    SurveyTemplatesProvider,
    SurveysProvider,
    StyleTemplatesProvider
} from '../../../../providers';
import { SurveysSettingProvider } from '../../../../../infrastructure/providers/surveys-setting.provider';
import { SurveysPageTypes } from '../../../../../infrastructure/consts/surveys.consts';
import * as _ from 'lodash';
import { Survey, SurveyPage } from '../../../../models';
import { preparePages, preparePage, preparePreviewPages, filterHiddenItemPage } from '../../../../../infrastructure/helpers/page-helpers';
import { AppearanceSSSModel } from '../../../../../infrastructure/models/survey-settings.model';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { ActivatedRoute } from '@angular/router';
import { pickBy } from 'lodash';
import { SharedService } from '../../../../../infrastructure/services';
import { StyleTemplate } from '../../../../../infrastructure/models/style-template.model';

@Component({
    selector: 'cb-survey-page-preview',
    templateUrl: './survey-page-preview.component.html',
    styleUrls: ['./survey-page-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class SurveyPagePreviewComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() survey: Survey;
    @Input() isTemplatePreview: boolean;
    @Input() language: string;
    @Input() styleTemplate: StyleTemplate;
    @ViewChild('wrapper') set wrap(elRef: ElementRef) {
        if (this.previewType === 'desktop' && elRef && elRef.nativeElement && !this.wrapper) {
            this.wrapper = elRef.nativeElement;
            this.sendPostMessage();
        }
    }
    isSurveyTemplate: boolean;
    wrapper;
    pages: SurveyPage[] = [];
    currentPage: SurveyPage;
    isLoading: boolean;
    appearanceSettings: AppearanceSSSModel;
    host: string;
    preparePages = preparePages;
    preparePage = preparePage;
    preparePreviewPages = preparePreviewPages;
    filterHiddenItemPage = filterHiddenItemPage;
    currentPageIndex: number;
    allQuestions = [];
    hiddenItems;
    height;
    previewType = 'desktop';
    surveyId: number;
    private componentDestroyed = new Subject();
    provider;

    constructor(
        private surveyPagesProvider: SurveyPagesProvider,
        private surveyEditorProvider: SurveyEditorProvider,
        private surveyProvider: SurveysProvider,
        private surveyTemplatesProvider: SurveyTemplatesProvider,
        private styleTemplatesProvider: StyleTemplatesProvider,
        private _cdr: ChangeDetectorRef,
        private surveySettingsProvider: SurveysSettingProvider,
        private sharedService: SharedService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.isSurveyTemplate = this.isTemplatePreview || this.route.snapshot.parent.queryParams.isTemplate === 'true';
        this.surveyId = this.survey.id;
        this.subscribeOnPageChange();
        this.initData();
    }

    initData() {
        const initData = this.isSurveyTemplate ?
            this.initTemplatePagesInfo(this.surveyId) :
            this.initSurveyPagesInfo(this.surveyId);
        initData.subscribe(() => {
            this.isLoading = false;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
            this.selectPage(0);
        });
    }

    subscribeOnPageChange() {
        window.parent.onmessage = (e) => {
            if (e.data.hasOwnProperty('styleTemplate')) {
                this.styleTemplate = e.data.styleTemplate;
                this.styleTemplatesProvider.applyStyleTemplate(new StyleTemplate(this.styleTemplate)).pipe(
                    first()
                ).subscribe();
                if (!this._cdr['destroyed']) {
                    this._cdr.detectChanges();
                }
            }
        };
        window.onmessage = (e) => {
            if ( e.data.page + 1) {
                this.selectPage(e.data.page);
                if (!this._cdr['destroyed']) {
                    this._cdr.detectChanges();
                }
            }
            if ( e.data.lang) {
                if ( e.data.lang.value !== this.language) {
                    this.language = e.data.lang.value;
                    this.updateSurveyText(this.language);
                    this.selectPage(this.currentPageIndex);
                    if (!this._cdr['destroyed']) {
                        this._cdr.detectChanges();
                    }
                }
            }
            if ( e.data.view) {
                this.previewType = e.data.view;
                if (this.previewType === 'desktop') {
                    this.sendPostMessage();
                }
            }
        };
    }

    updateSurveyText(lang) {
        this.sharedService.share('currentLanguage', lang);
        this.surveyProvider.getSurveyTextApplicationSettings(lang).pipe(
            first()
        ).subscribe(
            text => {
                const updatedText = { ...this.sharedService.surveyText.value, ...pickBy(text) };
                this.sharedService.surveyText.next(updatedText);
            }
        );
    }

    initSurveyPagesInfo(surveyId: number) {
        return forkJoin([
            this.surveyPagesProvider.loadPages(surveyId),
            this.surveySettingsProvider.getAppearanceSettingsById(surveyId.toString())
        ]).pipe(
            mergeMap(([pagesData, appearanceSettings]) => {
                this.appearanceSettings = appearanceSettings;
                this.pages = this.preparePreviewPages(
                    this.filterHiddenItemPage(pagesData)
                );
                const hiddenItems = pagesData.filter(
                    page =>  page.page_type === SurveysPageTypes.HiddenItems
                );
                return forkJoin([
                    this.surveyEditorProvider.getQuestions(surveyId, hiddenItems[0].id, this.language, this.isSurveyTemplate),
                    this.surveyEditorProvider.getQuestions(surveyId, this.pages[0].id, this.language, this.isSurveyTemplate)
                ]);
            }),
            mergeMap(([hiddenItems , questions]) => {
                this.hiddenItems = hiddenItems;
                this.allQuestions[0] = questions;
                this.pages[0] = this.preparePage(
                    this.pages[0],
                    questions,
                    this.allQuestions,
                    0,
                    this.appearanceSettings.showItemsNumber
                );
                this.pages[0].lang = this.language;
                this.selectPage(0);
                return observableOf(null);
            })
        );
    }

    initTemplatePagesInfo(Id: number) {
        return  this.surveyTemplatesProvider.loadPages(Id.toString())
        .pipe(
            mergeMap((pagesData) => {
                this.pages = this.filterHiddenItemPage(pagesData);
                if (this.pages.length) {
                    return this.surveyEditorProvider.getQuestions(
                        Id,
                        this.pages[0].id,
                        this.language,
                        this.isSurveyTemplate
                    );
                } else {
                    return observableOf(null);
                }
            }),
            mergeMap(questions => {
                if (questions) {
                    this.allQuestions[0] = questions;
                    this.pages[0] = this.preparePage(
                        this.pages[0],
                        questions,
                        this.allQuestions,
                        0
                    );
                    this.pages[0].lang = this.language;
                    this.selectPage(0);
                }
                return observableOf(null);
            })
        );
    }

    selectPage(index) {
        this.currentPageIndex = index;
        if (this.pages[index].items && this.pages[index].lang === this.language) {
            this.currentPage = this.pages[index];
        } else if (this.appearanceSettings && this.appearanceSettings.showItemsNumber) {
            this.getPageWithNumbers(index);
        } else {
            this.getPage(index);
        }
    }

    getPageWithNumbers(index) {
        const emptyPages = [];
        for (let i = 0; i <= index; i++) {
            if (!this.pages[i].items || this.pages[index].lang !== this.language) {
                emptyPages.push({
                        id: this.pages[i].id,
                        i
                    }
                );
            }
        }
        forkJoin(
            emptyPages.map(page =>
                this.surveyEditorProvider.getQuestions(
                    this.surveyId,
                    page.id,
                    this.language
                )
            )
        ).pipe(
            takeUntil(this.componentDestroyed),
            mergeMap(questions => {
                emptyPages.forEach((page, questionsIndex) => {
                    this.allQuestions[page.i] = questions[questionsIndex];
                    this.pages[page.i] = this.preparePage(
                        this.pages[page.i],
                        questions[questionsIndex],
                        this.allQuestions,
                        page.i,
                        true
                    );
                    this.pages[page.i].lang = this.language;
                });
                this.currentPage = this.pages[index];
                if (!this._cdr['destroyed']) {
                    this._cdr.detectChanges();
                }
                return observableOf(null);
            })
        ).subscribe();
    }

    getPage(index) {
        this.surveyEditorProvider.getQuestions(
            this.surveyId,
            this.pages[index].id,
            this.language,
            this.isSurveyTemplate
        ).pipe(
            takeUntil(this.componentDestroyed)
        ).subscribe(
            questions => {
                this.allQuestions[index] = questions;
                this.pages[index] = this.preparePage(
                    this.pages[index],
                    questions,
                    this.allQuestions,
                    index
                );
                this.pages[index].lang = this.language;
                this.currentPage = this.pages[index];
                if (!this._cdr['destroyed']) {
                    this._cdr.detectChanges();
                }
            }
        );
    }

    sendPostMessage() {
        if (this.wrapper) {
            const newHeight = this.wrapper.offsetHeight;
            if (this.height !== newHeight ) {
                this.height = newHeight;
                window.parent.postMessage({
                    frameHeight: this.height
                }, '*');
            }
        }
    }

    ngAfterViewChecked() {
        if (this.previewType === 'desktop') {
            this.sendPostMessage();
        }
    }

    ngOnDestroy() {}
}
