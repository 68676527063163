<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
            [hideSubtext]="isMatrix"
        ></cb-questions-header>
        <div class="cb-question-item-content" [ngClass]="{'full-width': isMatrix}">
            <form
                [formGroup]="form"
                *ngIf="questionItem.value_type === 'NumberRange'"

            >
                <div class="cb-slider-controls" [ngClass]="{
                    'row-reverse': isMobile
                }">
                    <div
                        class="cb-slider-preview-container"
                        [ngClass]="{
                            'matrix-slider-container': isMatrix,
                            'horizontal-slider-container': !isMobile,
                            'vertical-slider-container': isMobile,
                            'padding-for-value': questionItem.show_value && !isMatrix
                        }"
                        [ngStyle]="{ 'width.px': questionItem.width }"
                    >
                        <div class="slider-label-list slide-label-above">
                            <div class="slider-label-item first-item">
                                <span
                                    class="slider-label-item-value"
                                    (click)="onSliderLabelClick(min_value - 1)"
                                    [innerHtml]="min_value | keepHtml"
                                ></span>
                            </div>
                            <div class="slider-label-item last-item">
                                <span
                                    class="slider-label-item-value"
                                    (click)="onSliderLabelClick(max_value - 1)"
                                    [innerHtml]="max_value | keepHtml"
                                ></span>
                            </div>
                        </div>
                        <div
                            class="cb-custom-slider"
                            [ngClass]="{
                                'horizontal-slider-view': !isMobile,
                                'vertical-slider-view': isMobile
                            }"
                        >
                            <div class="slider-custom-ticks-list">
                                <span
                                    class="slider-custom-tick"
                                    *ngFor="
                                        let item of getSliderTicks(max_value);
                                        let j = index
                                    "
                                ></span>
                            </div>
                            <mat-slider
                                [attr.aria-labelledby]="questionId + questionItem.id"
                                thumbLabel="{{ isMatrix }}"
                                formControlName="value"
                                [vertical]="isMobile"
                                [min]="min_value"
                                [max]="max_value"
                                [tickInterval]="step"
                                [step]="step"
                                [value]="value"
                                [invert]="isMobile"
                            >
                            </mat-slider>
                        </div>
                    </div>
                    <mat-form-field
                        floatLabel="never"
                        *ngIf="questionItem.show_value && !isMatrix"
                    >
                        <label>
                            <span hidden>{{ sliderValue }}</span>
                            <input
                                type="text"
                                matInput
                                [value]="sliderValue"
                                [disabled]="true"
                            />
                        </label>
                    </mat-form-field>
                </div>
                <cb-server-validation-errors
                    [item]="questionItem"
                ></cb-server-validation-errors>
            </form>
            <form
                [formGroup]="form"
                *ngIf="
                    questionItem.value_type === 'Image' ||
                    questionItem.value_type === 'Text'
                "
                [attr.aria-labelledby]="questionId + questionItem.id"
                [ngClass]="{'cb-image-slider': questionItem.value_type === 'Image'}"
            >
                <div
                    #imageSlider
                    class="cb-slider-preview-container"
                    [ngStyle]="!isMobile ? {'width.px': questionItem.width} : ''"
                    [ngClass]="{
                        'matrix-slider-container': isMatrix,
                        'horizontal-slider-container': !isMobile,
                        'vertical-slider-container': isMobile
                    }"
                >
                    <div
                        class="slider-label-list"
                        [ngClass]="{
                            'slider-label-under':
                                questionItem.image_position === 'Bottom',
                            'slider-label-above':
                                questionItem.image_position === 'Top'
                        }"
                    >
                        <div
                            class="slider-label-item"
                            *ngFor="
                                let choice of enabled_choices;
                                let i = index
                            "
                            [ngClass]="{
                                active: i + 1 === sliderValue,
                                'first-item': i === 0,
                                'last-item': i === enabled_choices.length - 1
                            }"
                            (click)="
                                onSliderLabelClick(i);
                                getSliderActiveLabel(choice)
                            "
                        >
                            <span
                                class="slider-label-item-value"
                                *ngIf="questionItem.value_type === 'Text'"
                                [innerHtml]="choice.text | keepHtml"
                            >
                            </span>
                            <div
                                class="slider-label-item-value image"
                                *ngIf="questionItem.value_type === 'Image'"
                                [ngClass]="{
                                    'image-label-mobile': isMobile && questionItem.alias_position !== 'DontShow',
                                    'image-label-under': !isMobile && questionItem.alias_position === 'Bottom',
                                    'image-label-above': !isMobile && questionItem.alias_position === 'Top'
                                }"
                            >
                                <img
                                    [alt]="choice.alias"
                                    [src]="getImageUrl(choice)"
                                />
                                <span
                                    class="image-alias"
                                    *ngIf="
                                        questionItem.alias_position !==
                                        'DontShow'
                                    "
                                    >{{ choice.alias }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="cb-custom-slider"
                        [ngClass]="{
                            'horizontal-slider-view': !isMobile,
                            'vertical-slider-view': isMobile
                        }"
                        [ngStyle]="getSliderSizeStyle(enabled_choices.length)"
                    >
                        <div class="slider-custom-ticks-list">
                            <span
                                class="slider-custom-tick"
                                *ngFor="
                                    let item of getSliderTicks(max_value);
                                    let j = index
                                "
                            ></span>
                        </div>
                        <mat-slider
                            [attr.aria-labelledby]="questionId + questionItem.id"
                            formControlName="value"
                            [min]="1"
                            [max]="enabled_choices.length"
                            [vertical]="isMobile"
                            [tickInterval]="step"
                            [step]="step"
                            [value]="value"
                            [invert]="isMobile"
                        ></mat-slider>
                    </div>
                </div>
                <cb-server-validation-errors
                    [item]="questionItem"
                ></cb-server-validation-errors>
            </form>
        </div>
    </div>
</div>
