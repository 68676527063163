import { ItemBase } from '../itemBase';

export class WebhookItem extends ItemBase {
    url: string;
    send_once: boolean;
    include_alias: boolean;
    is_flat: boolean;
    request_headers: WebhookRequestHeader[];
}

export class WebhookRequestHeader {
    name: string;
    value: string;
}
