<cb-questions-header
    *ngIf="questionItem.item_number"
    [questionItem]="questionItem"
></cb-questions-header>

<div *ngIf="apiKeysFetched" class="cb-question-item-content">
    <div *ngIf="questionItem.captcha_type === captchaType.VISIBLE && questionItem.visibleRecaptchaSiteKey">
        <re-captcha
            (resolved)="resolvedVisible($event)"
            #captchaRef="reCaptcha"
            [theme]="questionItem.theme.toLowerCase()"
            [size]="questionItem.size.toLowerCase()"
            [siteKey]="questionItem.visibleRecaptchaSiteKey"
        ></re-captcha>
    </div>
    <div *ngIf="questionItem.captcha_type === captchaType.INVISIBLE && questionItem.inVisibleRecaptchaSiteKey">
        <re-captcha
            (resolved)="resolvedInvisible($event)"
            size="invisible"
            #captchaRef="reCaptcha"
            [siteKey]="questionItem.inVisibleRecaptchaSiteKey"
        ></re-captcha>
        <!--<button mat-button (click)="captchaRef.execute()">Submit</button>-->
        <!--<button mat-button (click)="captchaRef.reset()">Reset</button>-->
        <!--<pre>reCAPTCHA response log: {{ captchaInvisibleResponse || '(empty)' }}</pre>-->
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
