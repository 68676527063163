<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="layoutClass">
        <cb-questions-header
            [questionItem]="questionItem"
            [hideSubtext]="isMatrix"
        ></cb-questions-header>
        <div
            class="cb-checkboxes-container cb-question-item-content"
            [ngClass]="!isMatrix ? 'cb-question-item-content--max-width' : ''"
        >
            <form [formGroup]="form">
                <div
                    class="checkboxes-group with-columns"
                    [ngClass]="isMatrix ? 'd-block' : ''"
                    [ngStyle]="styleObj"
                    role="group" [attr.aria-labelledby]="questionId + questionItem.id"
                >
                    <ng-container *ngIf="!isMatrix">
                        <ng-container formArrayName="choices">
                            <div
                                *ngFor="
                                    let c of choices.controls;
                                    index as i
                                "
                                [formGroupName]="i"
                                class="choice-item-container"
                                [ngStyle]="cellStyles[i]"
                            >
                                <mat-checkbox
                                    [checked]="c.controls.is_default.value"
                                    [aria-label]="c.controls.text.value | extractInnerText"
                                    (change)="optionsChanged()"
                                    [formControlName]="'is_default'"
                                >
                                    <span *ngIf="questionItem.show_number_labels">{{ i + 1 }}.&nbsp;</span>
                                    <span [innerHtml]="c.controls.text.value | keepHtml"></span>
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <div
                            *ngIf="questionItem.allow_other"
                            formGroupName="otherOption"
                            class="cb-other-answer choice-item-container"
                            [ngStyle]="
                                questionItem.allow_none_of_above
                                    ? cellStyles[controls.length - 2]
                                    : cellStyles[controls.length - 1]
                            "
                        >
                            <mat-checkbox
                                [checked]="
                                    otherOption.controls.is_default.value
                                "
                                (change)="optionsChanged()"
                                [aria-label]="otherOption.controls.text.value | extractInnerText"
                                [formControlName]="'is_default'"
                            >
                                <span *ngIf="questionItem.show_number_labels">{{ choices.controls.length + 1 }}.&nbsp;</span>
                                <span
                                    [innerHtml]="
                                        otherOption.controls.text.value | keepHtml
                                    "
                                ></span>
                            </mat-checkbox>
                            <span *ngIf="otherOption.controls.is_default.value">
                                <mat-form-field>
                                    <input
                                        matInput
                                        [placeholder]="surveyText.YourOptionPrompt"
                                        formControlName="other_value"
                                    />
                                </mat-form-field>
                            </span>
                        </div>

                        <div
                            *ngIf="questionItem.allow_none_of_above"
                            formGroupName="noneOption"
                            class="cb-other-answer choice-item-container"
                            [ngStyle]="cellStyles[controls.length - 1]"
                        >
                            <mat-checkbox
                                [checked]="noneOption.controls.is_default.value"
                                (change)="noneChange()"
                                [aria-label]="noneOption.controls.text.value | extractInnerText"
                                class="choice-item-container"
                                [formControlName]="'is_default'"
                            >
                                <span *ngIf="questionItem.show_number_labels">{{ choices.controls.length + (questionItem.allow_other ? 2 : 1) }}.&nbsp;</span>
                                <span
                                    [innerHtml]="noneOption.controls.text.value | keepHtml"
                                ></span>
                            </mat-checkbox>
                        </div>
                    </ng-container>


                    <div
                        class="table-matrix-group"
                        *ngIf="isMatrix"
                        [ngClass]="{
                        'with-NA-option': questionItem.allow_none_of_above,
                        'display-horizontally' : !isMobile && !isTablet,
                        'display-vertically' : isMobile || isTablet
                        }"
                    >
                                <ng-container formArrayName="choices">
                                    <div *ngFor=" let c of choices.controls; index as i"
                                        [formGroupName]="i" class="checkbox-container"
                                    >
                                        <mat-checkbox
                                            [checked]="c.controls.is_default.value"
                                            (change)="optionsChanged()"
                                            [aria-label]="c.controls.text.value | extractInnerText"
                                            [formControlName]="'is_default'"
                                        >
                                        <span class="checkbox-label"
                                            [innerHtml]="c.controls.text.value | keepHtml"
                                        ></span>
                                        </mat-checkbox>
                                    </div>

                                    <div  class="checkbox-container"
                                        *ngIf="questionItem.allow_other"
                                        formGroupName="otherOption"
                                    >
                                        <mat-checkbox
                                            [checked]="
                                        otherOption.controls.is_default.value
                                    "
                                            (change)="optionsChanged()"
                                            [aria-label]="otherOption.controls.text.value | extractInnerText"
                                            [formControlName]="'is_default'"
                                        >
                                    <span class="checkbox-label"
                                        [innerHtml]="
                                            otherOption.controls.text.value | keepHtml
                                        "
                                    ></span>
                                            <span
                                                *ngIf="
                                            otherOption.controls.is_default
                                                .value
                                        "
                                            >
                                        <mat-form-field>
                                            <input
                                                matInput
                                                [placeholder]="surveyText.YourOptionPrompt"
                                                formControlName="other_value"
                                            />
                                        </mat-form-field>
                                    </span>
                                        </mat-checkbox>
                                    </div>
                                </ng-container>



                                <div  class="checkbox-container"
                                    *ngIf="questionItem.allow_none_of_above"
                                    formGroupName="noneOption"
                                >
                                    <mat-checkbox
                                        [checked]="
                                        noneOption.controls.is_default.value
                                    "
                                        (change)="noneChange()"
                                        [aria-label]="noneOption.controls.text.value | extractInnerText"
                                        [formControlName]="'is_default'"
                                    >
                                    <span class="checkbox-label"
                                        [innerHtml]="
                                            noneOption.controls.text.value | keepHtml
                                        "
                                    ></span>
                                    </mat-checkbox>
                                </div>
                    </div>

                <cb-server-validation-errors
                    [item]="questionItem"
                ></cb-server-validation-errors>
                </div>
            </form>
        </div>
    </div>
</div>

