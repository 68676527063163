import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserRoles } from '../../../../infrastructure/consts/auth.consts';
import { StorageProvider } from '../../../../infrastructure/providers';
import { ToasterService } from '../../../../infrastructure/services';
import { SurveysSharedData } from '../../../../infrastructure/consts/surveys.consts';
import { getFilters } from '../../../../infrastructure/helpers/surveys.helper';
import * as _ from 'lodash';
import { SurveyListOptions } from '../../../models/surveys/survey-list-options.model';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SurveysStore } from '../../../store';
import { SurveyListGetData } from '../../../models';
import { FoldersProvider, SurveysProvider } from '../../../providers';
import { SurveyListDialogsService } from '../../../surveys/survey-edit/dialog-windows/survey-list-dialogs.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'cb-header-level2-surveylist',
  templateUrl: './header-level2-surveylist.component.html',
  styleUrls: ['./header-level2-surveylist.component.scss']
})
export class HeaderLevel2SurveylistComponent implements OnInit, OnDestroy {

  constructor(
    private storageProvider: StorageProvider,
    private surveysStore: SurveysStore,
    private toasterService: ToasterService,
    private surveysProvider: SurveysProvider,
    private foldersProvider: FoldersProvider,
    private surveyListDialogsService: SurveyListDialogsService,
    private translateService: TranslateService
  ) { }
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
  isEditAllowed: boolean = false;
  surveyOptions: SurveyListOptions;
  active = true;
  draft = true;
  archived = true;
  startDate: Date;
  endDate: Date;
  displaySearchField = false;
  bulkActions = false;
  surveysSelected: number[] = [];
  componentDestroyed$ = new Subject();
  surveySelection$ = this.surveysStore.selectedSurveyIds.pipe(takeUntil(this.componentDestroyed$)).subscribe(selection => {
    this.bulkActions = selection.length > 0;
    this.surveysSelected = selection;
  });
  ngOnInit() {
    this.initEditPermissions();
    this.init();
  }
  init() {
    this.surveyOptions = {
      searchText: '',
      statusFilters: '',
      pageNumber: 1,
      dateFrom: '',
      dateTo: ''
    };
  }

  initEditPermissions() {
    return this.storageProvider.getIdentity()
      .subscribe(_identity => {
        const identity = JSON.parse(_identity);
        this.isEditAllowed = !!_.chain(identity)
          .get('roles')
          .split(',')
          .map(role => _.trim(role))
          .intersection([UserRoles.SURVEY_ADMINISTRATOR, UserRoles.SYSTEM_ADMINISTRATOR])
          .value()
          .length;
      });
  }
  public onFilterClick() {
    this.applyFilters();
  }
  public onResetClick() {
    this.init();
    this.applyFilters();
  }
  private applyFilters() {
    this.updateSurveysList(1);
  }
  createSurvey(): void {
    this.surveyListDialogsService.createSurveyDialog();
  }

  createSurveyFromFile(): void {
    this.surveyListDialogsService.createSurveyFromFileDialog();
  }

  createFolder(): void {
    this.surveyListDialogsService.createFolderDialog();
  }
  filterToGetData(filters: any): SurveyListGetData {
    return {
      folder_id: null,
      search_text: filters?.searchText ?? '',
      paging_info: {
        page_num: filters.pageNum ?? 0,
        page_size: filters.pageSize ?? 0
      },
      sort_criteria: {
        sort_by: filters.sortBy ?? '',
        sort_order: filters.sortOrder ?? ''
      },
      status_filter: filters.statusFilter ?? '',
      date_from: filters.dateFrom ?? '',
      date_to: filters.dateTo ?? ''
    }
  }
  displaySearchFieldOnClick() {
    this.displaySearchField = true;
  }
  onSearchChanged(searchText: string) {
    this.surveyOptions.searchText = searchText;
    this.updateSurveysList(1);
  }
  onSearchLeave() {
    this.displaySearchField = false;
  }
  private updateSurveysList(pageNumber) {
    this.surveyOptions.pageNumber = pageNumber;
    this.surveysStore.loadSurveys(this.filterToGetData(
      getFilters(
        this.surveyOptions.searchText,
        this.surveyOptions.pageNumber,
        SurveysSharedData.SURVEYS_PAGE_SIZE,
        this.surveyOptions.statusFilters,
        '',
        '',
        this.surveyOptions.dateFrom,
        this.surveyOptions.dateTo
      )
    ));
  }
  
  onDeleteSelectedSurveys() {
    this.surveyListDialogsService.deleteSelectedSurveysDialog(this.surveysSelected).subscribe();
  }

  onMoveSelectedSurveysToFolder() {
    this.surveyListDialogsService.moveSelectedSurveysToFolderDialog(this.surveysSelected);
  }

  onArchiveSelectedSurveys() {
    this.surveyListDialogsService.archiveSelectedSurveysDialog(this.surveysSelected);
  }

}
