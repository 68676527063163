import { ItemBase } from '../itemBase';

export class HyperlinkItem extends ItemBase {
    url: string;
    text: string;
    auto_redirect_delay: number;
    auto_redirect: boolean;
    restart_survey: boolean;
    open_in_new_window: boolean;
    item_position: string;
    font_color: string;
    font_size: string;
    itemPosition: string;
}
