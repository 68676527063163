import { Component, Input, OnInit } from '@angular/core';
import { ResponseSummaryItem } from '../../../models/survey-items/report-items/responseSummaryItem';
import { SurveyPage } from '../../../../app-admin/models';

@Component({
    selector: 'cb-response-summary-item-preview',
    templateUrl: './response-summary-item-preview.component.html',
    styleUrls: ['./response-summary-item-preview.component.scss']
})
export class ResponseSummaryPreviewItemComponent implements OnInit {
    @Input() reportItem: ResponseSummaryItem;
    @Input() isTakeSurvey: boolean;
    @Input() pages: SurveyPage[];
    printUrl = '/print-response';
    responseUrl = '/response';
    printParams: any;

    response: any;
    surveyId: string;

    constructor() {}

    ngOnInit(): void {
        if (this.isTakeSurvey) {
            this.extractDataFromItem(this.reportItem);
        }
    }

    extractDataFromItem(reportItem) {
        if (reportItem.surveyData) {
            this.response = reportItem.surveyData.response;
            this.surveyId = reportItem.surveyData.survey.id;
            this.printParams = {
                responseId: this.response.id,
                surveyId: this.surveyId,
                include_message_items: reportItem.include_message_items,
                show_question_numbers: reportItem.show_question_numbers,
                show_page_numbers: reportItem.show_page_numbers,
                show_hidden_items: reportItem.show_hidden_items,
                show_points: reportItem.show_points
            };
        }
    }
}
