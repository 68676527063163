import { of as observableOf, forkJoin, of } from 'rxjs';

import { first, mergeMap, map } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Survey } from '../../../../models';
import { ActivatedRoute } from '@angular/router';
import { StyleTemplatesProvider, SurveysProvider, SurveyTemplatesProvider } from '../../../../providers';
import { StyleTemplate } from '../../../../../infrastructure/models/style-template.model';
import * as _ from 'lodash';
import { appendCssTobody } from '../../../../../infrastructure/helpers/javascript.helper';
import { SurveysSettingProvider } from '../../../../../infrastructure/providers/surveys-setting.provider';

@Component({
    selector: 'cb-survey-preview-theme-preloader',
    templateUrl: './theme-preloader.component.html',
    styleUrls: ['./theme-preloader.component.scss']
})
export class SurveyPreviewThemePreloaderComponent implements OnInit, OnDestroy {
    surveyId: number;
    styleTemplate: StyleTemplate;
    isSurveyTemplate: boolean;
    isLoading: boolean;
    survey: Survey;
    languageSettings;

    constructor(
        private route: ActivatedRoute,
        private surveysProvider: SurveysProvider,
        private surveysSettingProvider: SurveysSettingProvider,
        private styleTemplatesProvider: StyleTemplatesProvider
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.isSurveyTemplate = this.route.snapshot.queryParams.isTemplate  === 'true';
        this.surveyId = this.route.snapshot.params.id;
        this.initSurveyAndStyleInfo(this.surveyId).pipe(
            first()
        ).subscribe(
            res => this.isLoading = false
        );
    }

    initSurveyAndStyleInfo(surveyId: number) {
        return forkJoin([
            this.surveysProvider.getSurvey(surveyId,  this.isSurveyTemplate),
            this.initLanguageSettings(),
        ])
         .pipe(
            mergeMap((res) => {
                this.languageSettings = res[1];
                this.survey = res[0];
                return this.initStyleInfo(this.survey);
            })
        );
    }

    initLanguageSettings() {
        return this.surveysSettingProvider.getLanguageSettingsById(
            this.surveyId.toString(), this.isSurveyTemplate
        );
    }

    initStyleInfo(survey: Survey) {
        const styleTemplateId = survey.style_template_id;

        const css = this.styleTemplatesProvider.getStyleTemplateById(styleTemplateId);
        const wideCss = this.surveysSettingProvider.getSurveyWideCSS(this.surveyId.toString(), this.isSurveyTemplate);
        if (styleTemplateId) {
            return forkJoin(
                css,
                wideCss
            ).pipe(
                mergeMap(styles => {
                    this.styleTemplate = styles[0];
                    if (styles[1]) {
                        appendCssTobody(styles[1]);
                    }
                    return this.styleTemplatesProvider.applyStyleTemplate(
                        this.styleTemplate
                    );
                })
            );
        } else {
            return wideCss.pipe(
                map(styles => {
                        if (styles) {
                            appendCssTobody(styles);
                        }
                    }
                )
            );
        }
    }

    ngOnDestroy() {
        this.styleTemplatesProvider.clearCss();
    }
}
