<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <form [formGroup]="form" class="form-single-line-text-preview" *ngIf="isSubQuestion">
        <cb-questions-header
            [questionItem]="questionItem"
            [hideSubtext]="true"
        ></cb-questions-header>
        <mat-form-field
            *ngIf="
            questionItem.answer_format !== 'Date_USA' &&
            questionItem.answer_format !== 'Date_ROTW'
        "
        >
            <input
                matInput
                [matAutocomplete]="auto"
                formControlName="answer_text"
                [attr.maxlength]="questionItem.max_length"
                [placeholder]="surveyText.AnswerTextPrompt"
                (keypress)="onNumericKeyUp($event)"
            />
            <mat-error *ngIf="questionItem.is_required">
                Please enter valid answer
            </mat-error>
        </mat-form-field>
        <cb-datepicker-en-us
            *ngIf="questionItem.answer_format === 'Date_USA'"
            (updated)="updateDate($event)"
            [questionItem]="questionItem"
            [resetForm]="resetFormEvent"
        ></cb-datepicker-en-us>
        <cb-datepicker-en-gb
            *ngIf="questionItem.answer_format === 'Date_ROTW'"
            (updated)="updateDate($event)"
            [questionItem]="questionItem"
            [resetForm]="resetFormEvent"
        ></cb-datepicker-en-gb>
        <span class="invalid">
        {{ validationError }}
    </span>
</form>

    <div *ngIf="!isSubQuestion">
        <form
            [formGroup]="form"
            class="datepicker-form cb-question-item-wrapper-inner"
            [ngClass]="{
            'cb-question-item-position--left':
                questionItem.item_position === 'Left',
            'cb-question-item-position--right':
                questionItem.item_position === 'Right',
            'cb-question-item-position--center':
                questionItem.item_position === 'Center',
            'cb-question-item-caption-position--top':
                questionItem.question_text_position === 'Top',
            'cb-question-item-caption-position--right':
                questionItem.question_text_position === 'Right',
            'cb-question-item-caption-position--left':
                questionItem.question_text_position === 'Left',
            'cb-question-item-caption-position--bottom':
                questionItem.question_text_position === 'Bottom'
        }"
        >
            <cb-questions-header
                [questionItem]="questionItem"
            ></cb-questions-header>
            <div class="cb-question-item-caption">
                <mat-form-field
                    *ngIf="
                    questionItem.answer_format !== 'Date_USA' &&
                    questionItem.answer_format !== 'Date_ROTW'
                "
                    [ngStyle]="{ 'width.px': questionItem.width }"
                >
                    <input
                        matInput
                        [matAutocomplete]="auto"
                        [attr.aria-labelledby]="questionId + questionItem.id"
                        formControlName="answer_text"
                        [attr.maxlength]="questionItem.max_length"
                        [placeholder]="surveyText.AnswerTextPrompt"
                    />
                </mat-form-field>
                <cb-datepicker-en-us
                    *ngIf="questionItem.answer_format === 'Date_USA'"
                    (updated)="updateDate($event)"
                    [questionItem]="questionItem"
                    [resetForm]="resetFormEvent"
                ></cb-datepicker-en-us>
                <cb-datepicker-en-gb
                    *ngIf="questionItem.answer_format === 'Date_ROTW'"
                    (updated)="updateDate($event)"
                    [questionItem]="questionItem"
                    [resetForm]="resetFormEvent"
                ></cb-datepicker-en-gb>
                <cb-server-validation-errors
                    [item]="questionItem"
                ></cb-server-validation-errors>
            </div>

        </form>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
        <mat-option
            *ngFor="let option of filteredOptions"
            [value]="option"
        >{{ option }}</mat-option
        >
    </mat-autocomplete>
</div>
