<cb-spinner [show]="isLoading"></cb-spinner>
<ng-container *ngIf="!isLoading">
    <div class="cb-take-survey-section cb-style-template-survey" #wrapper>
        <div
            class="cb-survey-header"
            [innerHTML]="
                styleTemplate && styleTemplate.header
                    ? (styleTemplate.header | keepHtml)
                    : ''
            "
        ></div>
        <div class="cb-take-survey-inner">
            <h2
                class="cb-take-survey-title"
                *ngIf="!isLoading && appearanceSettings?.showTitle"
            >
                {{ survey.name }}
            </h2>
            <cb-survey-page
                [pages]="pages"
                [page]="currentPage"
                [validationMessages]="[]"
                [isTakeSurvey]="false"
                *ngIf="currentPage"
            ></cb-survey-page>
        </div>
        <div
            class="cb-take-survey-footer-text"
            [innerHTML]="
                styleTemplate && styleTemplate.footer
                    ? (styleTemplate.footer | keepHtml)
                    : ''
            "
        ></div>
    </div>
</ng-container>
