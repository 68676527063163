import * as user from '../actions/users.actions';
import { Contact, Contacts } from '../../models';
import { State, initialState } from './users.state';
import * as _ from 'lodash';

export function usersReducer(
    state: State = initialState,
    action: user.Actions
): State {
    switch (action.type) {
        case user.GET_USER:
            return _.extend({}, state, { selectedUser: null });
        case user.REMOVE_USER:
            return _.extend({}, state, {
                isLoading: true,
                selectedUser: null,
                addedUser: null
            });
        case user.GET_USERS: {
            return {
                isLoading: true,
                selectedUser: null,
                users: <Contacts>{ items: <Contact[]>[] },
                addedUser: null
            };
        }
        case user.GET_USERS_PAGE:
            return _.extend({}, state, {
                isLoading: true,
                selectedUser: null,
                addedUser: null
            });
        case user.ADD_USER: {
            return {
                isLoading: true,
                selectedUser: state.selectedUser,
                users: state.users,
                addedUser: null
            };
        }
        case user.GET_USER_SUCCESS:
            return _.extend({}, state, { selectedUser: action.payload });
        case user.REMOVE_USER_SUCCESS:
            return _.extend({}, state);
        case user.GET_USERS_SUCCESS: {
            return {
                isLoading: false,
                selectedUser: null,
                users: action.payload,
                addedUser: null
            };
        }
        case user.ADD_USER_SUCCESS: {
            return {
                isLoading: false,
                selectedUser: state.selectedUser,
                users: state.users,
                addedUser: action.payload
            };
        }
        case user.USER_FAILED: {
            return {
                isLoading: false,
                selectedUser: state.selectedUser,
                users: state.users,
                addedUser: null
            };
        }
        default:
            return state;
    }
}
