import { QuestionItem } from './questionItem';

export class CaptchaItem extends QuestionItem {
    captcha_type: string;
    theme: string;
    size: string;
    is_required: boolean;
    default_text: string;
    visibleRecaptchaSiteKey: string;
    inVisibleRecaptchaSiteKey: string;
    answer_text?: string;
}
