import { Injectable } from '@angular/core';
import { DialogsService } from '../../infrastructure/services/dialogs.service';

@Injectable()
export class BrowserDetectionService {
    private value: any;
    private trident;
    private msie;

    constructor(private dialogService: DialogsService) {
        this.value = {
            IsIE: false,
            TrueVersion: 0,
            ActingVersion: 0,
            CompatibilityMode: false
        };

        this.setValue();
    }

    private setValue() {
        this.trident = navigator.userAgent.match(/Trident\/(\d+)/);
        if (this.trident) {
            this.value.IsIE = true;
            this.value.TrueVersion = parseInt(this.trident[1], 10) + 4;
        }

        this.msie = navigator.userAgent.match(/MSIE (\d+)/);
        if (this.msie) {
            this.value.IsIE = true;
            this.value.ActingVersion = +this.msie[1];
        } else {
            this.value.ActingVersion = this.value.TrueVersion;
        }
        if (
            this.value.IsIE &&
            this.value.TrueVersion > 0 &&
            this.value.ActingVersion > 0
        ) {
            this.value.CompatibilityMode =
                this.value.TrueVersion !== this.value.ActingVersion;
        }
    }

    addIe10Class() {
        document.getElementsByTagName('html')[0].className += ' currently-using-ie-10';
    }

    public handleBrowserCheck() {
        if (+localStorage.getItem('browserAlert') < new Date().getTime()) {
            if (this.value.IsIE) {
                if (this.value.CompatibilityMode) {
                    this.addIe10Class();
                    this.dialogService.simpleConfirm(
                        'NOTIFICATIONS.BROWSER-COMPATIBILITY'
                    );
                } else if (this.value.TrueVersion < 11) {
                    const versionText = `Internet Explorer version ${this.value.TrueVersion}`;
                    this.addIe10Class();
                    this.dialogService.simpleConfirm(
                        'NOTIFICATIONS.BROWSER-VERSION',
                        versionText
                    );
                }
            }
            const todaysDate = new Date().getTime();
            localStorage.setItem(
                'browserAlert',
                new Date(todaysDate + 7 * 24 * 60 * 60 * 1000)
                    .getTime()
                    .toString()
            );
        }
    }
}
