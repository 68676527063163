import { Injectable } from '@angular/core';
import markerSDK from '@marker.io/browser';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    isEnabled = false;
    widget: any;
    constructor() {
        this.isEnabled = !!environment['feedbackKey'];
    }

    initFeedback(){
        if (!this.isEnabled) {
            return;
        }
        if (window.frameElement) {
            return;
        }
        markerSDK.loadWidget({
            project: environment['feedbackKey'],
        }).then((widget) => {
            this.widget = widget;
        });
    }
}
