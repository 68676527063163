import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import {
    AccountNameResolverProvider,
    StorageProvider
} from '../../../../infrastructure/providers';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../../../app-admin/consts/resources.consts';
import { environment } from '../../../../environments/environment';
import { EnvironmentProvider } from '../../../../infrastructure/providers/environment.provider';
import { first } from 'rxjs/operators';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-image-item-preview',
    templateUrl: './image-item-preview.component.html',
    styleUrls: ['./image-item-preview.component.scss']
})
export class ImagePreviewItemComponent implements AfterViewInit, OnInit {
    @Input() displayItem: any;
    private accountRegex = /\{account\}/gim;
    private isHandledForPrint = false;
    imageUrl: string;

    constructor(
        private storageProvider: StorageProvider,
        private environmentProvider: EnvironmentProvider,
        private accountNameResolverProvider: AccountNameResolverProvider,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.imagePreview();
    }

    ngAfterViewInit(): void {
        if (this.imageUrl) {
            this.handleForPrint();
        }
    }

    private handleForPrint() {
        if (!this.isHandledForPrint) {
            this.isHandledForPrint = true;
            this.printService.waitForImageLoadingAndMarkReady(this.displayItem.id, this.elem);
        }  
    }

    private imagePreview(): void {
        if (
            this.displayItem &&
            this.displayItem.image &&
            this.displayItem.image.file_url
        ) {
            this.imageUrl = this.displayItem.image.file_url;
        } else {
            const observable = environment.isAdminSite
                ? this.storageProvider.getAccount()
                : this.accountNameResolverProvider.getTakeSurveyAccount();
            observable.pipe(first()).subscribe(account => {
                const imageId = this.displayItem.image
                    ? this.displayItem.image.id
                    : this.displayItem.image_id;

                const url = this.getImageUrl(imageId, account);
                this.imageUrl = url;
                this.handleForPrint();
            });
        }
    }

    private getImageUrl(id, account): string {
        const url = String.Format(ResourcesConsts.SURVEY_EDITOR_GET_IMAGE, id);
        if (environment.name === 'server') {
            return (
                this.rootApiUrl +
                url.replace(this.accountRegex, '').replace('//', '/')
            );
        } else {
            return this.rootApiUrl + url.replace(this.accountRegex, account);
        }
    }

    private get rootApiUrl(): string {
        return this.environmentProvider.apiUrl;
    }
}
