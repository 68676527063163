import { QuestionItem } from './questionItem';

export class ContactFormItem extends QuestionItem {
    fields: ContactFormField[];
    question_text_position: string;
    item_position: string;
    subitems?: ContactFormResponseSubitem[];
}

export class ContactFormField {
    position: number;
    system_name: string;
    question: any;
    enabled: boolean;
}

export enum ContactFormFieldSystemName {
    FirstName = 'FirstName',
    LastName = 'LastName',
    Organization = 'Organization',
    Address1 = 'Address1',
    Address2 = 'Address2',
    City = 'City',
    State = 'State',
    PostalCode = 'PostalCode',
    Country = 'Country',
    Email = 'Email',
    Phone = 'Phone'
}

export class ContactFormResponseSubitem {
    item_id: number;
    answer: any;
    excluded: boolean;
}

