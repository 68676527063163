import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    AbstractControl,
    Validators
} from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { AutocompleteListProvider } from '../../../../providers';
import { SingleLineItem, SINGLE_LINE_TYPES } from '../../../../../shared/models/survey-items/question-items/singleLineItem';
import * as _ from 'lodash';
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { convertDateToNonTimezoneString } from '../../../../../infrastructure/helpers/date.helper';
import { DatepickerMode } from '../../../../../shared/components/date-time-picker/date-time-picker.component';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'cb-singlelines-item',
    templateUrl: './singlelines-item.component.html',
    styleUrls: ['./singlelines-item.component.scss']
})
@AutoUnsubscribe()
export class SinglelinesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: SingleLineItem;
    @Input() isMatrix: boolean;

    singleLineText = SurveyQuestionType.SINGLE_LINE_TEXT;
    form: FormGroup;
    autocompleteLists: any[];
    isAutoCompleteListDefault: boolean;
    list: string;
    listName: string;
    editMode: boolean;
    positionOptions: any[];
    labelPositionOptions: any[];
    editorText = '';
    types = Object.entries(SINGLE_LINE_TYPES);
    itemPosition: string;
    textPosition: string;

    DatepickerMode = DatepickerMode;

    minDateValue: Date;
    maxDateValue: Date;
    setDefaultTextToPlaceholder: boolean;
    defaultTextPlaceholder: string;
    answerType: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private autocompleteListProvider: AutocompleteListProvider,
        private adapter: NgxMatMomentAdapter
    ) {}

    ngOnInit() {
        this.answerType = this.questionItem.answer_format || this.types[0][0];
        this.setInitialDatePickerFormat();
        if (!this.isMatrix) {
            this.autocompleteListProvider.getLists().subscribe(c => {
                this.autocompleteLists = c;
            });

            if (this.questionItem.autocomplete_list_id) {
                this.getAutoCompleteList();
            } else {
                this.listName = 'no';
            }
        }

        this.createFormGroup();
        this.createQuestionItemObject();
        this.shareQuestionItem(
            this.form.controls,
            this.isNumericMinMax(this.form.controls['answer_format'].value)
        );
    }

    setInitialDatePickerFormat() {
        if (
            this.questionItem.answer_format &&
            this.questionItem.answer_format === 'Date_ROTW'
        ) {
            this.adapter.setLocale('en-GB');
        } else if (  this.questionItem.answer_format &&
                    this.questionItem.answer_format === 'Date_USA' ) {
            this.adapter.setLocale('en-US');
        }
    }

    getAutoCompleteList() {
        this.autocompleteListProvider
            .getListById(this.questionItem.autocomplete_list_id)
            .subscribe(list => {
                this.list = '';
                this.fillAutoCompleteBox(list);
                this.editMode = true;
                this.listName = `${list.id}`;
                this.setUpAutoCompleteOptions(list);
            });
    }

    onAutocompleteChange(value) {
        this.resetAutoCompleteOptions(value);
        const item = this.autocompleteLists.find(c => c.id === +value);

        if (!item) {
            return;
        }

        this.autocompleteListProvider.getListById(item.id).subscribe(list => {
            this.setUpAutoCompleteOptions(list);
            this.fillAutoCompleteBox(list);
        });
    }

    resetAutoCompleteOptions(value) {
        const listNameFiledValue = value === 'no' ? '' : null;
        this.list = '';
        this.isAutoCompleteListDefault = false;
        this.editMode = false;
        this.questionItem.autocomplete_list_id = null;
        this.questionItem.autocomplete_list_is_default = false;
        this.form.controls['autocomplete_list_name'].setValue(
            listNameFiledValue
        );
    }

    setUpAutoCompleteOptions(list) {
        this.isAutoCompleteListDefault = list.is_default ? true : false;
        this.questionItem.autocomplete_list_is_default = list.is_default;
        this.questionItem.autocomplete_list_id = list.id;
        this.form.controls['autocomplete_list_name'].setValue(list.name);
    }

    fillAutoCompleteBox(list) {
        const items =
            list.items.length === 1 ? _.split(list.items[0], '\n') : list.items;

        for (let i = 0; i < items.length; i++) {
            this.list += items[i] + '\n';
        }
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    onAnswerFormatChanged($event) {
        this.answerType = $event.value;
        this.form.controls['min_value'].setValue(null);
        this.form.controls['max_value'].setValue(null);
        this.form.controls['max_length'].setValue(null);
        this.form.controls['regex_pattern'].setValue(null);
        this.form.controls['regex_validation_message'].setValue(null);
        this.form.controls['default_text'].setValue('');
        if ($event.value === 'Date_ROTW') {
            this.adapter.setLocale('en-GB');
        } else if ($event.value === 'Date_USA') {
            this.adapter.setLocale('en-US');
        }
        if ($event.value === 'Regex') {
            this.form.controls['regex_pattern'].setValidators([Validators.required]);
            this.form.controls['regex_validation_message'].setValidators([Validators.required]);
        } else {
            this.form.controls['regex_pattern'].setValidators([]);
            this.form.controls['regex_validation_message'].setValidators([]);
        }
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <SingleLineItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            max_length: new FormControl(this.questionItem.max_length),
            alias: new FormControl(this.questionItem.alias),
            is_required: new FormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new FormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            enable_sentiment_analysis: new FormControl(this.questionItem.enable_sentiment_analysis),
            default_text: new FormControl(this.questionItem.default_text || ''),
            html_class: new FormControl(this.questionItem.html_class),
            answer_format: new FormControl(this.questionItem.answer_format || this.types[0][0]),
            min_value: new FormControl(this.questionItem.min_value),
            max_value: new FormControl(this.questionItem.max_value),
            autocomplete_list_name: new FormControl(''),
            autocomplete_list: new FormControl(''),
            item_position: new FormControl(this.questionItem.item_position),
            regex_pattern: new FormControl(this.questionItem.regex_pattern),
            regex_validation_message: new FormControl(this.questionItem.regex_validation_message),
            question_text_position: new FormControl(
                this.questionItem.question_text_position
            ),
            width: new FormControl(this.questionItem.width)
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        const control = this.form.controls;
        this.checkMergeCodeInDatePicker(control['answer_format'].value, control['default_text'].value);

        this.form.get('min_value').valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(500),
            distinctUntilChanged()
        )
        .subscribe(s => {
            if (this.isNumericMinMax(control['answer_format'].value) ) {
                control['min_value'].setValue(s);
            }
        });

        this.form.get('max_value').valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(500),
            distinctUntilChanged()
        )
        .subscribe(s => {
            if (this.isNumericMinMax(control['answer_format'].value) ) {
                control['max_value'].setValue(s);
            }
        });

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                debounceTime(500)
            )
            .subscribe(s => {
                this.checkMergeCodeInDatePicker(s['answer_format'], s['default_text']);

                this.shareQuestionItem(
                    control,
                    this.isNumericMinMax(control['answer_format'].value)
                );
            });
    }

    private checkMergeCodeInDatePicker(format, defaultText) {
        if (format === 'Date_ROTW' || format === 'Date_USA') {
            this.defaultTextPlaceholder = defaultText ? defaultText.toString() : '';
            this.setDefaultTextToPlaceholder = this.defaultTextPlaceholder.startsWith('@@');
        }
    }

    private shareQuestionItem(
        control: { [key: string]: AbstractControl },
        isNumericMinMax: boolean
    ) {
        const questionItem = new SingleLineItem();
        questionItem.item_type = SurveyQuestionType.SINGLE_LINE_TEXT;
        questionItem.max_length = !isNumericMinMax ? control['max_length'].value : null;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.default_text = control['default_text'].value instanceof Date ?
                                    convertDateToNonTimezoneString(control['default_text'].value) :
                                    control['default_text'].value && this.answerType === 'Decimal' ?
                                    control['default_text'].value.replace(',', '.') : control['default_text'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.answer_format = control['answer_format'].value;
        questionItem.min_value = isNumericMinMax
            ? control['min_value'].value && this.answerType === 'Decimal' ?
              control['min_value'].value.replace(',', '.') : control['min_value'].value
            : convertDateToNonTimezoneString(control['min_value'].value);
        questionItem.max_value = isNumericMinMax
            ? control['max_value'].value && this.answerType === 'Decimal' ?
            control['max_value'].value.replace(',', '.') : control['max_value'].value
            : convertDateToNonTimezoneString(control['max_value'].value);
        questionItem.autocomplete_list_name =
            control['autocomplete_list_name'].value;
        questionItem.autocomplete_list = control['autocomplete_list'].value;
        questionItem.question_text_position =
            control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.width = control['width'].value;
        questionItem.autocomplete_list_id = this.questionItem.autocomplete_list_id;
        questionItem.regex_pattern = control['regex_pattern'].value;
        questionItem.regex_validation_message = control['regex_validation_message'].value;
        questionItem.enable_sentiment_analysis = control['enable_sentiment_analysis'].value;
        
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any, isDate = false) {
        return SurveyEditorValidator.validateNumericInput(event, isDate, this.answerType === 'Decimal');
    }

    public onMergeDefaultText(updatedText) {
        const field = this.form.controls['default_text'];
        const answerFormat = this.form.controls['answer_format'].value;
        if (answerFormat === 'Date_ROTW' || answerFormat === 'Date_USA') {
            field.setValue(updatedText);
        } else {
            field.setValue(`${field.value} ${updatedText}`);
        }
    }

    public isNumericMinMax(name: string) {
        return (
            name === 'Decimal' ||
            name === 'Integer' ||
            name === 'Numeric' ||
            name === 'Money'
        );
    }

    public isText(): boolean {
        return ['None', 'Alpha', 'AlphaNumeric', 'Uppercase', 'Lowercase']
            .includes(this.answerType);
    }

    ngOnDestroy() {}
}
