<cb-spinner [show]="isLoading"></cb-spinner>
<div *ngIf="!isLoading" class="cb-edit-my-info">
    <h2 class="cb-edit-my-info-title">{{ 'EDIT-MY-INFO.TITLE' | translate }}</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div
            class="cb-edit-my-info-warning"
            *ngIf="isAdContact && !overrideAdMapping"
        >
            {{ 'EDIT-MY-INFO.AD-CONTACT-EDIT-DISABLED' | translate }}
        </div>
        <div
            *ngIf="allowEdit && !isAdContact"
            class="form-sub-section cb-edit-my-info-password-section"
        >
            <h3 class="cb-edit-my-info-subtitle">
                {{ 'EDIT-MY-INFO.CHANGE-PASSWORD' | translate }}
            </h3>
            <div class="form-group">
                <mat-form-field class="cb-password-input">
                    <input
                        matInput
                        type="password"
                        placeholder="{{ 'EDIT-MY-INFO.CURRENT-PASSWORD' | translate }}"
                        formControlName="current_password"
                        autocomplete ="new-password"
                    />
                    <mat-error>
                        {{ 'EDIT-MY-INFO.CURRENT-PASSWORD-REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="cb-password-input">
                    <input
                        matInput
                        type="password"
                        placeholder="{{ 'EDIT-MY-INFO.NEW-PASSWORD' | translate }}"
                        formControlName="password"
                        autocomplete ="new-password"
                    />
                    <mat-error>
                        {{ passwordValidationError }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="cb-password-input cb-confirm-password-input">
                    <input
                        matInput
                        type="password"
                        placeholder="{{ 'EDIT-MY-INFO.PASSWORD-CONFIRM' | translate }}"
                        formControlName="confirm_password"
                        autocomplete ="new-password"
                    />
                    <mat-error>
                        {{ 'EDIT-MY-INFO.PASSWORD-MISMATCH-CONFIRMATION' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-sub-section cb-user-info-section">
            <h3 class="cb-edit-my-info-subtitle">
                {{ 'EDIT-MY-INFO.USER-INFO' | translate }}
            </h3>
            <div class="form-group">
                <mat-form-field class="cb-user-profile-input">
                    <input
                        matInput
                        placeholder="{{ 'EDIT-MY-INFO.EMAIL' | translate }}"
                        formControlName="email"
                    />
                    <mat-error>
                        {{ 'EDIT-MY-INFO.EMAIL-ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field
                    *ngIf="showSmsPhone"
                    class="cb-user-profile-input"
                >
                    <input
                        matInput
                        placeholder="{{ 'EDIT-MY-INFO.PHONE-SMS' | translate }}"
                        formControlName="phoneSms"
                    />
                    <mat-error>
                        {{ 'EDIT-MY-INFO.PHONE-SMS-ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <ng-container *ngFor="let propertyName of propertyNames">
                    <mat-form-field class="cb-user-profile-input">
                        <input
                            matInput
                            [placeholder]="propertyName"
                            [formControlName]="propertyName"
                        />
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
        <div
            *ngIf="allowEdit"
            class="cb-survey-fixed-bottom-actions"
        >
            <div class="cb-container">
                <div class="actions-content button-containers">
                    <button
                        mat-raised-button
                        class="btn-submit"
                        type="submit"
                    >
                        {{ 'SHARED.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
