import { Component, OnInit, OnDestroy } from '@angular/core';
import { SurveysProvider } from '../../../providers';
import { ToasterService } from '../../../../infrastructure/services';
import { SurveyResponseProvider } from '../../../../infrastructure/providers/responses/survey-response.provider';
import { String } from 'typescript-string-operations-ng4';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-restore-data-settings',
    templateUrl: './restore-data-settings.component.html',
    styleUrls: ['./restore-data-settings.component.scss']
})
@AutoUnsubscribe()
export class RestoreDataSettingsComponent implements OnInit, OnDestroy {
    isLoading: boolean;
    public form: FormGroup;
    public deletedSurveyList;
    public surveyListWithDeletedResponses;
    public filteredObservableSurveys: Observable<Array<any>>;
    public filteredObservableResponses: Observable<Array<any>>;
    private SUCCESS_SURVEY: string;
    private SUCCESS_RESPONSES =
        'SYSTEM_SETTINGS.RESTORE-DATA.SUCCESS-RESPONSES';
    private restoreSurveyItem;
    private restoreResponseItem;
    private componentDestroyed = new Subject();

    constructor(
        private surveyProvider: SurveysProvider,
        private surveyResponseProvider: SurveyResponseProvider,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            restoreSurveyName: new FormControl('', [Validators.required]),
            restoreSurveyResponses: new FormControl(false),
            restoreResponsesName: new FormControl('', [Validators.required]),
            startDate: new FormControl(''),
            endDate: new FormControl('')
        });
        this.isLoading = true;
        this.getDeletedSurveyList();
        this.getSurveysWithDeletedResponsesList();
        this.translateService.get('SYSTEM_SETTINGS.RESTORE-DATA.SUCCESS-SURVEY').subscribe((data: string) => {
            this.SUCCESS_SURVEY = data;
        });
        this.translateService.get('SYSTEM_SETTINGS.RESTORE-DATA.SUCCESS-RESPONSES').subscribe((data: string) => {
            this.SUCCESS_RESPONSES = data;
        });

        this.filteredObservableSurveys = this.form['controls'][
            'restoreSurveyName'
            ].valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            startWith(''),
            map((val: string) => this.filter(val, true))
        );

        this.filteredObservableResponses = this.form['controls'][
            'restoreResponsesName'
            ].valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            startWith(''),
            map((val: string) => this.filter(val))
        );
    }

    getDeletedSurveyList() {
        this.surveyProvider.getDeletedSurveys().subscribe((data: any) => {
            this.deletedSurveyList = data;
            this.isLoading = false;
        }, err => this.isLoading = false);
    }

    getSurveysWithDeletedResponsesList() {
        this.surveyProvider
            .getSurveysWithDeletedResponses()
            .subscribe((data: any) => {
                    this.surveyListWithDeletedResponses = data;
                    this.isLoading = false;
                },
                error => this.errorHandler(error));
    }

    restoreSurvey() {
        this.isLoading = true;
        const restoreSurveyResponses = this.form.get('restoreSurveyResponses').value;
        this.surveyProvider
            .restoreSurvey(this.restoreSurveyItem.id, restoreSurveyResponses)
            .subscribe((data: any) => {
                    this.toasterService.showInfo(
                        String.Format(
                            this.SUCCESS_SURVEY,
                            this.restoreSurveyItem.name
                        ),
                        true
                    );
                    this.getDeletedSurveyList();
                },
                error => this.errorHandler(error));
    }

    onRestoreSurveyResponses() {
        this.isLoading = true;
        const startDate = this.form.get('startDate').value;
        const endDate = this.form.get('endDate').value;
        this.surveyResponseProvider
            .restoreResponses(
                this.restoreResponseItem.id,
                startDate,
                endDate
            )
            .subscribe((data: any) => {
                    this.toasterService.showInfo(
                        String.Format(this.SUCCESS_RESPONSES, this.restoreResponseItem.name),
                        true
                    );
                    this.getSurveysWithDeletedResponsesList();
                },
                error => this.errorHandler(error));
    }

    private errorHandler(error) {
        this.isLoading = false;
    }

    onAutocompleteSelected(item, isSurvey = false) {
        if (isSurvey) {
            this.form.controls['restoreSurveyName'].setValue(item.name);
            this.restoreSurveyItem = item;
        } else {
            this.form.controls['restoreResponsesName'].setValue(item.name);
            this.restoreResponseItem = item;
        }

    }

    filter(value, isSurvey = false) {
        const entryArray = isSurvey ? this.deletedSurveyList : this.surveyListWithDeletedResponses;
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
        const options = entryArray ? entryArray.filter(option =>
            option.name ? option.name.toLowerCase().includes(filterValue) : false
        ) : [];
        return options;
    }

    get isValidSurvey() {
        return this.form.controls['restoreSurveyName'].valid;
    }

    get isValidResponse() {
        return this.form.controls['restoreResponsesName'].valid;
    }
    ngOnDestroy() {}
}
