<!--mat-dialog-content-->
<div class="dialog-content cb-widget-dialog-content">
    <div class="cb-widget-dialog-title-group">
        <h2 mat-dialog-title>{{ 'DIALOG.REORDER-ELEMENTS' | translate }}</h2>

        <span class="cb-reorder-items-buttons">
            <button
                mat-button
                class="cb-button-default"
                [ngClass]="{ active: reorderByItems }"
                (click)="reorderElementsByItems()"
            >
                {{ 'DIALOG.ITEMS' | translate }}
            </button>
            <button
                mat-button
                class="cb-button-default"
                [ngClass]="{ active: reorderByPages }"
                (click)="reorderElementsByPages()"
            >
                {{ 'DIALOG.PAGES' | translate }}
            </button>
        </span>
    </div>

    <div class="cb-reorder-page draggable-item">
        <cb-svg-icon
            class="icon icon-move"
            [name]="'move-page'"
            *ngIf="reorderByPages"
        ></cb-svg-icon>
        <div class="cb-reorder-page-title">Page 1</div>
        <div class="reorder-items">
            <div
                class="reorder-item item-size-s draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
            <div
                class="reorder-item item-size-s draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
            <div
                class="reorder-item item-size-s draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
            <div
                class="reorder-item item-size-s draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
            <div
                class="reorder-item item-size-m draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
            <div
                class="reorder-item item-size-l draggable-item shadowed-section"
            >
                <cb-svg-icon
                    class="icon icon-move"
                    [name]="'move-page'"
                    *ngIf="reorderByItems"
                ></cb-svg-icon>
                <div class="reorder-item-title">
                    {{ 'DIALOG.RESPONDENT-LOCATION' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="cb-reorder-page draggable-item">
        <cb-svg-icon
            class="icon icon-move"
            [name]="'move-page'"
            *ngIf="reorderByPages"
        ></cb-svg-icon>
        <div class="cb-reorder-page-title">
            {{ 'SHARED.PAGE' | translate }} 2
        </div>
        <div class="reorder-items no-items-placeholder"></div>
    </div>

    <div class="buttons-container large-button-container">
        <button mat-button type="button" class="btn-cancel" mat-dialog-close>
            >{{ 'SHARED.CANCEL' | translate }}
        </button>
        <button mat-raised-button type="submit" class="btn-submit">
            >{{ 'SHARED.ADD' | translate }}
        </button>
    </div>
</div>
