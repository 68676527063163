import { QuestionItem } from './questionItem';
import { ItemOption } from './itemOption';

export class ImageChoiceItem extends QuestionItem {
    select_min_limit: number;
    select_max_limit: number;
    randomize: boolean;
    layout: string;
    show_text_labels: boolean;
    choices: ItemOption[];
    image_width: number;
    image_height: number;
    item_position: string;
    question_text_position: string;
}
