export class TakeSurveyData {
    public static SIGNATURE_FORM_DATA = 'signatureFormData';
    public static SURVEY_PASSWORD = 'surveyPassword';
    public static UPDATE_NUMERATION =
        'takeSurveyUpdateItemNumeration';
    public static UPDATED_RATING_ITEM_ID = 'ratingItemPreviewUpdatedId';
    public static SURVEY_TEXTS = 'surveyTexts';
    public static VALIDATION_TEXTS = 'validationTexts';
    public static QUESTION_ID = 'questionID-';
}

export class TakeSurveyActions {
    public static UPDATE_CONDITIONS_QUEQUE = 'updateConditionsQueue';
}

export class TakeSurveyRequestType {
    public static POST_CURRENT_PAGE = 'postCurrentPage';
    public static CREATE_RESPONSE = 'createResponse';
}
