<div class="cb-question-item-caption" [id]="questionId + questionItem.id">
    <span
        *ngIf="questionItem.item_number"
        class="question-item-number"
        [class.required]="
            questionItem.is_required &&
            questionItem.item_number &&
            (appearanceSettings && appearanceSettings.showAsterisks)
        "
        ><strong>{{ questionItem.item_number }}.</strong></span
    >
    <div
        class="cb-question-item-title"
        [innerHTML]="
            questionItem.question_text && questionItem.question_text !== emptyRootElement
                ? (questionItem.question_text | keepHtml)
                : ''
        "
        [class.required]="
            questionItem.is_required &&
            !questionItem.item_number &&
            (appearanceSettings && appearanceSettings.showAsterisks)
        "
    ></div>
    <div
        class="cb-question-item-subtitle"
        *ngIf="!hideSubtext"
        [innerHTML]="
            questionItem.subtext && questionItem.subtext !== emptyRootElement
                ? (questionItem.subtext | keepHtml)
                : ''
        "
    ></div>
</div>
