<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>

        <div
            class="cb-question-item-content"
            *ngIf="questionItem.rank_order_type === 'Numeric' && !isPrint"
        >
            <form [formGroup]="formGroup" autocomplete="off">
                <div
                    class="cb-rank-order-input-options"
                    formArrayName="choices"
                    role="group" [attr.aria-labelledby]="questionId + questionItem.id"
                >
                    <div
                        class="cb-rank-order-input"
                        *ngFor="let r of ranks; let i = index"
                        [formGroupName]="i"
                    >
                        <div
                            class="cb-rank-order-label"
                            *ngIf="questionItem.option_type === 'Text'"
                            [innerHtml]="getRankTextValue(r) | keepHtml"
                        ></div>
                        <div
                            class="cb-rank-order-label"
                            *ngIf="questionItem.option_type === 'Image' && getRankImageValue(r)"
                        >
                            <img
                                class="image-label"
                                [src]="getRankImageValue(r)"
                                [alt]="r['controls']['alias'].value"
                            />
                        </div>
                        <div class="cb-rank-order-control">
                            <mat-form-field floatLabel="never">
                                <mat-label>{{surveyText.AnswerTextPrompt}}</mat-label>
                                <input
                                    type="number"
                                    [attr.aria-label]="'Enter rank for ' + getRankTextValue(r)"
                                    matInput
                                    formControlName="answer"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div
            class="cb-question-item-content"
            *ngIf="questionItem.rank_order_type === 'TopN' && !isPrint"
        >
            <form [formGroup]="formGroup" role="form" [attr.aria-labelledby]="questionId + questionItem.id">
                <ng-container formArrayName="answers" >
                    <div
                        *ngFor="let r of ranks; let i = index"
                        [formGroupName]="i"
                    >
                        <label>{{ i + 1 }})</label>
                        <mat-form-field class="rank-order-select">
                            <mat-select formControlName="answer"
                                        [aria-label]="'Select item for rank ' +  +(i + 1) ">
                                <mat-option
                                    *ngFor="let choice of enabled_choices"
                                    [value]="choice"
                                    [innerHtml]="choice.text | keepHtml"
                                >
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </form>
        </div>

        <div
            tabindex="0"
            role="group" [attr.aria-labelledby]="questionId + questionItem.id"
            class="cb-rank-order-container cb-rank-order-container-single-col cb-question-item-content"
            *ngIf="
                questionItem.rank_order_type === 'DragnDroppable' && !isPrint
            "
        >
            <div
                cdkDropList
                class="cb-rank-order-options"
                [cdkDropListData]="questionItem.choices_to_rank"
                (cdkDropListDropped)="onSimpleDnDDrop($event)"
            >
                <div
                    cdkDrag
                    class="cb-rank-order-item rank-order-dnd"
                    *ngFor="let choice of questionItem.choices_to_rank"
                >
                    <p
                        *ngIf="questionItem.option_type === 'Text'"
                        [innerHtml]="choice.text | keepHtml"
                    ></p>
                    <img
                        class="image-label"
                        *ngIf="questionItem.option_type === 'Image' && choice.image"
                        [src]="choice.image.file_url"
                        [alt]="choice.alias"
                    />
                    <cb-svg-icon
                        class="icon icon-move"
                        [name]="'move-page'"
                    ></cb-svg-icon>
                </div>
            </div>
        </div>

        <div
            tabindex="0"
            role="group" [attr.aria-labelledby]="questionId + questionItem.id"
            class="cb-rank-order-container cb-question-item-content"
            cdkDropListGroup
            *ngIf="
                questionItem.rank_order_type === 'SelectableDragnDrop' && !isPrint
            "
        >
            <div
                cdkDropList
                class="cb-rank-order-options"
                [cdkDropListData]="questionItem.choices_to_rank"
                (cdkDropListDropped)="onSelectableDnDDrop($event)"
            >
                <div
                    cdkDrag
                    class="cb-rank-order-item rank-order-dnd"
                    *ngFor="let choice of questionItem.choices_to_rank"
                >
                    <span
                        *ngIf="questionItem.option_type === 'Text'"
                        [innerHtml]="choice.text | keepHtml"
                    ></span>
                    <img
                        class="image-label"
                        *ngIf="questionItem.option_type === 'Image' && choice.image"
                        [src]="choice.image.file_url"
                        [alt]="choice.alias"
                    />
                    <cb-svg-icon
                        class="icon icon-move"
                        [name]="'move-page'"
                    ></cb-svg-icon>
                </div>
            </div>
            <div
                cdkDropList
                class="cb-rank-order-options"
                [cdkDropListData]="questionItem.selected_choices"
                (cdkDropListDropped)="onSelectableDnDDrop($event)"
            >
                <div
                    cdkDrag
                    class="cb-rank-order-item  rank-order-dnd"
                    *ngFor="let choice of questionItem.selected_choices"
                >
                    <span
                        *ngIf="questionItem.option_type === 'Text'"
                        [innerHtml]="choice.text | keepHtml"
                    ></span>
                    <img
                        class="image-label"
                        *ngIf="questionItem.option_type === 'Image' && choice.image"
                        [src]="choice.image.file_url"
                        [alt]="choice.alias"
                    />
                    <cb-svg-icon
                        class="icon icon-move"
                        [name]="'move-page'"
                    ></cb-svg-icon>
                </div>
            </div>
        </div>

        <div class="cb-question-item-content" *ngIf="isPrint">
            <form
                autocomplete="off"
            >
                <div class="cb-rank-order-input-options">
                    <div
                        class="cb-rank-order-input"
                        *ngFor="let r of enabled_choices; let i = index"
                    >
                        <div
                            class="cb-rank-order-label"
                            *ngIf="questionItem.option_type === 'Text' || questionItem.rank_order_type === 'TopN'"
                            [innerHtml]="r.text | keepHtml"
                        ></div>
                        <div
                            class="cb-rank-order-label"
                            *ngIf="questionItem.option_type === 'Image' && r.image"
                        >
                            <img
                                class="image-label"
                                [src]="r.image.file_url"
                                [alt]="r.alias"
                            />
                        </div>
                        <div class="cb-rank-order-control">
                            <mat-form-field floatLabel="never">
                                <input
                                    type="number"
                                    matInput
                                    [placeholder]="surveyText.AnswerTextPrompt"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
