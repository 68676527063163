import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
  selector: 'app-set-email-dialog',
  templateUrl: './set-email-dialog.component.html',
  styleUrls: ['./set-email-dialog.component.scss']
})
@AutoUnsubscribe()
export class SetEmailDialogComponent implements OnInit, OnDestroy {
    form: FormGroup;
    email: string;
    contactId: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<SetEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit() {     
        this.form = this.fb.group({
            email: [this.email, [Validators.required, Validators.email]]
        });
    }

    onSubmit() {
        const email = this.form.value.email;
        if (this.form.valid) {          
            this.dialogRef.close(email);
        }
    }

    ngOnDestroy() {}
}
