import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
@AutoUnsubscribe()
export class SearchInputComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    @Output() searchChanged: EventEmitter<void> = new EventEmitter();
    private componentDestroyed = new Subject();

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            searchString: new FormControl('')
        });
        this.initSearchString();
    }

    private initSearchString() {
        this.form
            .get('searchString')
            .valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(500),
            distinctUntilChanged()
        )
            .subscribe(text => this.searchChanged.emit(text));
    }

    ngOnDestroy() {}
}
