<div [style.text-align]="reportItem.item_position">
    <div *ngIf="!isTakeSurvey">
        <h3>Survey Response Details Item</h3>
    </div>
    <a
        mat-icon-button
        class="print-btn"
        type="button"
        *ngIf="isTakeSurvey && reportItem.show_print_button"
        [routerLink]="[printUrl]"
        [queryParams]="printParams"
        [target]="'_blank'"
    >
        <cb-svg-icon
            class="icon"
            [name]="'print'"
        ></cb-svg-icon>
    </a>
    <div *ngIf="isTakeSurvey">
        <ng-container
            *ngIf="
                reportItem &&
                reportItem.surveyData &&
                reportItem.include_response_details
            "
        >
            <strong>Survey Response:</strong>&emsp;{{
                reportItem.surveyData.survey.name
            }}<br/>
            <strong>Response GUID:</strong>&emsp;{{
                reportItem.surveyData.response.id
            }}<br/>
            <strong>Started:</strong>&emsp;{{
                reportItem.surveyData.response.started | date: 'medium'
            }}<br/>
            <strong>Completed:</strong>&emsp;{{
                reportItem.surveyData.response.ended | date: 'medium'
            }}<br/>
        </ng-container>
        <ng-container *ngIf="reportItem && !reportItem.display_inline">
            <a
                [routerLink]="[responseUrl]"
                [queryParams]="printParams"
                [target]="'_blank'"
            >
                {{reportItem.link_text}}
            </a><br/>
        </ng-container>
        <cb-survey-response-details
            *ngIf="reportItem && reportItem.display_inline"
            id="section-to-print"
            [pages]="pages"
            [response]="response"
            [surveyId]="surveyId"
            [showDisplayMessages]="reportItem.include_message_items"
            [showItemNumeration]="reportItem.show_question_numbers"
            [showPagesNumeration]="reportItem.show_page_numbers"
            [showHiddenItems]="reportItem.show_hidden_items"
            [showPoints]="reportItem.show_points"
            [showFileUploads]="false"
        ></cb-survey-response-details>
    </div>
</div>
