export function appendScriptToBody(script) {
    const newScript = document.createElement('script');
    newScript.className = 'advance-settings-appended';
    const inlineScript = document.createTextNode(script);
    newScript.appendChild(inlineScript);
    document.body.appendChild(newScript);
}

export function appendCssTobody(css) {
    const newCss = document.createElement('style');
    newCss.className = 'advance-settings-appended';
    const inlineCss = document.createTextNode(css);
    newCss.appendChild(inlineCss);
    document.body.appendChild(newCss);
}

export function appendToHead(data) {
    const head = document.createElement('head') as HTMLHeadElement;
    head.innerHTML = data;

    let node = <Element>head.firstChild;
    while (node) {
        const next = <Element>node.nextSibling;
        if (node.nodeName === 'SCRIPT') {
            const newNode = document.createElement(
                'script'
            ) as HTMLScriptElement;
            if (node.attributes.getNamedItem('src')) {
                newNode.src = node.attributes.getNamedItem('src').value;
            }
            while (node.firstChild) {
                newNode.appendChild(node.firstChild.cloneNode(true));
                node.removeChild(node.firstChild);
            }
            node = newNode;
        }
        document.head.appendChild(node);
        node = next;
    }
}

export function scrollIntoView(element) {
    if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
}
