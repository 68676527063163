import { Component } from '@angular/core';

@Component({
    selector: 'cb-toggle-search',
    templateUrl: './toggle-search.component.html',
    styleUrls: ['./toggle-search.component.scss']
})
export class ToggleSearchComponent {
    displaySearchField: boolean;

    constructor() {
        this.displaySearchField = false;
    }

    displaySearchFieldOnClick() {
        this.displaySearchField = true;
    }
}
