<h3>Message Format</h3>
<p>{{ actionItem.format }}</p>
<h3>From Email Address</h3>
<p>{{ actionItem.from }}</p>
<h3>To Email Address</h3>
<p>{{ actionItem.to }}</p>
<h3>Bcc</h3>
<p>{{ actionItem.bcc }}</p>
<h3>Subject</h3>
<p>{{ actionItem.subject }}</p>
<h3>Body</h3>
<p [innerHtml]="actionItem.body ? (actionItem.body | keepHtml) : ''"></p>
