import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Identity } from '../../../infrastructure/models';
import { IdentityStore } from '../../../infrastructure/store';
import { StorageProvider } from '../../../infrastructure/providers';
import { MfaTokenStorage, ResetPasswordProvider } from '../../providers';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'cb-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.scss']
})
@AutoUnsubscribe()
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
    public identity: Observable<Identity>;

    public sendEmail = true;
    public resetPassword = false;
    public showNotification = false;
    public message: string;
    public account: string;
    public notificationClass: string;
    public user: object;
    public emailSent: boolean;
    public brokenToken: boolean;
    private token: string;
    public isLoading: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private store: IdentityStore,
        private route: ActivatedRoute,
        private $storageProvider: StorageProvider,
        private $resetPasswordProvider: ResetPasswordProvider,
        private translateService: TranslateService,
        private mfaTokenStorage: MfaTokenStorage
    ) {
        this.identity = this.store.identity;
    }

    ngOnInit() {
        this.identity.subscribe(i => {
            if (i && i.access_token) {
                this.store.goToRedirectUri(i);
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params.token && params.account_name) {
                this.sendEmail = false;
                this.account = params.account_name;
                this.token = params.token;
                this.$storageProvider.saveAccount(this.account);
                this.checkAccount(params.token);
            }
        });
    }

    sendMail($event) {
        let message = '';
        this.translateService
            .get('RESET-PASSWORD.EXPLAIN')
            .subscribe((data: string) => {
                message = data;
            });
        this.account = $event.account;
        this.$storageProvider.saveAccount(this.account);
        const data = {
            contact_id: $event.contactid,
            is_admin_site: true
        };
        this.$resetPasswordProvider.sendEmailForResetPassword(data)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                result => {
                    this.emailSent = true;
                    this.brokenToken = false;
                },
                error => {
                    this.emailSent = true;
                    this.brokenToken = false;
                }
            );
    }

    checkAccount(token) {
        this.isLoading = true;
        this.$resetPasswordProvider.getUserByToken(token)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                result => {
                    this.user = result;
                    this.resetPassword = true;
                    this.isLoading = false;
                },
                error => {
                    this.resetPassword = false;
                    this.brokenToken = true;
                    this.sendEmail = true;
                    this.isLoading = false;
            }
        );
    }

    resetMail($event) {
        const message = `Password successfully updated. Click "BACK TO LOGIN" link below to login.`;
        const data = {
            new_password: $event.passwordConfirmation,
            token: this.token
        };
        this.$resetPasswordProvider.sendNewPassword(data)
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                (result:any) => {
                    this.resetPassword = false;
                    this.showNotification = true;
                    this.message = message;
                    if (result.mfa_email_token) {
                        const account = environment.isMultiTenant ? this.account : null;
                        this.mfaTokenStorage.setMfaEmailToken(String(this.user), account, result.mfa_email_token);
                    }                    
                },
                error => {
                    this.resetPassword = false;
                    this.showNotification = true;
                    this.message = 'Error on update password';
                }
        );
    }

    ngOnDestroy() {}
}
