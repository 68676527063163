import { Component, Input } from '@angular/core';
import { WebhookItem } from '../../../models/survey-items/action-items/webhookItem';

@Component({
    selector: 'cb-webhook-item-preview',
    templateUrl: './webhook-item-preview.component.html',
    styleUrls: ['./webhook-item-preview.component.scss']
})
export class WebhookPreviewItemComponent {
    @Input() actionItem: WebhookItem;
    constructor() {}
}
