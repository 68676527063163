import {
    SurveyQuestionType,
    SurveyAnswerFormatType
} from '../../../../infrastructure/consts/surveys.consts';
import { SurveyEditorValidator } from '../../../../infrastructure/validators/survey-editor.validators';
import { SingleLineItem } from '../../../models/survey-items/question-items/singleLineItem';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { AutocompleteListProvider } from '../../../../app-admin/providers';
import * as _ from 'lodash';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import {TakeSurveySanitizerService} from '../../../services/take-survey-sanitizer.service';

@Component({
    selector: 'cb-singlelines-item-preview',
    templateUrl: './singlelines-item-preview.component.html',
    styleUrls: ['./singlelines-item-preview.component.scss']
})
@AutoUnsubscribe()
export class SinglelinesPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: SingleLineItem;
    @Input() isSubQuestion: boolean;
    @Input() validationError: string;
    @Output() updated = new EventEmitter<any>();
    listOptions: string[] = [];
    filteredOptions: string[] = [];
    form: FormGroup;
    sumTotalItem = SurveyQuestionType.SUM_TOTAL;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    resetFormEvent: Subject<void> = new Subject<void>();
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;

    constructor(private autocompleteListProvider: AutocompleteListProvider,
                private sharedService: SharedService,
                private changeDetectorRef: ChangeDetectorRef,
                private printService: PrintService,
                private elem: ElementRef,
                private sanitizerService: TakeSurveySanitizerService
        ) { }

    ngOnInit() {
        // TODO: refactor code to use answer object instead of default_text
        const answerText = this.questionItem.item_type === this.sumTotalItem
            ? this.sanitizerService.sanitizeHtml(this.questionItem.answer_text)
            : this.sanitizerService.sanitizeHtml(this.questionItem.default_text);

        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            answer_text: this.questionItem.is_required
                ? new FormControl(answerText, [Validators.required])
                : new FormControl(answerText)
        });

        if (this.questionItem.autocomplete_list_id) {
            this.getAutoCompleteList(this.questionItem.autocomplete_list_id);
        }

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                tap((s) => this.filterAutocompleteList(s.answer_text)),
                debounceTime(500)
            )
            .subscribe(s => this.updated.emit(s));

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngAfterViewInit() {
        if (!this.isSubQuestion) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    resetForm() {
        this.form.get('answer_text').reset();
        this.resetFormEvent.next();
    }

    private filterAutocompleteList(val: string): string[] {
        if (!this.questionItem.autocomplete_list_id) {
            return;
        }

        this.filteredOptions = val
            ? this.listOptions.filter(option => option.toLowerCase().includes(val.toLowerCase()))
            : [];
    }

    private getAutoCompleteList(id) {
        this.autocompleteListProvider.getListById(id).subscribe(list => {
            this.listOptions = list.items.length === 1
                ? _.split(list.items[0], '\n')
                : list.items;

            this.filterAutocompleteList(this.form['controls']['answer_text'].value);
        });
    }

    onNumericKeyUp(event: any, isDate = false) {
        if (this.questionItem.item_type !== this.sumTotalItem) {
            return true;
        }
        if (
            this.questionItem.answer_format === SurveyAnswerFormatType.DECIMAL ||
            this.questionItem.answer_format === SurveyAnswerFormatType.NUMERIC
        ) {
            return SurveyEditorValidator.validateNumericInput(
                event,
                isDate,
                true
            );
        }
        return SurveyEditorValidator.validateNumericInput(event, isDate);
    }

    updateDate(date) {
        this.form['controls']['answer_text'].setValue(date);
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
