import { QuestionItem } from './questionItem';

export class SignatureItem extends QuestionItem {
    pen_color: string;
    width: number;
    height: number;
    question_text_position: string;
    item_position: string;
    dataUrl: string;
    file_id: string;
    answer: any;
    surveyId: number;
    responseId: string;
}
