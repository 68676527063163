import { Action } from "@ngrx/store";
import { Survey } from "../../../infrastructure/models";
import { SurveyGet } from "../../models";

export const LOAD_SURVEY = '[Current Survey] Load Survey';
export const LOAD_SURVEY_SUCCESS = '[Current Survey] Load Survey Success';
export const LOAD_SURVEY_FAILED = '[Current Survey] Load Survey Failed';
export const DISPOSE_CURRENT_SURVEY = '[Current Survey] Dispose Survey';
export const TOGGLE_FAVORITE = '[Current Survey] Toggle Favorite';
export const TOGGLE_LOCK = '[Current Survey] Toggle Lock';
export const CHANGE_STATUS = '[Current Survey] Change Status';
export const PRINT_SURVEY = '[Survey] Print Survey';
export const CHANGE_CURRENT_SURVEY_URL = '[Current Survey] Change Url';

export class LoadSurveyAction implements Action {
    readonly type = LOAD_SURVEY;

    constructor(public payload: SurveyGet) {}
}

export class LoadSurveySuccessAction implements Action {
    readonly type = LOAD_SURVEY_SUCCESS;

    constructor(public payload: Survey) {}
}

export class LoadSurveyFailedAction implements Action {
    readonly type = LOAD_SURVEY_FAILED;

    constructor(public payload: any) {}
}
export class DisposeCurrentSurveyAction implements Action {
    readonly type = DISPOSE_CURRENT_SURVEY;

    constructor() {}
}
export class ToggleFavoriteAction implements Action {
    readonly type = TOGGLE_FAVORITE;

    constructor() {}
}
export class ToggleLockAction implements Action {
    readonly type = TOGGLE_LOCK;

    constructor() {}
}
export class ChangeStatusAction implements Action {
    readonly type = CHANGE_STATUS;

    constructor(public payload: string) {}
}

export class PrintSurveyAction implements Action {
    readonly type = PRINT_SURVEY;

    constructor(public payload: boolean) {}
}

export class ChangeCurrentSurveyUrlAction implements Action {
    readonly type = CHANGE_CURRENT_SURVEY_URL;

    constructor(public payload: string) {}
}

export type Actions = LoadSurveyAction |
LoadSurveySuccessAction |
LoadSurveyFailedAction |
DisposeCurrentSurveyAction |
ToggleFavoriteAction |
ToggleLockAction |
ChangeStatusAction|
PrintSurveyAction |
ChangeCurrentSurveyUrlAction;
