import { QuestionItem } from './questionItem';

export class SliderItem extends QuestionItem {
    value_type: string;
    min_value: number;
    max_value: number;
    step_size: number;
    alias: string;
    default_value: number;
    value: number;
    randomize: boolean;
    allow_other: boolean;
    alias_position: string;
    image_position: string;
    question_text_position: string;
    item_position: string;
    show_value: string;
    choices: any[];
}
