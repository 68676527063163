<div class="dialog-content">
    <h2 mat-dialog-title>
        {{ 'SYSTEM_SETTINGS.SYSTEM.TIME-ZONE-SUB' | translate }}
    </h2>
    <cb-spinner [show]="isLoading"></cb-spinner>
    <form *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
        <p
            *ngIf="isLegacyTimeZoneSet" 
            class="sub-section-main-text" 
            [innerText]="legacyTimeZoneDescription"
        >
        </p>
        <p
            *ngIf="!isLegacyTimeZoneSet"
            class="sub-section-main-text"
            [innerText]="'SYSTEM_SETTINGS.SYSTEM.CONFIRM-TIME-ZONE' | translate"
        >
        </p>
        <mat-form-field class="full-width-input">
            <mat-select
                formControlName="timeZoneId"
                 [placeholder]="'SYSTEM_SETTINGS.SYSTEM.SELECT-TIME-ZONE-PROMPT' | translate"
            >
                <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.id">
                    {{timeZone.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'SYSTEM_SETTINGS.SYSTEM.TIME-ZONE-REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <div class="buttons-container">
            <button
                mat-raised-button
                type="submit"
                [disabled]="!form.valid"
                class="btn-submit"
            >
                {{ 'SHARED.SAVE' | translate }}
            </button>
        </div>
    </form>
</div>
