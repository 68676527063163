import { SharedService } from '../../../../infrastructure/services/shared.service';
import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { RatingItem } from '../../../models/survey-items/question-items/ratingItem';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { PrintService } from '../../../../infrastructure/services';
import * as _ from 'lodash';
import { ItemOption } from '../../../models/survey-items/question-items/itemOption';

@Component({
    selector: 'cb-rating-item-preview',
    templateUrl: './rating-item-preview.component.html',
    styleUrls: ['./rating-item-preview.component.scss']
})
export class RatingPreviewItemComponent implements AfterViewInit, OnInit {
    @Input() questionItem: RatingItem;

    verticalBarItem = 'VerticalBar';
    reversedBoxItem = 'ReversedBox';
    squareItem = 'Square';
    starItem = 'Star';
    starStyle: object;
    marked = -1;
    savedScore = -1;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    showSelected: string;
    questionId = TakeSurveyData.QUESTION_ID;
    enabled_choices: ItemOption[];

    constructor(
        private sharedService: SharedService,
        private printService: PrintService,
        private elem: ElementRef
        ) {}

    ngOnInit() {
        this.enabled_choices = getEnabledChoices(this.questionItem.choices);
        this.starStyle = {
            width: `${this.questionItem.option_size}px`,
            height: `${this.questionItem.option_size}px`
        };
        if (!this.questionItem.selectedScore) {
            const defaultChoiceIndex = _.findIndex(this.enabled_choices, ['is_default', true]);
            if (defaultChoiceIndex < 0) {
                this.questionItem.selectedScore = 0;
            } else {
                this.onMark(defaultChoiceIndex);
            }
        }
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    public onMark(index) {
        this.marked = this.getMarkedItemIndex(index);
        this.questionItem.selectedScore = this.questionItem.rating_type === this.reversedBoxItem
            ? this.marked
            : this.marked + 1;
        this.savedScore = this.questionItem.selectedScore;
        this.sharedService.share(TakeSurveyData.UPDATED_RATING_ITEM_ID, {
            id: this.questionItem.id
        });
    }

    public onHover(index) {
        this.showSelected = index;
    }

    public onLeave() {
        this.showSelected = '';
        this.questionItem.selectedScore = this.savedScore;
    }

    public isMarked(index) {
        if (
            this.questionItem.rating_type !== this.starItem &&
            this.questionItem.rating_type !== this.squareItem &&
            this.questionItem.rating_type !== this.reversedBoxItem
        ) {
            return this.questionItem.rating_type === this.verticalBarItem
                ? this.verticalBarRatingType(index)
                : this.ratingStyle('item-default', 'item-selected', index);
        } else if (this.questionItem.rating_type === this.starItem) {
            return this.ratingStyle('star-default', 'star-selected', index);
        } else if (this.questionItem.rating_type === this.squareItem) {
            return this.ratingStyle('box-default', 'box-selected', index);
        } else if (this.questionItem.rating_type === this.reversedBoxItem) {
            return this.ratingStyle('item-selected', 'item-default', index);
        }
    }

    public resetForm() {
        this.questionItem.selectedScore = 0;
    }

    private getMarkedItemIndex(index): number {
        if (this.questionItem.rating_type === this.verticalBarItem) {
            return this.marked === index
                ? this.enabled_choices.length
                : index;
        } else {
            return this.marked === index ? -1 : index;
        }
    }

    private verticalBarRatingType(index): string {
        if (this.questionItem.selectedScore <= index + 1) {
            return 'item-selected';
        } else {
            return 'item-default';
        }
    }

    private ratingStyle(
        defaultStyle: string,
        selectedStyle: string,
        index: number
    ): string {
        if (this.questionItem.selectedScore >= index + 1) {
            return selectedStyle;
        } else {
            return defaultStyle;
        }
    }
}
