import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { String } from 'typescript-string-operations-ng4';
import { MoveSurveysToFolderDialogComponent } from '../../../surveys/survey-edit/dialog-windows/move-surveys-to-folder-dialog/move-surveys-to-folder-dialog.component';
import { ArchiveSurveysConfirmationComponent } from '../../../surveys/survey-edit/dialog-windows/archive-surveys-confirmation/archive-surveys-confirmation.component';
import { AddSurveyDialogComponent } from '../../../surveys/survey-edit/dialog-windows/add-survey-dialog/add-survey-dialog.component';
import { AddFolderDialogComponent } from '../../../surveys/survey-edit/dialog-windows/add-folder-dialog/add-folder-dialog.component';
import { DialogsService } from '../../../../infrastructure/services/dialogs.service';
import { filter, first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FoldersProvider, SurveysProvider } from '../../../providers';
import { SurveysStore } from '../../../store';
import { ToasterService } from '../../../../infrastructure/services';
import { TranslateService } from '@ngx-translate/core';
import { CopySurveyDialogComponent } from './copy-survey-dialog/copy-survey-dialog.component';
import { Survey, SurveyListItem } from '../../../../infrastructure/models';
import { RenameSurveyDialogComponent } from './rename-survey-dialog/rename-survey-dialog.component';
import { map, result } from 'lodash';
import { SaveAsTEmplateDialogComponent } from '../save-as-template-dialog/save-as-template-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SurveyListDialogsService {

  createFolderDialogRef: MatDialogRef<AddFolderDialogComponent>;

  constructor(private dialog: MatDialog,
    private dialogService: DialogsService,
    private surveysProvider: SurveysProvider,
    private foldersProvider: FoldersProvider,
    private surveysStore: SurveysStore,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private routeService: Router
  ) { }
  createSurveyDialog(): void {
    const dialog = this.dialog.open(
      AddSurveyDialogComponent,
      {
        width: '470px',
      }
    );
    dialog.afterClosed().subscribe((created: Survey) => {
      if (created) {
        this.toasterService.showSuccess('SURVEYS-LIST.CREATE-SUCCESS', true, null, 'outline');
        this.routeService.navigate(['surveys', created.id, 'preview'])
      }
    });
  }

  createSurveyFromFileDialog(): void {
    const dialog = this.dialog.open(
      AddSurveyDialogComponent,
      {
        width: '470px',
        data: {
          fromFile: true
        }
      }
    );
    dialog.afterClosed().subscribe((created: any) => {
      if (created) {
        this.routeService.navigate(['surveys', created['survey_id'], 'preview'])
        this.toasterService.showSuccess('SURVEYS-LIST.CREATE-SUCCESS', true, null, 'outline');
      }
    });
  }

  createFolderDialog(): void {
    const dialogRef = this.dialog.open(
      AddFolderDialogComponent,
      {
        width: '470px'
      }
    );

    dialogRef.afterClosed().pipe(
      filter(data => data && data.created)
    ).subscribe(data => {
      this.surveysStore.loadSurveys();
      const message = String.Format(this.translateService.instant('SURVEYS-LIST.FOLDER-CREATE-SUCCESS'), data.name);
      this.toasterService.showSuccess(message, false, null, 'outline');
    });
  }
  deleteSelectedSurveysDialog(surveysSelected: number[]) {
    const textPrefix = surveysSelected.length > 1
      ? 'SURVEYS-LIST.DELETE-MULTIPLE-'
      : 'SURVEYS-LIST.DELETE-SINGLE-'

    return this.dialogService
      .confirmWithTranslate(
        textPrefix + 'TITLE',
        textPrefix + 'MESSAGE',
        ''
      )
      .pipe(
        first(),
        filter(result => result),
        tap(result => this.deleteSurveys(surveysSelected))
      );
  }

  moveSelectedSurveysToFolderDialog(surveysSelected: number[]) {
    const confirmationDialogRef = this.dialog.open(
      MoveSurveysToFolderDialogComponent,
      {
        width: '470px',
        data: { surveyIds: surveysSelected }
      });

    confirmationDialogRef.afterClosed()
      .pipe(first()).subscribe(result => {
        if (result && result.move) {
          this.moveSurveysToFolder(surveysSelected, result.folderId, result.folderName);
        }
      });
  }

  archiveSelectedSurveysDialog(surveysSelected: number[]) {
    const confirmationDialogRef = this.dialog.open(
      ArchiveSurveysConfirmationComponent,
      {
        width: '500px',
        data: { surveyIds: surveysSelected }
      });
    confirmationDialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.archiveSurveys(surveysSelected);
      }
    });
  }

  private deleteSurveys(surveyIds: number[]) {
    const successMsg = surveyIds.length > 1
      ? 'SURVEYS-LIST.DELETE-MULTIPLE-SUCCESS'
      : 'SURVEYS-LIST.DELETE-SINGLE-SUCCESS';

    this.surveysProvider
      .bulkDeleteSurveys(surveyIds)
      .pipe(first())
      .subscribe(() => {
        this.surveysStore.loadSurveys();
        this.surveysStore.loadFavoriteSurveys();
        this.toasterService.showInfo(successMsg, true, null, 'outline');
      });
  }

  moveSurveysToFolder(surveyIds, folderId: number, folderName?: string) {
    const successMsg = surveyIds.length > 1
      ? 'SURVEYS-LIST.MOVE-TO-FOLDER-MULTIPLE-SUCCESS'
      : 'SURVEYS-LIST.MOVE-TO-FOLDER-SINGLE-SUCCESS';
    const message = `${this.translateService.instant(successMsg)} "${folderName ?? this.translateService.instant('NEW-SURVEY-DIALOG.NO_FOLDER')}"`;
    this.foldersProvider
      .bulkMoveSurveysToFolder(surveyIds, folderId)
      .pipe(first())
      .subscribe(() => {
        this.surveysStore.loadSurveys();
        this.toasterService.showSuccess(message, false, null, 'outline');
      });
  }

  private archiveSurveys(surveyIds: number[]) {
    const successMsg = surveyIds.length > 1
      ? 'SURVEYS-LIST.ARCHIVE-MULTIPLE-SUCCESS'
      : 'SURVEYS-LIST.ARCHIVE-SINGLE-SUCCESS';

    this.surveysProvider
      .bulkArchiveSurveys(surveyIds)
      .pipe(first())
      .subscribe(() => {
        this.surveysStore.loadSurveys();
        this.surveysStore.loadFavoriteSurveys();
        this.toasterService.showSuccess(successMsg, true, null, 'outline');
      });
  }

  copySurveyDialog(survey: SurveyListItem) {
    const copySurveyDialogRef = this.dialog.open(
      CopySurveyDialogComponent,
      {
        width: '470px',
        data: {
          survey: survey
        }
      }
    );

    copySurveyDialogRef.afterClosed().subscribe((survey) => {
      if (survey) {
        this.surveysStore.loadSurveys();
        this.toasterService.showSuccess('SURVEYS-LIST.CREATE-SUCCESS', true, null, 'outline');
      }
    });
  }
  renameSurveyDialog(survey: SurveyListItem) {
    const renameSurveyDialogRef = this.dialog.open(
      RenameSurveyDialogComponent,
      {
        width: '470px',
        data: {
          surveyId: survey.id
        }
      }
    );

    renameSurveyDialogRef.afterClosed().subscribe((updated) => {
      if (updated) {
        this.surveysStore.loadSurveys();
        this.surveysStore.loadFavoriteSurveys();
      }
    });
  }
  saveAsTemplateDialog(survey: SurveyListItem) {
    this.dialog.open(SaveAsTEmplateDialogComponent, {
      width: '500px',
      data: {
          survey: survey
      }
  });
  }
}
