import { FormGroup } from '@angular/forms';

export class SyncValidators {
    static Equal(group: FormGroup) {
        if (!group.controls) {
            return null;
        }

        let firstStep = true;
        let prevVal = '';
        for (const name in group.controls) {
            if (group.controls.hasOwnProperty(name)) {
                const ctrl = group.controls[name];
                const val = ctrl.value;
                if (firstStep) {
                    prevVal = val;
                    firstStep = false;
                } else if (prevVal !== val) {
                    ctrl.setErrors({ equal: false });
                    return { equal: false };
                } else if (prevVal === val) {
                    if (ctrl.errors && ctrl.errors['equal'] !== undefined) {
                        delete ctrl.errors['equal'];
                    }
                    if (ctrl.errors && !Object.keys(ctrl.errors).length) {
                        ctrl.setErrors(null);
                    }
                }
            }
        }
        return null;
    }
}
