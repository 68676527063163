import { QuestionItem } from './questionItem';

export class RatingItem extends QuestionItem {
    option_text: string;
    rating_type: string;
    option_size: number;
    show_values: boolean;
    alias: string;
    choices: any[];
    item_position: string;
    question_text_position: string;
    show_selected_value: boolean;
    selectedScore: number;
}
