<button 
    type="button"
    class="icon-button"
    (mousedown) = "onMouseDown()"
    (mouseup) = "onMouseUp()"
    (mouseenter) = "onMouseEnter()"
    (mouseleave) = "onMouseLeave()"
>
    <img
        [src]="buttonImgSource"
    />
</button>