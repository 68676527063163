import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SingleLineItem } from '../../../models/survey-items/question-items/singleLineItem';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable, Subscription } from 'rxjs';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';

@Component({
    selector: 'cb-datepicker-en-us',
    templateUrl: './datepicker-en-us.component.html'
})
export class DatePickerEnUSComponent implements OnInit, OnDestroy {
    @Input() questionItem: SingleLineItem;
    @Output() updated = new EventEmitter<any>();
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
    @Input() resetForm: Observable<void>;
    datePicker: FormControl;
    resetSubscription: Subscription;
    surveyText = SurveyDefaultText;
    questionId = TakeSurveyData.QUESTION_ID;

    constructor(private adapter: DateAdapter<any>) {
    }

    ngOnInit(): void {
        let dateValue: Date;
        this.adapter.setLocale('en-US');
        if (this.questionItem.default_text && !this.questionItem.default_text.startsWith('@@')) {
            if (moment(this.questionItem.default_text, 'MM/DD/YYYY', true).isValid()) {
                dateValue = moment(this.questionItem.default_text, 'MM/DD/YYYY', true).toDate();
            } else if (this.questionItem.default_text.trim().split(' ').length > 1) {
                dateValue = moment(this.questionItem.default_text.split(' ')[0]).toDate();
            } else {
                dateValue = moment(this.questionItem.default_text.split('T')[0]).toDate();
            }
        }
        this.datePicker = new FormControl(
            dateValue,
            this.questionItem.is_required ? [Validators.required] : null
        );
        if (this.questionItem.default_text) {
            this.dateChangedHandler(dateValue);
        }
        this.resetSubscription = this.resetForm.subscribe(() => this.datePicker.reset());
    }

    dateChangedHandler(dateValue) {
        const ev = dateValue ? moment(dateValue).format('MM/DD/YYYY') : '';
        this.updated.emit(ev);
    }

    ngOnDestroy(): void {
        this.resetSubscription.unsubscribe();
    }
}
