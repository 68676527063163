import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import { currentSurvey } from './actions';
import {  SurveyStatus } from '../../infrastructure/consts/surveys.consts';

@Injectable()
export class CurrentSurveyStore {
  
  constructor(private store: Store<AppState>) {}
  get currentSurvey() {
    return this.store.select(state => state.currentSurvey.survey);
  }
  get isLoading() {
    return this.store.select(state => state.currentSurvey.isLoading);
  }
  get params() {
    return this.store.select(state => state.currentSurvey.currentSurveyParams);
  }
  get isPrinting() {
    return this.store.select(state => state.currentSurvey.isPrinting);
  }
  loadSurvey(id: string, isSurveyTemplate: boolean) {
    this.store.dispatch(new currentSurvey.LoadSurveyAction({ id, isSurveyTemplate }));
  }
  disposeSurvey() {
    this.store.dispatch(new currentSurvey.DisposeCurrentSurveyAction());
  }
  toggleLock() {
    this.store.dispatch(new currentSurvey.ToggleLockAction());
  }
  toggleFavorite() {
    this.store.dispatch(new currentSurvey.ToggleFavoriteAction());
  }
  changeStatus(status: string) {
    if(Object.keys(SurveyStatus).map((key) =>SurveyStatus[key]).includes(status)){
      this.store.dispatch(new currentSurvey.ChangeStatusAction(status));
    }
  }
  changePrintStatus(isPrinting: boolean) {
    this.store.dispatch(new currentSurvey.PrintSurveyAction(isPrinting));
  }
  changeCurrentSurveyUrl(url: string) {
    this.store.dispatch(new currentSurvey.ChangeCurrentSurveyUrlAction(url));
  }

}
