import { EmailAlertItem } from '../../../models/survey-items/action-items/emailAlertItem';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-email-alert-item-preview',
    templateUrl: './email-alert-item-preview.component.html',
    styleUrls: ['./email-alert-item-preview.component.scss']
})
export class EmailAlertPreviewItemComponent {
    @Input() actionItem: EmailAlertItem;
    constructor() {}
}
