import { first, takeUntil, take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveysSettingProvider, SurveysProvider } from '../../../providers';
import { SharedService } from '../../../../infrastructure/services';
import { pickBy } from 'lodash';
import { Subject } from 'rxjs';
import { LanguageItemModel, LanguageSSSModel, SurveySelectedLanguage } from '../../../../infrastructure/models/survey-settings.model';
import { LanguageService } from '../../../../infrastructure/services';
import { AutoUnsubscribe } from '../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-language-selector',
    templateUrl: './language-selector.component.html'
})

@AutoUnsubscribe()
export class SurveyLanguageSelectorComponent implements OnInit, OnDestroy {
    @Output() languageSelectorChanged: EventEmitter<SurveySelectedLanguage> = new EventEmitter();
    @Input() isPreview: boolean;
    public surveyLanguages: LanguageItemModel[] = [];
    public selectedLanguage: LanguageItemModel;
    public languageSettings: LanguageSSSModel;

    isSurveyTemplate: boolean;
    surveyId: number;

    private componentDestroyed = new Subject();

    constructor(
        private route: ActivatedRoute,
        private surveysSettingProvider: SurveysSettingProvider,
        private sharedService: SharedService,
        private languageService: LanguageService,
        private surveyProvider: SurveysProvider
    ) {}

    public ngOnInit() {
        this.isSurveyTemplate = this.route.snapshot.parent.data.isTemplate;
        this.surveyId = this.route.parent.snapshot.params.id;
        this.initLanguageSettings();
    }

    initLanguageSettings() {
        this.languageService.surveyLanguageSettings.pipe(takeUntil(this.componentDestroyed)).subscribe(
            data => {
                this.languageSettings = data;
            }
        );
        this.languageService.surveyLanguages.pipe(takeUntil(this.componentDestroyed)).subscribe(
            data => {
                this.surveyLanguages = data;
            }
        );

        this.languageService.surveySelectedLanguage.pipe(takeUntil(this.componentDestroyed)).subscribe(
            data => {
                this.selectedLanguage = data;
                this.onSelectedLanguageChange(this.selectedLanguage);
            }
        );
    }

    updateSurveyText(lang) {
        this.surveyProvider.getSurveyTextApplicationSettings(lang.value).pipe(
            first()
        ).subscribe(
            text => {
                const updatedText = { ...this.sharedService.surveyText.value, ...pickBy(text) };
                this.sharedService.surveyText.next(updatedText);
            }
        );
    }

    public onSelectedLanguageChange(change: SurveySelectedLanguage) {
        if (change) {
            this.updateSurveyText(change);
            this.selectedLanguage = change;
            change.isDefault =
                change.value === this.languageSettings.defaultLanguage;
            this.surveysSettingProvider.shareCurrentSurveySelectedLanguage(
                change
            );
            this.languageSelectorChanged.emit(this.selectedLanguage);
        }
    }

    checkTranslate(item, translate) {
        return translate === 'LANGUAGES.' + item.value ? item.viewValue : translate;
    }

    ngOnDestroy() {}

}
