import { QuestionItem } from './questionItem';

export class DrillDownItem extends QuestionItem {
    levels: DrillDownLevel[];
    choice_list_url: string;
    question_text_position: string;
    item_position: string;
    // subitems?: ContactFormResponseSubitem[];
}

export class DrillDownRow {
    id: number;
    column1: string;
    column2: string;
    column3: string;
    column4: string;
    column5: string;
    column6: string;
    column7: string;
    column8: string;
    column9: string;
    column10: string;
    column11: string;
    column12: string;
    position: number;
    enabled: boolean;
}
export class DrillDownLevel {
    name_property: string;
    next_level_property: string;
    level: number;
}

// export class ContactFormResponseSubitem {
//     item_id: number;
//     answer: any;
//     excluded: boolean;
// }

