<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <div class="cb-question-item-cb-question-item-content" tabindex="0" role="group" [attr.aria-labelledby]="questionId + questionItem.id">
            <div
                class="rating"
                [ngClass]="{
                    'hb-width': questionItem.rating_type === 'VerticalBar',
                    'horizontal-rating':
                        questionItem.rating_type === 'HorizontalBar',
                    'star-text-align':
                        questionItem.rating_type === 'Star' &&
                        questionItem.show_values,
                    'pill-rating': questionItem.rating_type === 'Pill'
                }"
            >
                <span
                    class="rating-item"
                    *ngFor="let n of enabled_choices; let $index = index"
                    (click)="onMark($index)"
                    (mouseenter)="onHover(n.text)"
                    (mouseleave)="onLeave()"
                    role="radio"
                    [attr.aria-label]="n.text || $index+1 + ' of ' + enabled_choices.length"
                    [attr.aria-checked]="marked === $index"
                >
                    <span
                        *ngIf="questionItem.rating_type === 'HorizontalBar'"
                        class="horizontal-bar-rate"
                        [ngClass]="isMarked($index)"
                        >&nbsp;</span
                    >
                    <span
                        *ngIf="questionItem.rating_type === 'Line'"
                        class="line-rate"
                        [ngClass]="isMarked($index)"
                        >&nbsp;</span
                    >
                    <span
                        *ngIf="
                            questionItem.rating_type === 'Star' &&
                            questionItem.show_values
                        "
                        >{{ n.text }}</span
                    >
                    <span
                        *ngIf="questionItem.rating_type === 'Star'"
                        class="star-rate"
                        [ngClass]="isMarked($index)"
                        [ngStyle]="starStyle"
                    >
                        <cb-svg-icon
                            class="icon selected-icon"
                            [name]="'favorite-completed'"
                        >
                        </cb-svg-icon>
                        <cb-svg-icon
                            class="icon default-icon"
                            [name]="'favorite-default'"
                        >
                        </cb-svg-icon>
                    </span>
                    <span
                        *ngIf="questionItem.rating_type === 'Square'"
                        class="box-rate"
                        [ngClass]="isMarked($index)"
                    >
                        <span *ngIf="questionItem.show_values">{{
                            n.text
                        }}</span>
                    </span>
                    <span
                        *ngIf="questionItem.rating_type === 'VerticalBar'"
                        class="vertical-bar-rate"
                        [ngClass]="isMarked($index)"
                        >&nbsp;</span
                    >
                    <span
                        *ngIf="questionItem.rating_type === 'ReversedBox'"
                        class="reversed-box-rate"
                        [ngClass]="isMarked($index)"
                        >&nbsp;</span
                    >
                    <span *ngIf="questionItem.rating_type === 'Pill'">
                        <span
                            class="pill-rate pill-first"
                            *ngIf="$index === 0"
                            [ngClass]="isMarked($index)"
                        >
                            <span *ngIf="questionItem.show_values">{{
                                n.text
                            }}</span>
                        </span>
                        <span
                            class="pill-rate"
                            *ngIf="
                                $index > 0 &&
                                $index < enabled_choices.length - 1
                            "
                            [ngClass]="isMarked($index)"
                        >
                            <span *ngIf="questionItem.show_values">{{
                                n.text
                            }}</span>
                        </span>
                        <span
                            class="pill-rate pill-last"
                            *ngIf="$index === enabled_choices.length - 1"
                            [ngClass]="isMarked($index)"
                        >
                            <span *ngIf="questionItem.show_values">{{
                                n.text
                            }}</span>
                        </span>
                    </span>
                </span>
            </div>
            <span
                class="rating-selected-value"
                *ngIf="questionItem.show_selected_value"
                >{{ showSelected }}</span
            >
        </div>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
