import { HyperlinkItem } from '../../../models/survey-items/action-items/hyperlinkItem';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';
import * as _ from 'lodash';
import { ComponentPrintStatus, PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-hyperlink-item-preview',
    templateUrl: './hyperlink-item-preview.component.html',
    styleUrls: ['./hyperlink-item-preview.component.scss']
})
export class HyperlinkPreviewItemComponent implements OnInit, AfterViewInit {
    @ViewChild('link') link: ElementRef;
    @Input() actionItem: HyperlinkItem;
    @Input() isTakeSurvey: boolean;
    @Input() isPrint: boolean;
    @Output() restart = new EventEmitter<any>();
    autoRedirectString: string;
    restartOptions = {
        restart: true,
        delay: null
    };
    constructor(
        private printService: PrintService) { }

    ngOnInit() {
        if (
            this.isTakeSurvey &&
            this.actionItem.restart_survey &&
            this.actionItem.auto_redirect &&
            !this.isPrint
        ) {
            this.restartOptions.delay = this.actionItem.auto_redirect_delay;
            this.restart.emit(this.restartOptions);
        }
        if (this.actionItem.auto_redirect_delay && !this.isPrint) {
            this.autoRedirectString =
                `The respondent will be automatically redirected to ` +
                `${this.actionItem.url} after ${
                    this.actionItem.auto_redirect_delay
                } seconds `;
        }

        this.printService.setStatus(this.actionItem.id, ComponentPrintStatus.RENDERED);
    }

    ngAfterViewInit() {
        if (
            this.isTakeSurvey &&
            !this.actionItem.restart_survey &&
            this.actionItem.auto_redirect &&
            _.isNumber(this.actionItem.auto_redirect_delay) &&
            !this.isPrint
        ) {
            const delay = this.actionItem.auto_redirect_delay * 1000;
            setTimeout(() => {
                this.link.nativeElement.click();
            }, delay);
        }
    }

    onClick($event) {
        $event.preventDefault();
        this.restart.emit(this.restartOptions);
    }
}
