import { Routes } from '@angular/router';
import { SecuritySettingsResolver } from '../../shared/services/security-settings.resolver';
import { AccountSettingsGuard } from '../../infrastructure/guards/account-settings.guard';
import { AuthGuard } from '../../infrastructure/guards/auth.guard';
import { SharedPaths } from '../../shared/consts/urls';
import { EditMyInfoComponent } from '../../shared/contacts/edit-my-info/edit-my-info.component';
import { ChangePasswordPageComponent } from '../../shared/containers/change-password-page/change-password-page.component';
import { LoginPageComponent } from '../identity/login-page/login-page.component';
import { ResetPasswordPageComponent } from '../identity/reset-password-page/reset-password-page.component';
import { SignupPageComponent } from '../identity/signup/singup-page.component';
import { NotFoundPageComponent } from '../not-found/not-found-page.component';
import { SurveyPreviewThemePreloaderComponent } from '../surveys/survey-edit/survey-preview/theme-preloader/theme-preloader.component';

export const routes: Routes = [
    {
        path: '',
        component: LoginPageComponent
    },
    {
        path: 'login',
        component: LoginPageComponent
    },
    {
        path: 'login/:accountName',
        component: LoginPageComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordPageComponent
    },
    {
        path: SharedPaths.changePassword,
        component: ChangePasswordPageComponent,
        resolve: { securitySettings: SecuritySettingsResolver }
    },
    {
        path: 'signup',
        component: SignupPageComponent
    },
    {
        path: 'preview/:id',
        component: SurveyPreviewThemePreloaderComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'system',
        canActivate: [AuthGuard, AccountSettingsGuard],
        loadChildren: () => import('../settings/system-settings/system-settings.module').then(m => m.SystemSettingsModule)
    },
    {
        path: 'settings',
        canActivate: [AuthGuard, AccountSettingsGuard],
        loadChildren: () => import('../settings/application-settings/application-settings.module').then(m => m.ApplicationSettingsModule)
    },
    {
        path: 'dashboards',
        loadChildren: () => import('../dashboards/dashboards.module').then(m => m.DashboardsModule)
    },
    {
        path: 'contacts',
        loadChildren: () => import('../contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
        path: 'groups',
        loadChildren: () => import('../contacts/groups/groups.module').then(m => m.GroupsModule)
    },
    {
        path: 'styles',
        canActivate: [AuthGuard],
        loadChildren: () => import('../style-templates/style-templates.module').then(m => m.StyleTemplatesModule)
    },
    {
        path: 'downloads',
        canActivate: [AuthGuard],
        loadChildren: () => import('../downloads/downloads.module').then(m => m.DownloadsModule)
    },
    {
        path: 'survey-templates',
        canActivate: [AuthGuard],
        loadChildren: () => import('../surveys/survey-templates/survey-templates.module').then(m => m.SurveyTemplatesModule)
    },
    {
        path: 'surveys',
        loadChildren: () => import('../surveys/surveys.module').then(m => m.SurveysModule)
    },
    {
        path: 'edit-my-info',
        component: EditMyInfoComponent,
        canActivate: [AuthGuard]
    },
    /* ----- */
    {
        path: '404',
        component: NotFoundPageComponent
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];
