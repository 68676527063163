<div
    class="cb-question-item-wrapper-outer cb-rating-scale-radio-container {{
        this.questionItem.html_class
    }}"
>
    <div
        class="{{ isMatrix ? 'rating-scale-matrix' : '' }}"
        [ngClass]="layoutClass"
    >
        <cb-questions-header
            [questionItem]="questionItem"
            class="cb-rating-scale-question-header"
            [hideSubtext]="isMatrix"
        ></cb-questions-header>
        <form
            [formGroup]="form"
            class="cb-question-item-content"
            [ngStyle]="{ 'width.px': questionItem.option_width }"
        >
            <div
                class="cb-scale-rating-text"
                *ngIf="
                    (!isMatrix || isTablet) &&
                    (questionItem.start_text ||
                        questionItem.mid_text ||
                        questionItem.end_text)
                "
                [ngClass]="{
                    'with-other-button': questionItem.enable_not_applicable
                }"
            >
                <label [ngStyle]="textItemWidth">{{
                    questionItem.start_text
                }}</label>
                <label [ngStyle]="middleTextItemWidth">{{
                    questionItem.mid_text
                }}</label>
                <label class="last-text-item" [ngStyle]="textItemWidth">{{
                    questionItem.end_text
                }}</label>
                <label
                    *ngIf="appendEmptyLabel"
                    [ngStyle]="textItemWidth"
                ></label>
            </div>

            <mat-radio-group
                role="group"
                [attr.aria-labelledby]="questionId + questionItem.id"
                formControlName="choice_id"
                aria-label="Select an option on a rating scale"
                [ngStyle]="rbGroupStyle"
                class="mat-radio-button-group"
                [ngClass]="{
                    'has-divider': questionItem.show_separator,
                    'with-other-button': questionItem.enable_not_applicable
                }"
            >
                <mat-radio-button
                    *ngFor="let c of questionItem.choices; index as i"
                    [checked]="c.is_default"
                    [value]="c.id"
                    [aria-label]="
                        c.text
                            ? (c.text | extractInnerText)
                            : c.points >= 0 && !c.is_other
                            ? c.points
                            : notApplicable
                    "
                    [ngStyle]="rbStyles[i]"
                    [ngClass]="{
                        'other-button': c.is_other
                    }"
                >
                    <span
                        *ngIf="showText || c.is_other"
                        [innerHtml]="
                            c.text
                                ? (c.text | keepHtml)
                                : c.is_other
                                ? notApplicable
                                : ' '
                        "
                    ></span>
                    <p
                        class="points"
                        *ngIf="
                            showPoints &&
                            !c.is_other &&
                            (showPointsOnly || c.text != c.points + '')
                        "
                        [ngClass]="{
                            onlyPoints: showPointsOnly || !c.text
                        }"
                    >
                        {{ c.points }}
                    </p>
                </mat-radio-button>
            </mat-radio-group>
        </form>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
