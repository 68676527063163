import { ItemOption } from './itemOption';
import { QuestionItem } from './questionItem';

export class RatingScaleItem extends QuestionItem {
    start_value: number;
    end_value: number;
    start_text: string;
    mid_text: string;
    end_text: string;
    enable_not_applicable: boolean;
    not_applicable_text: string;
    layout: string;
    show_separator: boolean;
    question_text_position: string;
    item_position: string;
    option_width: number;
    alias: string;
    choices: ItemOption[];
    choice_id: number;
    choice_label_type: string;
}
