import { QuestionItem } from './questionItem';
import { ItemOption } from './itemOption';

export class RadiobuttonsItem extends QuestionItem {
    select_min_limit: number;
    select_max_limit: number;
    randomize: boolean;
    allow_other: boolean;
    other_value: string;
    layout: string;
    item_position: string;
    question_text_position: string;
    columns: number;
    show_number_labels: boolean;
    choices: ItemOption[];
    alias: string;
    value: number;
}
