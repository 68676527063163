import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-question-collapsed-panel',
    templateUrl: './question-collapsed-panel.component.html'
})
export class QuestionCollapsedPanel {
    @Input() panel: any;
    @Input() title: string;
    @Input() expanded = false;
    @Input() tooltip: string;

    constructor() {}
}
