
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p class="error-message" *ngIf="isBrokenToken">{{ 'RESET-PASSWORD.TOKEN-ERROR' | translate }}</p>
    <p>
        <strong *ngIf="!emailSent">{{ 'RESET-PASSWORD.EXPLAIN' | translate }}</strong>
        <strong *ngIf="emailSent">{{ 'RESET-PASSWORD.MAIL-SENT' | translate }}</strong>
    </p>
    <div class="form-group"
    *ngIf="!emailSent && !isServer && !isBrokenToken">
        <mat-form-field class="cb-full-width-input">
            <input
                matInput
                placeholder="{{ 'RESET-PASSWORD.ACCOUNT-NAME' | translate }}"
                formControlName="account"
            />
            <mat-error>
                {{ 'RESET-PASSWORD.TYPE-ACCOUNT' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group"
    *ngIf="!emailSent">
        <mat-form-field class="cb-full-width-input">
            <input
                matInput
                placeholder="{{ 'RESET-PASSWORD.USERNAME' | translate }}"
                formControlName="contactid"
            />
            <mat-error>
                {{ 'RESET-PASSWORD.TYPE-USERNAME' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="cb-take-survey-buttons-container"
    [ngClass]="{'center': emailSent}">
        <button
            mat-button
            type="button"
            class="btn-cancel"
            [routerLink]="['/login']"
        >
            {{ 'RESET-PASSWORD.BACK' | translate }}
        </button>
        <button
        *ngIf="!emailSent"
            mat-button
            class="btn-next"
            type="submit"
            [disabled]="!form.valid"
        >
            {{ 'RESET-PASSWORD.RESET-PASSWORD' | translate }}
        </button>
    </div>
</form>
