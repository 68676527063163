import { ItemBase } from '../itemBase';

export class EmailAlertItem extends ItemBase {
    from: string;
    to: string;
    bcc: string;
    subject: string;
    body: string;
    format: string;
    send_once: boolean;
    style_template_id: number;
}
