import { Component, Input } from '@angular/core';
import { QuestionItem } from '../../models/survey-items/question-items/questionItem';

@Component({
    selector: 'cb-server-validation-errors',
    templateUrl: './server-validation-errors.component.html',
    styleUrls: ['./server-validation-errors.component.scss']
})
export class ServerValidationErrorsComponent {
    @Input() item: QuestionItem;
}
