import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './reducers';
import {
    GetUsersAction,
    GetUsersPageAction,
    AddUserAction,
    GetUserAction,
    RemoveUserAction
} from './actions/users.actions';
import { Contact, Contacts } from '../models';
import {
    getUsersList,
    getAddedUserInUsersList,
    getUsersLoadingStatus,
    getSelectedUserInUsersList
} from './selectors';
import { PageRequestOptions } from '../../infrastructure/models';

@Injectable()
export class UsersStore {
    constructor(private store: Store<AppState>) {}

    get users(): Observable<Contacts> {
        return this.store.select(getUsersList);
    }

    get selectedUser(): Observable<Contact> {
        return this.store.select(getSelectedUserInUsersList);
    }

    get addedUser(): Observable<Contact> {
        return this.store.select(getAddedUserInUsersList);
    }

    get isLoading(): Observable<boolean> {
        return this.store.select(getUsersLoadingStatus);
    }

    doLoadUserById(userId: string): void {
        this.store.dispatch(new GetUserAction(userId));
    }

    doLoadUsers(): void {
        this.store.dispatch(new GetUsersAction());
    }

    doLoadUsersPage(options: PageRequestOptions): void {
        this.store.dispatch(new GetUsersPageAction(options));
    }

    doRemoveUser(userId: string) {
        this.store.dispatch(new RemoveUserAction(userId));
    }

    doAddUser(user: Contact) {
        this.store.dispatch(new AddUserAction(user));
    }
}
