import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cb-reorder-elements-dialog',
    templateUrl: './reorder-elements-dialog.component.html',
    styleUrls: ['./reorder-elements-dialog.component.scss']
})
export class ReorderElementsDialogComponent {
    reorderByPages = false;
    reorderByItems = true;
    constructor(
        public dialogRef: MatDialogRef<ReorderElementsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public reorderElementsByItems() {
        this.reorderByPages = false;
        this.reorderByItems = true;
    }

    public reorderElementsByPages() {
        this.reorderByPages = true;
        this.reorderByItems = false;
    }
}
