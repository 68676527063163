import { QuestionItem } from './questionItem';
import { ItemOption } from './itemOption';

export class DropdownListItem extends QuestionItem {
    randomize: boolean;
    allow_other: boolean;
    other_value: string;
    display_as_combobox: boolean;
    show_number_labels: boolean;
    question_text_position: string;
    item_position: string;
    choices: ItemOption[];
    alias: string;
    value: number;
}
