import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RegistrationSettings } from '../../../infrastructure/models/registration-settings.model';
import { CaptchaKeys } from '../../../infrastructure/models/signin.model';
import { EnvironmentProvider } from '../../../infrastructure/providers/environment.provider';
import { LoginProvider } from '../../../infrastructure/providers/login.provider';
import { getEnvironmentDomain } from '../../../shared/utils/host-names';

@Component({
    selector: 'cb-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public settings: RegistrationSettings;
    public captchaKeys: CaptchaKeys;
    @Output() login: EventEmitter<any> = new EventEmitter();
    @Output() googleLogin: EventEmitter<any> = new EventEmitter();
    @Output() samlLogin: EventEmitter<string> = new EventEmitter();
    @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
    isServerVersion = environment.name === 'server';
    accountName: string;
    googleAuthClientId: string;

    constructor(
        private fb: FormBuilder,
        private loginProvider: LoginProvider,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private environmentProvider: EnvironmentProvider
    ) {}

    ngOnInit() {
        this.getAccountName();
        const accountValidators = this.isServerVersion
            ? []
            : [Validators.required, Validators.pattern('[a-zA-Z0-9\-\.]*')];
        this.form = this.fb.group({
            account: ['' || this.accountName, accountValidators],
            id: ['', Validators.pattern(/^(?!\s+$).+/)],
            password: ['']
        });
        this.googleAuthClientId = this.environmentProvider.googleAuthClientId;

        this.tryParseAccountNameFromGoogle();
        this.getRegistrationSettings();
    }

    private setValidatorsForSignIn() {
        this.form.get('id').setValidators([Validators.required]);
        this.form.get('id').updateValueAndValidity();
        this.form.get('password').setValidators([Validators.required]);
        this.form.get('password').updateValueAndValidity();
    }

    private setValidatorsForGoogle() {
        this.form.get('account').markAsTouched();
        this.form.get('account').updateValueAndValidity();
        this.form.get('id').clearValidators();
        this.form.get('id').updateValueAndValidity();
        this.form.get('password').clearValidators();
        this.form.get('password').updateValueAndValidity();
    }

    private setValidatorsForSaml() {
        this.form.get('account').markAsTouched();
        this.form.get('account').updateValueAndValidity();
        this.form.get('id').clearValidators();
        this.form.get('id').updateValueAndValidity();
        this.form.get('password').clearValidators();
        this.form.get('password').updateValueAndValidity();
    }

    private getRegistrationSettings() {
        if (this.isServerVersion) {
            this.loginProvider
                .getRegistrationSettings().pipe(
                    switchMap(settings=> this.loginProvider.getCaptchaKeysFromServer(settings))
                )
                .subscribe((settings) => {
                    this.settings = settings.settings;
                    this.captchaKeys = settings.captchaKeys;
                    if(settings.captchaKeys){
                        this.form.addControl('captcha', this.fb.control('', Validators.required));
                    }
                    this.cdr.detectChanges();
                    if (this.captchaKeys && this.settings.is_invisible_captcha) {
                        this.captchaRef?.execute();
                    }
                });
        }
    }

    private getAccountName() {
        const queryParams = this.route.snapshot.queryParams;
        this.route.params.subscribe((params) => {
            this.accountName = params['accountName'];
            if (!this.accountName) {
                this.accountName = queryParams.subDomain;
            }
        });
    }

    private tryParseAccountNameFromGoogle() {
        let split = location.hash ? location.hash.split('state=') : '';
        const state = split ? split[1].split('&')[0] : '';
        split = state ? state.split('account=') : '';
        const account = split ? split[1].split('&')[0] : '';

        if (account)
            this.form.get('account').setValue(account);
    }

    onSubmit() {
        this.setValidatorsForSignIn();

        if (this.form.valid) {
            this.login.emit(this.form.value);
        }
    }

    onGoogleLogin() {
        this.setValidatorsForGoogle();

        if (this.form.valid) {
            this.googleLogin.emit(this.accountName || this.form.get('account').value);
        }
    }

    onSamlLogin() {
        this.setValidatorsForSaml();

        if (this.form.valid) {
            this.samlLogin.emit(this.accountName || this.form.get('account').value);
        }
    }

    getEnvironmentDomain() {
        const host = window.location.host;
        return getEnvironmentDomain(host);
    }

    resolveCaptcha(response: string) {
        const captchaField = this.form.get('captcha');
        if (captchaField) {
            captchaField.setValue(response);
            captchaField.markAsTouched();
        }
    }
}
