import { EmailAlertItem } from './emailAlertItem';

export class EmailResponseItem extends EmailAlertItem {
    include_response_details: boolean;
    include_message_items: boolean;
    show_page_numbers: boolean;
    show_question_numbers: boolean;
    show_hidden_items: boolean;
    show_points: boolean;
}
