import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-help-icon',
    templateUrl: './help-icon.component.html',
    styleUrls: ['./help-icon.component.scss']
})

export class HelpIconComponent {
    @Input() tooltip: string;

    constructor() {
    }
}
