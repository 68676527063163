import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, last, count, distinct, map, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'cb-header-level2',
  templateUrl: './header-level2.component.html',
  styleUrls: ['./header-level2.component.scss']
})
export class HeaderLevel2Component implements OnInit, OnChanges {
  @Input() currentPathConfig = '';
  headerType = 'none';
  none = 'none';
  surveyList = 'survey-list';
  surveyDetail = 'survey-detail';
  surveyCampaign = 'survey-campaign';
  headerTypes = [this.none, this.surveyList, this.surveyDetail, this.surveyCampaign];
  isHeaderAvailable = false;
  pathStartingMap = {
    'surveys': this.surveyList,
    'surveys/:id': this.surveyDetail,
    'surveys/:id/print': this.none
    //'surveys/:id/invitations': this.surveyCampaign
  }
  get toolbarClasses(){
    return ['main-navigation', this.headerType ?? ''];
  }

  constructor(private route: Router, private activeRoute: ActivatedRoute) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.changeBasedCurrentPathConfig();
  }

  changeBasedCurrentPathConfig() {
    const startings = Object.keys(this.pathStartingMap);
    let changed = false;
    for (let i = 0; i < startings.length; i++) {
      if (this.currentPathConfig.startsWith(startings[i])) {
        this.changeHeaderType(this.pathStartingMap[startings[i]]);
        changed = true;
      }
    }
    if (changed === false) {
      this.changeHeaderType(this.none);
    }
  }
  ngOnInit() {
  }
  changeHeaderType(headerType: string) {
    this.headerType = headerType;
    this.changeHeaderAvailable();
  }
  changeHeaderAvailable() {
    this.isHeaderAvailable = this.headerTypes.includes(this.headerType) && this.headerType !== this.none;
  }




}
