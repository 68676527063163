import { ItemOption } from './itemOption';
import { QuestionItem } from './questionItem';

export class NetPromoterScoreItem extends QuestionItem {
    max_length: number;
    default_text: string;
    answer_format: string;
    min_value: number;
    max_value: number;
    width: number;
    layout: string;
    option_width: number;
    show_separator: boolean;
    alias: string;
    item_position: string;
    question_text_position: string;
    choices: ItemOption[];
    value: number;
}
