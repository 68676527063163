import { QuestionItem } from './questionItem';

export class MatrixItem extends QuestionItem {
    alias: string;
    rows: any[];
    columns: any[];
    elements: any[];
    question_text_position: string;
    row_text_align: string;
    item_position: string;
    grid_lines: string;
    width: number;
    excluded_rows?: any[];
    matrixValueAnswers: any[][];
}

export class MatrixPreview {
    id: number;
    text: string;
    subtext: string;
    rows: Row[] = [];
}

export class Row {
    id: number;
    position: number;
    text: string;
    row_type: string;
    alias: string;
    columns: Column[] = [];
    include_condition: any;
}

export class Column {
    id: number;
    position: number;
    prototype_item: any;
    require_unique_answers: boolean;
    width: number;
    prototype_item_type: string;
    column_type?: string;
}
