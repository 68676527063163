import { QuestionItem } from '../../models/survey-items/question-items/questionItem';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SurveysSettingProvider } from '../../../infrastructure/providers/surveys-setting.provider';
import { TakeSurveyData } from '../../../infrastructure/consts/take-survey.consts';
import { SharedService } from '../../../infrastructure/services';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { EditorSurveyQuestionType, HtmlTags } from '../../../infrastructure/consts/surveys.consts';
import * as _ from 'lodash';

@Component({
    selector: 'cb-questions-header',
    templateUrl: './questions-header.component.html',
    styleUrls: ['./questions-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class QuestionsHeaderComponent implements OnInit, OnDestroy {
    @Input() questionItem: QuestionItem;
    @Input() hideSubtext: boolean;
    questionId = TakeSurveyData.QUESTION_ID;
    appearanceSettings;
    settingsSubscription: Subscription;
    emptyRootElement = HtmlTags.ROOT_ELEMENT;
    private componentDestroyed = new Subject();

    constructor(
        private surveysSettingProvider: SurveysSettingProvider,
        private sharedService: SharedService,
        private _cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.settingsSubscription = this.surveysSettingProvider.storedAppearanceSettings.subscribe(
            data => {
                this.appearanceSettings = data;
            }
        );
        this.sharedService
            .getData(TakeSurveyData.UPDATE_NUMERATION)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this._cdr.markForCheck();
            });
        if (this.questionItem.item_type === EditorSurveyQuestionType.MATRIX) {
            this.questionItem.is_required =
                _.chain(this.questionItem)
                .get('columns')
                .some(column => _.get(column, 'prototype_item.is_required'))
                .value();
        }
    }

    ngOnDestroy() {
        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
    }
}
