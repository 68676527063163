import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../infrastructure/services';

@Component({
    selector: 'cb-share-url',
    templateUrl: './share-url.component.html',
    styleUrls: ['./share-url.component.scss']
})
export class ShareUrlComponent implements OnInit {
    @Input() urlLink: string;
    @Input() attr: string;
    @Input() host: string;

    copyURLSuccessMessage: string;

    constructor(
        private translateService: TranslateService,
        protected toasterService: ToasterService
    ) {
    }

    ngOnInit() {
        this.translateService
            .get('SHARED.COPIED-TO-CLIPBOARD')
            .subscribe((data: string) => {
                this.copyURLSuccessMessage = data;
            });
    }

    urlCopied() {
        this.toasterService.showInfo(this.copyURLSuccessMessage);
    }
}
